import { Toastr } from '@paljs/ui/Toastr';
import { navigate } from '@reach/router';
import React, { useEffect, Component } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { Button, ButtonLink } from '@paljs/ui/Button';
import Loader from '../modal-overlays/loader';
import { EvaIcon } from '@paljs/ui/Icon';

class UserSubscriptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listing: [],
      isLoading: false,
      limit: 10,
      lastDoc: null,
      firstDoc: null,
      disableNext: false,
      searchVal: '',
      page: 1,
    };
    this.toastrRef = React.createRef();
  }

  componentDidMount() {
    this.getSubscriptions();
  }

  getSubscriptions = () => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('user_subscriptions')
      .orderBy('purchase_date', 'desc')
      .limit(this.state.limit)
      .get()
      .then((snapShot) => {
        let tempArr = [];
        let temp = snapShot.docs[snapShot.docs.length - 1];
        snapShot.forEach((doc) => {
          //console.log(doc.id)
          tempArr.push({ id: doc.id, ...doc.data(), purchase_date: this.formatDate(doc.data().purchase_date.seconds) });
        });
        _self.setState({
          listing: tempArr,
          isLoading: false,
          lastDoc: temp,
          disableNext: !tempArr.length ? true : false,
        });
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  nextPage = () => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('user_subscriptions')
      .orderBy('purchase_date', 'desc')
      .startAfter(this.state.lastDoc)
      .limit(this.state.limit)
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length == 0) {
          _self.setState({ disableNext: true, isLoading: false });
        } else {
          let tempArr = [];
          let last = snapshot.docs[snapshot.docs.length - 1];
          let first = snapshot.docs[0];
          snapshot.forEach((doc) => {
            tempArr.push({
              id: doc.id,
              ...doc.data(),
              purchase_date: this.formatDate(doc.data().purchase_date.seconds),
            });
          });
          _self.setState({
            listing: tempArr,
            firstDoc: first,
            lastDoc: last,
            page: this.state.page + 1,
            isLoading: false,
          });
        }
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  previousPage = () => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('user_subscriptions')
      .orderBy('purchase_date', 'desc')
      .endBefore(this.state.firstDoc)
      .limitToLast(this.state.limit)
      .get()
      .then((snapshot) => {
        let tempArr = [];
        let last = snapshot.docs[snapshot.docs.length - 1];
        let first = snapshot.docs[0];
        snapshot.forEach((doc) => {
          tempArr.push({ id: doc.id, ...doc.data(), purchase_date: this.formatDate(doc.data().purchase_date.seconds) });
        });
        _self.setState({
          listing: tempArr,
          firstDoc: first,
          lastDoc: last,
          page: this.state.page - 1,
          disableNext: false,
          isLoading: false,
        });
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  changeStatus = (id, type) => {
    let tempStaus = { label: type == 'active' ? 'Active' : 'Inactive', value: type };
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('user_subscriptions')
      .doc(id)
      .update({ status: tempStaus })
      .then(function () {
        _self.toastrRef.current?.add('Updated Successfully', 'Success', { status: 'Success' });
        _self.setState({ page: 1, isLoading: false, searchVal: '' });
        _self.getSubscriptions();
      })
      .catch(function (error) {
        _self.setState({ isLoading: false });
        _self.toastrRef.current?.add('Error Updating Data', 'Error', { status: 'Danger' });
      });
  };

  formatDate = (seconds) => {
    let date = new Date(seconds * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  search = () => {
    let _self = this;
    if (this.state.searchVal === '') {
      _self.toastrRef.current?.add('No search provided', 'Error', { status: 'Danger' });
      return;
    }
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('user_subscriptions')
      .where('user_email', '>=', this.state.searchVal)
      .where('user_email', '<=', this.state.searchVal + '\uf8ff')
      .get()
      .then((snapshot) => {
        let tempArr = [];
        snapshot.forEach((doc) => {
          tempArr.push({
            id: doc.id,
            ...doc.data(),
            purchase_date: this.formatDate(doc.data().purchase_date.seconds),
          });
        });
        _self.setState({
          listing: tempArr,
          disableNext: true,
          isLoading: false,
          page: 1,
        });
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  clear = () => {
    this.setState({
      searchVal: '',
    });
    this.getSubscriptions();
  };

  checkEnter = (e) => {
    if (e.key === 'Enter') {
      this.search();
    }
  };

  render() {
    return (
      <>
        <Toastr ref={this.toastrRef} />
        <div className="container table-responsive">
          <div className="d-flex justify-content-between mb-3">
            <h4>Subscription Listing</h4>
            <div className="d-flex justify-content-between">
              <input
                onKeyDown={this.checkEnter}
                type="text"
                value={this.state.searchVal}
                onChange={(e) => this.setState({ searchVal: e.target.value })}
                className="form-control mr-2"
                placeholder="Search"
              />
              <Button onClick={this.search} type="button" appearance="outline" className="mr-2">
                <EvaIcon name="search-outline" />
              </Button>
              <Button onClick={this.clear} type="button" appearance="outline" status="Danger">
                <EvaIcon name="close-outline" />
              </Button>
            </div>
            <button className="btn btn-primary" onClick={() => navigate('/admin/user-subscriptions/add-subscription')}>
              Add Subscription
            </button>
          </div>
          <table className="table table-light">
            <thead className="thead-dark">
              <tr>
                <th>User</th>
                <th style={{ minWidth: '120px' }}>Plan Name</th>
                <th>Term</th>
                <th>Subscription Date</th>
                <th>Stripe Subscription ID</th>
                <th>Discount Code</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {this.state.listing.length ? (
                this.state.listing.map((item, i) => (
                  <tr key={i}>
                    <td>{item.user_email}</td>
                    <td>{item.product_name}</td>
                    <td>{item.term}</td>
                    <td>{item.purchase_date}</td>
                    <td>{item.stripe_subscription_id}</td>
                    <td>{item.coupon_code ? item.coupon_code : ''}</td>
                    <td>
                      {item.status.value == 'active' ? (
                        <ButtonLink
                          appearance="outline"
                          status="Danger"
                          onClick={() => this.changeStatus(item.id, 'inactive')}
                        >
                          Deactivate
                        </ButtonLink>
                      ) : (
                        <ButtonLink
                          appearance="outline"
                          status="Success"
                          onClick={() => this.changeStatus(item.id, 'active')}
                        >
                          Activate
                        </ButtonLink>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li className={`page-item ${this.state.page == 1 && 'disabled'}`}>
                <div className="page-link cursor-pointer" onClick={this.previousPage}>
                  <EvaIcon name="arrowhead-left-outline" />
                </div>
              </li>
              <li className={`page-item ${this.state.disableNext && 'disabled'}`}>
                <div className="page-link cursor-pointer" onClick={this.nextPage}>
                  <EvaIcon name="arrowhead-right-outline" />
                </div>
              </li>
            </ul>
          </nav>
        </div>
        <Loader isOpen={this.state.isLoading} />
      </>
    );
  }
}

export default UserSubscriptions;
