// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-admin-404-tsx": () => import("./../../src/pages/admin/404.tsx" /* webpackChunkName: "component---src-pages-admin-404-tsx" */),
  "component---src-pages-admin-admin-app-js": () => import("./../../src/pages/admin/adminApp.js" /* webpackChunkName: "component---src-pages-admin-admin-app-js" */),
  "component---src-pages-admin-auth-login-tsx": () => import("./../../src/pages/admin/auth/login.tsx" /* webpackChunkName: "component---src-pages-admin-auth-login-tsx" */),
  "component---src-pages-admin-auth-logout-tsx": () => import("./../../src/pages/admin/auth/logout.tsx" /* webpackChunkName: "component---src-pages-admin-auth-logout-tsx" */),
  "component---src-pages-admin-auth-request-password-tsx": () => import("./../../src/pages/admin/auth/request-password.tsx" /* webpackChunkName: "component---src-pages-admin-auth-request-password-tsx" */),
  "component---src-pages-admin-auth-reset-password-tsx": () => import("./../../src/pages/admin/auth/reset-password.tsx" /* webpackChunkName: "component---src-pages-admin-auth-reset-password-tsx" */),
  "component---src-pages-admin-dashboard-tsx": () => import("./../../src/pages/admin/dashboard.tsx" /* webpackChunkName: "component---src-pages-admin-dashboard-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-instruments-instruments-listing-tsx": () => import("./../../src/pages/admin/instruments/instruments-listing.tsx" /* webpackChunkName: "component---src-pages-admin-instruments-instruments-listing-tsx" */),
  "component---src-pages-admin-instruments-instruments-tsx": () => import("./../../src/pages/admin/instruments/instruments.tsx" /* webpackChunkName: "component---src-pages-admin-instruments-instruments-tsx" */),
  "component---src-pages-admin-miscellaneous-404-tsx": () => import("./../../src/pages/admin/miscellaneous/404.tsx" /* webpackChunkName: "component---src-pages-admin-miscellaneous-404-tsx" */),
  "component---src-pages-admin-modal-overlays-confirm-modal-tsx": () => import("./../../src/pages/admin/modal-overlays/confirm-modal.tsx" /* webpackChunkName: "component---src-pages-admin-modal-overlays-confirm-modal-tsx" */),
  "component---src-pages-admin-modal-overlays-loader-tsx": () => import("./../../src/pages/admin/modal-overlays/loader.tsx" /* webpackChunkName: "component---src-pages-admin-modal-overlays-loader-tsx" */),
  "component---src-pages-admin-products-products-listing-tsx": () => import("./../../src/pages/admin/products/products-listing.tsx" /* webpackChunkName: "component---src-pages-admin-products-products-listing-tsx" */),
  "component---src-pages-admin-products-products-tsx": () => import("./../../src/pages/admin/products/products.tsx" /* webpackChunkName: "component---src-pages-admin-products-products-tsx" */),
  "component---src-pages-admin-report-management-add-report-bkp-tsx": () => import("./../../src/pages/admin/report-management/add-report-bkp.tsx" /* webpackChunkName: "component---src-pages-admin-report-management-add-report-bkp-tsx" */),
  "component---src-pages-admin-report-management-add-report-tsx": () => import("./../../src/pages/admin/report-management/add-report.tsx" /* webpackChunkName: "component---src-pages-admin-report-management-add-report-tsx" */),
  "component---src-pages-admin-report-management-dtn-modal-tsx": () => import("./../../src/pages/admin/report-management/dtn-modal.tsx" /* webpackChunkName: "component---src-pages-admin-report-management-dtn-modal-tsx" */),
  "component---src-pages-admin-report-management-dtn-string-tsx": () => import("./../../src/pages/admin/report-management/dtn-string.tsx" /* webpackChunkName: "component---src-pages-admin-report-management-dtn-string-tsx" */),
  "component---src-pages-admin-report-management-paste-word-tsx": () => import("./../../src/pages/admin/report-management/pasteWord.tsx" /* webpackChunkName: "component---src-pages-admin-report-management-paste-word-tsx" */),
  "component---src-pages-admin-report-management-pop-up-tsx": () => import("./../../src/pages/admin/report-management/popUp.tsx" /* webpackChunkName: "component---src-pages-admin-report-management-pop-up-tsx" */),
  "component---src-pages-admin-report-management-report-listing-tsx": () => import("./../../src/pages/admin/report-management/report-listing.tsx" /* webpackChunkName: "component---src-pages-admin-report-management-report-listing-tsx" */),
  "component---src-pages-admin-report-management-report-tabs-tsx": () => import("./../../src/pages/admin/report-management/report-tabs.tsx" /* webpackChunkName: "component---src-pages-admin-report-management-report-tabs-tsx" */),
  "component---src-pages-admin-reports-add-report-tsx": () => import("./../../src/pages/admin/reports/add-report.tsx" /* webpackChunkName: "component---src-pages-admin-reports-add-report-tsx" */),
  "component---src-pages-admin-reports-pop-up-tsx": () => import("./../../src/pages/admin/reports/popUp.tsx" /* webpackChunkName: "component---src-pages-admin-reports-pop-up-tsx" */),
  "component---src-pages-admin-reports-report-listing-tsx": () => import("./../../src/pages/admin/reports/report-listing.tsx" /* webpackChunkName: "component---src-pages-admin-reports-report-listing-tsx" */),
  "component---src-pages-admin-reports-report-tabs-tsx": () => import("./../../src/pages/admin/reports/report-tabs.tsx" /* webpackChunkName: "component---src-pages-admin-reports-report-tabs-tsx" */),
  "component---src-pages-admin-user-management-user-form-tsx": () => import("./../../src/pages/admin/user-management/user-form.tsx" /* webpackChunkName: "component---src-pages-admin-user-management-user-form-tsx" */),
  "component---src-pages-admin-user-management-user-listing-tsx": () => import("./../../src/pages/admin/user-management/user-listing.tsx" /* webpackChunkName: "component---src-pages-admin-user-management-user-listing-tsx" */),
  "component---src-pages-admin-user-subscriptions-add-subscription-tsx": () => import("./../../src/pages/admin/user-subscriptions/add-subscription.tsx" /* webpackChunkName: "component---src-pages-admin-user-subscriptions-add-subscription-tsx" */),
  "component---src-pages-admin-user-subscriptions-user-subscriptions-tsx": () => import("./../../src/pages/admin/user-subscriptions/user-subscriptions.tsx" /* webpackChunkName: "component---src-pages-admin-user-subscriptions-user-subscriptions-tsx" */),
  "component---src-pages-admin-users-my-profile-tsx": () => import("./../../src/pages/admin/users/my_profile.tsx" /* webpackChunkName: "component---src-pages-admin-users-my-profile-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-frontend-components-banner-section-tsx": () => import("./../../src/pages/frontend/components/banner-section.tsx" /* webpackChunkName: "component---src-pages-frontend-components-banner-section-tsx" */),
  "component---src-pages-frontend-components-common-contact-boxes-tsx": () => import("./../../src/pages/frontend/components/common/contact-boxes.tsx" /* webpackChunkName: "component---src-pages-frontend-components-common-contact-boxes-tsx" */),
  "component---src-pages-frontend-components-common-footer-tsx": () => import("./../../src/pages/frontend/components/common/footer.tsx" /* webpackChunkName: "component---src-pages-frontend-components-common-footer-tsx" */),
  "component---src-pages-frontend-components-common-header-tsx": () => import("./../../src/pages/frontend/components/common/header.tsx" /* webpackChunkName: "component---src-pages-frontend-components-common-header-tsx" */),
  "component---src-pages-frontend-components-hero-mission-tsx": () => import("./../../src/pages/frontend/components/hero-mission.tsx" /* webpackChunkName: "component---src-pages-frontend-components-hero-mission-tsx" */),
  "component---src-pages-frontend-components-hero-tsx": () => import("./../../src/pages/frontend/components/hero.tsx" /* webpackChunkName: "component---src-pages-frontend-components-hero-tsx" */),
  "component---src-pages-frontend-components-newsletter-tsx": () => import("./../../src/pages/frontend/components/newsletter.tsx" /* webpackChunkName: "component---src-pages-frontend-components-newsletter-tsx" */),
  "component---src-pages-frontend-components-our-advantage-tsx": () => import("./../../src/pages/frontend/components/our-advantage.tsx" /* webpackChunkName: "component---src-pages-frontend-components-our-advantage-tsx" */),
  "component---src-pages-frontend-components-products-tsx": () => import("./../../src/pages/frontend/components/products.tsx" /* webpackChunkName: "component---src-pages-frontend-components-products-tsx" */),
  "component---src-pages-frontend-components-track-record-tsx": () => import("./../../src/pages/frontend/components/trackRecord.tsx" /* webpackChunkName: "component---src-pages-frontend-components-track-record-tsx" */),
  "component---src-pages-frontend-components-why-us-tsx": () => import("./../../src/pages/frontend/components/why-us.tsx" /* webpackChunkName: "component---src-pages-frontend-components-why-us-tsx" */),
  "component---src-pages-frontend-front-app-js": () => import("./../../src/pages/frontend/frontApp.js" /* webpackChunkName: "component---src-pages-frontend-front-app-js" */),
  "component---src-pages-home-tsx": () => import("./../../src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-market-reports-agricultural-tsx": () => import("./../../src/pages/market-reports/agricultural.tsx" /* webpackChunkName: "component---src-pages-market-reports-agricultural-tsx" */),
  "component---src-pages-market-reports-etf-tsx": () => import("./../../src/pages/market-reports/etf.tsx" /* webpackChunkName: "component---src-pages-market-reports-etf-tsx" */),
  "component---src-pages-market-reports-financial-tsx": () => import("./../../src/pages/market-reports/financial.tsx" /* webpackChunkName: "component---src-pages-market-reports-financial-tsx" */),
  "component---src-pages-market-reports-index-tsx": () => import("./../../src/pages/market-reports/index.tsx" /* webpackChunkName: "component---src-pages-market-reports-index-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-product-reports-tsx": () => import("./../../src/pages/product-reports.tsx" /* webpackChunkName: "component---src-pages-product-reports-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-report-list-tsx": () => import("./../../src/pages/report-list.tsx" /* webpackChunkName: "component---src-pages-report-list-tsx" */),
  "component---src-pages-report-listing-tsx": () => import("./../../src/pages/report-listing.tsx" /* webpackChunkName: "component---src-pages-report-listing-tsx" */),
  "component---src-pages-reports-bkp-tsx": () => import("./../../src/pages/reports-bkp.tsx" /* webpackChunkName: "component---src-pages-reports-bkp-tsx" */),
  "component---src-pages-reports-tsx": () => import("./../../src/pages/reports.tsx" /* webpackChunkName: "component---src-pages-reports-tsx" */),
  "component---src-pages-single-report-tsx": () => import("./../../src/pages/single-report.tsx" /* webpackChunkName: "component---src-pages-single-report-tsx" */),
  "component---src-pages-subscriptions-agricultural-tsx": () => import("./../../src/pages/subscriptions/agricultural.tsx" /* webpackChunkName: "component---src-pages-subscriptions-agricultural-tsx" */),
  "component---src-pages-subscriptions-etf-tsx": () => import("./../../src/pages/subscriptions/etf.tsx" /* webpackChunkName: "component---src-pages-subscriptions-etf-tsx" */),
  "component---src-pages-subscriptions-financial-tsx": () => import("./../../src/pages/subscriptions/financial.tsx" /* webpackChunkName: "component---src-pages-subscriptions-financial-tsx" */),
  "component---src-pages-subscriptions-index-tsx": () => import("./../../src/pages/subscriptions/index.tsx" /* webpackChunkName: "component---src-pages-subscriptions-index-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-user-products-tsx": () => import("./../../src/pages/user-products.tsx" /* webpackChunkName: "component---src-pages-user-products-tsx" */)
}

