import React, { Component } from 'react';
import { navigate } from 'gatsby';
import Tooltip from '@paljs/ui/Tooltip';
import firebase from 'gatsby-plugin-firebase';
import ConfirmModal from '../modal-overlays/confirm-modal';
import { Toastr } from '@paljs/ui/Toastr';
import { EvaIcon } from '@paljs/ui/Icon';
import { Button } from '@paljs/ui/Button';
import Loader from '../modal-overlays/loader';

class InstrumentListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listing: [],
      isModalOpen: false,
      deleteId: '',
      lastDoc: null,
      firstDoc: null,
      limit: 10,
      disableNext: false,
      page: 1,
      isLoading: false,
    };
    this.toastrRef = React.createRef();
  }

  componentDidMount() {
    this.getData();
  }

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  getData = () => {
    this.setState({ isLoading: true });
    let _self = this;
    firebase
      .firestore()
      .collection('instruments')
      .orderBy('createdAt', 'desc')
      .limit(this.state.limit)
      .get()
      .then((snapshot) => {
        let tempArr = [];
        let temp = snapshot.docs[snapshot.docs.length - 1];
        snapshot.forEach((doc) => {
          tempArr.push({ id: doc.id, ...doc.data() });
        });
        _self.setState({
          listing: tempArr,
          lastDoc: temp,
          isLoading: false,
          disableNext: !tempArr.length ? true : false,
        });
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  handleDelete = (id, name) => {
    let _self = this;
    let t = false;
    let doc_names = [];
    firebase
      .firestore()
      .collection('products')
      .get()
      .then((querySnapshot) => {
        for (let i in querySnapshot.docs) {
          let doc = querySnapshot.docs[i];
          let obj = doc.data();
          if (obj.selectedIns && obj.selectedIns.length && obj.selectedIns.find((item) => item.name === name)) {
            doc_names.push(obj.name);
            break;
          }
        }
      })
      .then(() => {
        if (doc_names.length) {
          _self.toastrRef.current?.add(`Please remove instrument from ${doc_names[0]}`, 'Error', { status: 'Danger' });
        } else {
          _self.setState({ deleteId: id, isModalOpen: true });
        }
      });
  };

  nextPage = () => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('instruments')
      .orderBy('createdAt', 'desc')
      .startAfter(this.state.lastDoc)
      .limit(this.state.limit)
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length == 0) {
          _self.setState({ disableNext: true, isLoading: false });
        } else {
          let tempArr = [];
          let last = snapshot.docs[snapshot.docs.length - 1];
          let first = snapshot.docs[0];
          snapshot.forEach((doc) => {
            tempArr.push({ id: doc.id, ...doc.data() });
          });
          _self.setState({
            listing: tempArr,
            firstDoc: first,
            lastDoc: last,
            page: this.state.page + 1,
            isLoading: false,
          });
        }
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  previousPage = () => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('instruments')
      .orderBy('createdAt', 'desc')
      .endBefore(this.state.firstDoc)
      .limitToLast(this.state.limit)
      .get()
      .then((snapshot) => {
        let tempArr = [];
        let last = snapshot.docs[snapshot.docs.length - 1];
        let first = snapshot.docs[0];
        snapshot.forEach((doc) => {
          tempArr.push({ id: doc.id, ...doc.data() });
        });
        _self.setState({
          listing: tempArr,
          firstDoc: first,
          lastDoc: last,
          page: this.state.page - 1,
          disableNext: false,
          isLoading: false,
        });
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  confirmDelete = () => {
    let _self = this;
    this.setState({ isLoading: true });

    let storageRef = firebase.storage().ref('instruments/' + this.state.deleteId);
    let imgRef = firebase.storage().ref('instrument-logos/' + this.state.deleteId);
    imgRef
      .delete()
      .then(() => {
        storageRef
          .delete()
          .then(() => {
            firebase
              .firestore()
              .collection('instruments')
              .doc(this.state.deleteId)
              .delete()
              .then(function () {
                _self.toastrRef.current?.add('Deleted Successfully', 'Success', { status: 'Success' });
                _self.setState({ isModalOpen: false, page: 1, isLoading: false });
                _self.getData();
              })
              .catch(function (error) {
                _self.setState({ isLoading: false });
                _self.toastrRef.current?.add('Error Deleting Data', 'Error', { status: 'Danger' });
              });
          })
          .catch((error) => {
            _self.setState({ isLoading: false });
            _self.toastrRef.current?.add('Error Deleting Image', 'Error', { status: 'Danger' });
          });
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
        _self.toastrRef.current?.add('Error Deleting Image', 'Error', { status: 'Danger' });
      });
  };

  render() {
    return (
      <>
        <Toastr ref={this.toastrRef} />
        <div className="container table-responsive">
          <div className="d-flex justify-content-between mb-3">
            <h4>Instruments</h4>
            <button className="btn btn-primary" onClick={() => navigate('/admin/instruments/instruments')}>
              Add Instruments
            </button>
          </div>
          <table className="table table-light">
            <thead className="thead-dark">
              <tr>
                <th></th>
                <th>ID</th>
                <th>Name</th>
                <th>Market</th>
                <th>Type</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.listing.length ? (
                this.state.listing.map((list, i) => (
                  <tr key={i}>
                    <td>{list.imageUrl && <img src={list.imageUrl} height="50px" />}</td>
                    <td>{list.id}</td>
                    <td>{list.name}</td>
                    <td>{list.market}</td>
                    <td>{list.types.label}</td>
                    <td>
                      <Tooltip className="with-margin inline-block" trigger="hover" placement="right" content="Edit">
                        <Button
                          appearance="ghost"
                          onClick={() => navigate(`/admin/instruments/instruments/${list.id}`)}
                        >
                          <EvaIcon name="edit-outline" />
                        </Button>
                      </Tooltip>
                      <Tooltip className="with-margin inline-block" trigger="hover" placement="right" content="Delete">
                        <Button appearance="ghost" onClick={() => this.handleDelete(list.id, list.name)}>
                          <EvaIcon name="trash-outline" />
                        </Button>
                      </Tooltip>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li className={`page-item ${this.state.page == 1 && 'disabled'}`}>
                <div className="page-link cursor-pointer" onClick={this.previousPage}>
                  <EvaIcon name="arrowhead-left-outline" />
                </div>
              </li>
              <li className={`page-item ${this.state.disableNext && 'disabled'}`}>
                <div className="page-link cursor-pointer" onClick={this.nextPage}>
                  <EvaIcon name="arrowhead-right-outline" />
                </div>
              </li>
            </ul>
          </nav>
        </div>
        <ConfirmModal isOpen={this.state.isModalOpen} close={this.toggleModal} accept={this.confirmDelete} />
        <Loader isOpen={this.state.isLoading} />
      </>
    );
  }
}

export default InstrumentListing;
