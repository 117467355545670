import React, { useEffect } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { navigate } from 'gatsby';

const Logout = () => {
  const logoutUser = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        localStorage.removeItem('user');
        navigate('/admin/auth/login');
      })
      .catch(function (error) {
        navigate('/admin/dashboard');
      });
  };
  useEffect(() => logoutUser());
  return <></>;
};

export default Logout;
