import moment from 'moment';
import sanitizeHtml from 'sanitize-html';
import React from 'react';
import $ from 'jquery';

let sanitizeConf = {
  allowedTags: [],
  allowedAttributes: {},
};

let allowP = {
  allowedTags: ['p', 'tr', 'th', 'td', 'table', 'li'],
  allowedAttributes: {},
};

let author = `PRECISE TIMING and MOVEMENT ANALYSIS by BARRY ROSEN
"Serving Futures Traders since 1987"`;

const changeData = (data) => {
  let params = { ...data };
  let finalString = params.code ? `${params.code}\n` : '';
  let line2 = `${params.dtn_name}\n`;
  finalString += `${line2}${params.prod_name}\n${author}\n`;

  let updated = params.updated
    ? `UPDATED ${moment(params.updated.seconds * 1000).format('MMMM DD')} for MARKETS of ${moment(
        params.rep_date,
      ).format('MMMM DD, YYYY')}\n`
    : '';
  finalString += updated + 'SPEC TRADING RECOMMENDTIONS*\n\n';

  params.ov_title ? (finalString += `${$('<div/>').html(params.ov_title).text()}\n\n`) : '';

  if (params.ov_description) {
    let tempStr = sanitizeHtml(params.ov_description, allowP);
    finalString += changeParseData(tempStr);
    console.log(tempStr);
  }

  /* Section start */
  for (let i = 0; i < params.sections.length; i++) {
    let temp = params.sections[i];
    temp.title ? (finalString += '\n\n' + $('<div/>').html(temp.title).text() + '\n') : '';
    for (let j = 0; j < temp.rows.length; j++) {
      for (let k = 0; k < temp.rows[j].columns.length; k++) {
        if (temp.rows[j].columns[k].colType === 'editor') {
          let tempStr = sanitizeHtml(temp.rows[j].columns[k].colValue, allowP);
          finalString += changeParseData(tempStr);
        }

        /* tempStr = tempStr.replace(/<p[^>]*>/g, "");
									 tempStr = tempStr.replace(/<\/p[^>]*>/g, "\n");
		 
									 tempStr = tempStr.replace(/<tr[^>]*>/g, "");
									 tempStr = tempStr.replace(/<\/tr[^>]*>/g, "\n"); */

        //finalString += tempStr
      }
    }
  }
  /* Section End */

  /* TABS START */

  let tData = [];

  if (params.isCopy == true) {
    tData = [...params.tabsData];
  } else {
    /* tData = [...params.tabsData].sort((a, b) => {
			return a.dtn_code.localeCompare(b.dtn_code, undefined, {
				numeric: true,
				sensitivity: 'base',
			});
			}); */
    tData = [...params.tabsData].sort((a, b) => {
      return a.order - b.order;
    });
  }

  let usedIds = [];

  for (let i = 0; i < tData.length; i++) {
    if (tData[i].status.value === 'completed') {
      if (params.isCopy == false) {
        console.log('code here', tData[i].dtn_code);
        if (tData[i].dtn_code && usedIds.find((item) => item.id == tData[i].dtn_code) == undefined) {
          finalString += '\n\n\n' + tData[i].dtn_code + '\n';
          finalString += tData[i].dtn_name;
        }
      }

      usedIds.push({ id: tData[i].dtn_code, name: tData[i].dtn_name });
      console.log('usedIds', usedIds);
      /*  if (params.isCopy == false) {
        tData[i].dtn_name && usedIds.find((item) => item.name === tData[i].dtn_name) == undefined
          ? (finalString += tData[i].dtn_name + '\n\n')
          : '\n';
      } */

      let title = tData[i].title.trim();
      console.log('title', title);
      if (title) {
        finalString += '\n\n' + $('<div/>').html(tData[i].title).text() + '\n\n';
        title = $('<div/>').html(tData[i].title).text();
      } else {
        finalString += '\n';
      }

      let entry = tData[i].entry ? tData[i].entry : '';
      let stop = tData[i].stop ? tData[i].stop : '';
      let target = tData[i].target ? tData[i].target : '';

      if (!entry || !target || !stop) {
        console.log(tData[i].description, 'desc');
        tData[i].description
          ? (finalString +=
              `\n${tData[i].subtitle ? tData[i].subtitle : ''}:` +
              changeParseData(sanitizeHtml(tData[i].description, allowP)).replace('\n', ''))
          : '';
      } else {
        finalString += `${
          tData[i].subtitle ? tData[i].subtitle : ''
        }:  Hold ${title} from ${entry} with ${stop} stop. Exit ${target}`;
      }

      for (let j = 0; j < tData[i].sections.length; j++) {
        let temp = tData[i].sections[j];
        console.log(temp.title);
        temp.title ? (finalString += '\n\n' + $('<div/>').html(temp.title).text() + '\n') : '\n\n';
        for (let k = 0; k < temp.rows.length; k++) {
          for (let l = 0; l < temp.rows[k].columns.length; l++) {
            /* temp.rows[k].columns[l].colValue
													 ? (finalString += sanitizeHtml(temp.rows[k].columns[l].colValue, sanitizeConf).replace(/\s+/g, ' ') + '\n')
													 : ''; */
            if (temp.rows[k].columns[l].colType === 'editor') {
              let tempStr = sanitizeHtml(temp.rows[k].columns[l].colValue, allowP);
              finalString += changeParseData(tempStr);
            }
            /*  tempStr.replace(/\w([ ]{2,})\w/g, ' ')
													 
													 tempStr = tempStr.replace(/<p[^>]*>/g, "");
													 tempStr = tempStr.replace(/<\/p[^>]*>/g, "\n\n")
													 
													 tempStr = tempStr.replace(/<tr[^>]*>/g, "");
													 tempStr = tempStr.replace(/<\/tr[^>]*>/g, "\n");
													 
													 finalString += tempStr */
          }
        }
      }
    }
  }

  function changeParseData(data) {
    let parsed = $.parseHTML(data);
    let changedStr = '';
    let blankCount = 0;
    console.log('parsed', parsed);
    for (let x = 0; x < parsed.length; x++) {
      if (parsed[x].localName === 'table') {
        for (let y = 0; y < parsed[x].rows.length; y++) {
          let tr = parsed[x].rows[y];
          for (let z = 0; z < tr.cells.length; z++) {
            changedStr += tr.cells[z].innerText.trim().replace(/[\n\r]/g, '') + '\t';
          }
          changedStr += '\n';
        }
        changedStr += '\n';
      } else if (parsed[x].localName === 'li') {
        changedStr += '\n' + parsed[x].textContent.trim().replace('\n', '');
      } else {
        if (parsed[x].textContent.replace(/[\n\r]/g, '').trim() === '') {
          blankCount += 1;
        } else {
          if (blankCount >= 3) {
            changedStr += '\n\n';
          } else {
            changedStr += '\n\n';
          }
          changedStr += parsed[x].textContent.trim();
          blankCount = 0;
        }
      }
    }
    return changedStr;
  }

  if (params.terms) {
    if (params.isCopy == false) {
      finalString += `\n\n\n\\PM:08006768\nSTATEMENT OF DISCLAIMER/TRADING INSTRUCTIONS\n`;
    } else {
      finalString += `\n\nProduct Disclaimer\n`;
    }
    console.log('params.terms', params.terms);
    finalString += changeParseData(params.terms) + '\n\n';
  }

  /* TABS END */
  return finalString;
};

export { changeData };

const DTNString = () => {
  return <div></div>;
};

export default DTNString;
