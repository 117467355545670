import { navigate } from '@reach/router';
import React from 'react';
import SEO from '../components/SEO';

const About = () => (
  <>
    <SEO
      title="Who is Fortucast? • Fortucast"
      description="Meet Barry Rosen. Daily he analyzes futures, stocks, and ETFs. His trade recommendations derive from Gann, Elliot Wave and his own proprietary cyclical analysis."
      keywords="barry rosen, elliot wave theorist, gann, analyst"
      author="Barry Rosen"
      site_name="Fortucast"
      type="website"
      og_url={`${process.env.SITE_URL}/about`}
      og_title="Who is Fortucast? • Fortucast"
      og_description="Meet Barry Rosen. Daily he analyzes futures, stocks, and ETFs. His trade recommendations derive from Gann, Elliot Wave and his own proprietary cyclical analysis."
      og_image="og-white-logo.png"
      og_image_type="image/png"
      og_image_width="1200"
      og_image_height="630"
      og_image_alt="Fortucast Market Timing"
      twitter_title="Who is Fortucast? • Fortucast"
      twitter_description="Meet Barry Rosen. Daily he analyzes futures, stocks, and ETFs. His trade recommendations derive from Gann, Elliot Wave and his own proprietary cyclical analysis."
      twitter_image="og-twitter-image.png"
      twitter_image_alt="Fortucast Market Timing"
      twitter_card="summary"
      twitter_site="@fortucast"
      twitter_creator="@fortucast"
    />
    <div id="gatsby-focus-wrapper">
      <main className="why-choose-us-content-text">
        <section className="about-wrapper">
          <div className="container">
            <div className="about-text-box">
              <figure className="about-img-box">
                <div className="gatsby-image-wrapper">
                  <div aria-hidden="true" className="image-guard" />
                  <img
                    aria-hidden="true"
                    src="/images/fortucast-bio-barry.jpg"
                    alt="Barry Rosen"
                    className="img-class"
                  />
                </div>
              </figure>
              <div className="about-text">
                <h1 className="about-text-h1">Barry Rosen</h1>
                <p className="about-text-p">Consistently ranked high in Timer Digest.</p>
              </div>
            </div>
          </div>
        </section>
        <section className="about-grey-section">
          <div className="container">
            <div className="about-grey-section-wrap">
              <p className="about-grey-section-wrap-small">Since 1987</p>
              <p className="about-grey-section-wrap-p">
                Barry Rosen has been the editor and publisher of Fortucast Market Timers, which cover over 15 futures
                markets, 10-15 stocks and 10-20 ETF’s. <br />
                <br />
                Mr. Rosen derives his trade recommendations from GANN, ELLIOTT WAVE and his own proprietary cyclical
                analysis. His frequent timing for entries at critical points in the market can only be described as
                uncanny!
              </p>
            </div>
          </div>
        </section>
        <section className="about-report-wrap">
          <div className="container">
            <div className="about-report-wrap-text">
              <h2 className="about-report-wrap-text-h2">October 2007</h2>
              <p className="about-text-p">
                Barry predicted a major top for the stock market and a 2-3 year decline. It was projected at that time
                to be a 33% correction. Mr. Rosen also operates intra-day hotlines for the S&amp;P.
              </p>
              <a
                href="javascript:void(0);"
                className="about-report-wrap-text-link"
                onClick={() => navigate('/market-reports')}
              >
                Get his reports
                <svg className="about-report-wrap-text-btn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-arrow-right"
                  >
                    <line x1={5} y1={12} x2={19} y2={12} />
                    <polyline points="12 5 19 12 12 19" />
                  </svg>
                </svg>
              </a>
            </div>
          </div>
        </section>
      </main>
    </div>
  </>
);

export default About;
