import React from 'react';
import { Router } from '@reach/router';
import Header from './components/common/header';
import Footer from './components/common/footer';
import Home from '../home';
import About from '../about';
import Terms from '../terms';
import Privacy from '../privacy';
import Agricultural from '../market-reports/agricultural';
import Subscriptions from '../market-reports';
import Financial from '../market-reports/financial';
import ETF from '../market-reports/etf';
import UserLogin from '../login';
import UserProducts from '../dashboard';
import ReportList from '../product-reports';
import Reports from '../reports';
import ThankYou from '../thank-you';
import ContactUs from '../contact-us';
import Profile from '../profile';

const FrontApp = (props) => {
  return (
    <main className="why-choose-us-content-text">
      <Header />
      <div className="height-fix">
        <Router>
          <Home path="/" />
          <UserLogin path="/login" />
          <UserProducts path="/dashboard" />
          <ReportList path="/product-reports/:id" />
          <Reports path="/reports/:id" />
          <About path="/about" />
          <Terms path="/terms" />
          <Privacy path="/privacy" />
          <Subscriptions path="/market-reports" />
          <Agricultural path="/market-reports/agricultural" />
          <Financial path="/market-reports/financial" />
          <ETF path="/market-reports/etf" />
          <ThankYou path="/thank-you" />
          <ContactUs path="/contact-us" />
          <Profile path="/profile" />
        </Router>
      </div>
      <Footer location={props.location} />
    </main>
  );
};

export default FrontApp;
