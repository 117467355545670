import React, { useRef, useState } from 'react';
import { Card, CardBody } from '@paljs/ui/Card';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import styled from 'styled-components';
import firebase from 'gatsby-plugin-firebase';
import { Toastr } from '@paljs/ui/Toastr';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uid: '',
      email: '',
      user: {
        name: '',
        phone: '',
        address: '',
      },
    };
    this.toastrRef = React.createRef();
  }

  componentDidMount() {
    let _self = this;
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then(function (doc) {
            if (doc.exists) {
              let userData = {
                name: doc.data().name ? doc.data().name : '',
                phone: doc.data().phone ? doc.data().phone : '',
                address: doc.data().address ? doc.data().address : '',
              };
              _self.setState({ user: userData, uid: user.uid, email: user.email });
            }
          });
      }
    });
  }

  onChange = (event) => {
    this.setState({
      user: {
        ...this.state.user,
        [event.target.name]: event.target.value,
      },
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let _self = this;
    firebase
      .firestore()
      .collection('users')
      .doc(this.state.uid)
      .update(this.state.user)
      .then(function () {
        _self.toastrRef.current?.add('Details Updated Success', 'Success', { status: 'Success' });
      })
      .catch(function (error) {
        _self.toastrRef.current?.add('Could Not Update', 'Error', { status: 'Danger' });
      });
  };

  render() {
    const { user } = this.state;
    return (
      <>
        <Row>
          <Toastr ref={this.toastrRef} />
          <Col breakPoint={{ xs: 12, md: 12 }}>
            <form onSubmit={this.onSubmit}>
              <Card>
                <header>Account Information</header>
                <CardBody>
                  <Input fullWidth shape="SemiRound">
                    <input
                      type="text"
                      placeholder="Enter Full Name"
                      name="name"
                      value={user.name}
                      onChange={this.onChange}
                    />
                  </Input>
                  <Input fullWidth shape="SemiRound">
                    <input type="text" placeholder="Enter Email" value={this.state.email} readOnly />
                  </Input>
                  <Input fullWidth shape="SemiRound">
                    <input
                      type="number"
                      placeholder="Enter Phone Number"
                      name="phone"
                      onChange={this.onChange}
                      value={user.phone}
                    />
                  </Input>
                  <Input fullWidth shape="SemiRound">
                    <textarea
                      rows={5}
                      placeholder="Address"
                      onChange={this.onChange}
                      name="address"
                      value={user.address}
                    />
                  </Input>
                  <Button shape="SemiRound" type="submit">
                    Save Details
                  </Button>
                </CardBody>
              </Card>
            </form>
          </Col>
        </Row>
      </>
    );
  }
}

export default Profile;
