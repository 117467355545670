import React from 'react';
import SEO from '../components/SEO';

const Privacy = () => (
  <>
    <SEO
      title="Privacy Policy • Fortucast"
      description="Fortucast Market Timing aims to provide you with a rewarding visit to sites in the Fortucast Network (fortucast.com, commoditytimers.com and etftimertoday.com) as well as safeguard your privacy online. This page provides key points of Fortucast's privacy statement about the information we collect."
      keywords=""
      site_name="Fortucast"
      type="website"
      og_url={`${process.env.SITE_URL}/privacy`}
      og_title="Privacy Policy • Fortucast"
      og_description="Fortucast Market Timing aims to provide you with a rewarding visit to sites in the Fortucast Network (fortucast.com, commoditytimers.com and etftimertoday.com) as well as safeguard your privacy online. This page provides key points of Fortucast's privacy statement about the information we collect."
      og_image="og-white-logo.png"
      og_image_type="image/png"
      og_image_width="1200"
      og_image_height="630"
      og_image_alt="Fortucast Market Timing"
      twitter_title="Privacy Policy • Fortucast"
      twitter_description="Fortucast Market Timing aims to provide you with a rewarding visit to sites in the Fortucast Network (fortucast.com, commoditytimers.com and etftimertoday.com) as well as safeguard your privacy online. This page provides key points of Fortucast's privacy statement about the information we collect."
      twitter_image="og-twitter-image.png"
      twitter_image_alt="Fortucast Market Timing"
      twitter_card="summary"
      twitter_site="@fortucast"
      twitter_creator="@fortucast"
    />
    <section className="grey-section-wrapper">
      <div className="container">
        <div className="cms-wrap">
          <div className="cms-wrap-content">
            <h1 className="cms-wrap-content-h1">Privacy Policy</h1>
            <p className="cms-wrap-content-p">
              Respecting the privacy and security of your personal information is important to us. Please read this
              Privacy Policy carefully. We do not disclose any nonpublic personal information about our customers or
              former customers to anyone, except as permitted by law.
            </p>
            <h3 className="cms-wrap-content-h3">Collection of Information</h3>
            <p className="cms-wrap-content-p">We collect personal information about you from the following sources:</p>
            <ul className="cms-wrap-content-ul">
              <li className="cms-wrap-content-ul-li">Information we receive from you on email trials and orders;</li>
              <li className="cms-wrap-content-ul-li">Information about your transactions with us</li>
            </ul>
            <p />
            <h3 className="cms-wrap-content-h3">
              Information Sharing with Non-affiliated Third Parties as Permitted by law
            </h3>
            <p className="cms-wrap-content-p-small">
              We are permitted by law to share all the information we collect, as described above, with (1) companies
              that perform marketing services on our behalf and (2) other third parties that assist us with preparing
              and processing orders.
            </p>
            <h3 className="cms-wrap-content-h3">Confidentiality and Security</h3>
            <p className="cms-wrap-content-p">
              We restrict access to nonpublic personal information about you to those employees who need to know that
              information to provide products or services to you. We maintain physical, electronic and procedural
              safeguards that are designed to protect your nonpublic information.
            </p>
            <h3 className="cms-wrap-content-h3">Disclaimer</h3>
            <p className="cms-wrap-content-p">
              In the event that you do not become a Fortucast customer, we may offer your information to other firms in
              the industry who have other futures products that may be of interest to you and benefit you. In the event
              that you want your name kept private, please send a written request to support@fortucast.com or write us
              at: Fortucast, PO Box 266 Fairfield, IA 52556.
            </p>
            <h3 className="cms-wrap-content-h3">Opt out</h3>
            <p className="cms-wrap-content-p">
              Send emails to support@fortucast.com or write us at Fortucast, PO Box 266 Fairfield, IA 52556.
            </p>
          </div>
        </div>
      </div>
    </section>
  </>
);

export default Privacy;
