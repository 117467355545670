import React from 'react';
import { navigate } from 'gatsby';
import Tooltip from '@paljs/ui/Tooltip';
import { ButtonLink } from '@paljs/ui/Button';
import firebase from 'gatsby-plugin-firebase';
import { Toastr } from '@paljs/ui/Toastr';
import ConfirmModal from '../modal-overlays/confirm-modal';
import { Button } from '@paljs/ui/Button';
import Loader from '../modal-overlays/loader';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';

class UserListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listing: [],
      isModalOpen: false,
      deleteIds: {},
      lastDoc: null,
      firstDoc: null,
      limit: 10,
      disableNext: false,
      page: 1,
      searchVal: '',
      isLoading: false,
    };
    this.toastrRef = React.createRef();
  }

  componentDidMount() {
    this.getData();
  }

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  formatDate = (seconds) => {
    let date = new Date(seconds * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  getData = () => {
    this.setState({ isLoading: true });
    let _self = this;
    firebase
      .firestore()
      .collection('users')
      .orderBy('createdAt', 'desc')
      .limit(this.state.limit)
      .get()
      .then((snapshot) => {
        let tempArr = [];
        let temp = snapshot.docs[snapshot.docs.length - 1];
        snapshot.forEach((doc) => {
          tempArr.push({ id: doc.id, ...doc.data(), addedDate: this.formatDate(doc.data().createdAt.seconds) });
        });
        _self.setState({
          listing: tempArr,
          lastDoc: temp,
          isLoading: false,
          disableNext: !tempArr.length ? true : false,
        });
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  handleDelete = (uid, stripe_id) => {
    this.setState({ deleteIds: { uid, stripe_id }, isModalOpen: true });
  };

  nextPage = () => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('users')
      .orderBy('createdAt', 'desc')
      .startAfter(this.state.lastDoc)
      .limit(this.state.limit)
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length == 0) {
          _self.setState({ disableNext: true, isLoading: false });
        } else {
          let tempArr = [];
          let last = snapshot.docs[snapshot.docs.length - 1];
          let first = snapshot.docs[0];
          snapshot.forEach((doc) => {
            tempArr.push({ id: doc.id, ...doc.data() });
          });
          _self.setState({
            listing: tempArr,
            firstDoc: first,
            lastDoc: last,
            page: this.state.page + 1,
            isLoading: false,
          });
        }
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  previousPage = () => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('users')
      .orderBy('createdAt', 'desc')
      .endBefore(this.state.firstDoc)
      .limitToLast(this.state.limit)
      .get()
      .then((snapshot) => {
        let tempArr = [];
        let last = snapshot.docs[snapshot.docs.length - 1];
        let first = snapshot.docs[0];
        snapshot.forEach((doc) => {
          tempArr.push({ id: doc.id, ...doc.data() });
        });
        _self.setState({
          listing: tempArr,
          firstDoc: first,
          lastDoc: last,
          page: this.state.page - 1,
          disableNext: false,
          isLoading: false,
        });
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  confirmDelete = () => {
    let _self = this;
    this.setState({ isLoading: true });
    let config = {
      headers: { 'Content-Type': 'application/json' },
    };

    let data = {
      user_id: this.state.deleteIds.uid,
      stripe_cust_id: this.state.deleteIds.stripe_id,
    };

    axios
      .post(`${process.env.API_ENDPOINT}/delete-user`, data, config)
      .then((res) => {
        _self.toastrRef.current?.add('Deleted Successfully', 'Success', { status: 'Success' });
        _self.setState({ isModalOpen: false, page: 1, isLoading: false, searchVal: '' });
        _self.getData();
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
        _self.toastrRef.current?.add(e.response.data.msg, 'Error', { status: 'Danger' });
      });
  };

  changeStatus = (id, type) => {
    let tempStaus = { label: type == 'active' ? 'Active' : 'Inactive', value: type };
    let _self = this;
    this.setState({ isLoading: true });
    /*  firebase
          .firestore()
          .collection('users')
          .doc(id)
          .update({ status: tempStaus })
          .then(function () {
            _self.toastrRef.current?.add('Updated Successfully', 'Success', { status: 'Success' });
            _self.setState({ page: 1, isLoading: false });
            _self.getData();
          })
          .catch(function (error) {
            _self.setState({ isLoading: false });
            _self.toastrRef.current?.add('Error Updating Data', 'Error', { status: 'Danger' });
          }); */

    let config = {
      headers: { 'Content-Type': 'application/json' },
    };

    let data = {
      new_status: type,
      uid: id,
      statusObj: tempStaus,
    };

    axios
      .post(`${process.env.API_ENDPOINT}/user-status`, data, config)
      .then((res) => {
        _self.toastrRef.current?.add('Updated Successfully', 'Success', { status: 'Success' });
        _self.setState({ page: 1, isLoading: false, searchVal: '' });
        _self.getData();
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
        _self.toastrRef.current?.add('Error Updating Data', 'Error', { status: 'Danger' });
      });
  };

  search = () => {
    let _self = this;
    if (this.state.searchVal === '') {
      _self.toastrRef.current?.add('No search provided', 'Error', { status: 'Danger' });
      return;
    }
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('users')
      .where('name', '>=', this.state.searchVal.toLowerCase())
      .where('name', '<=', this.state.searchVal.toLowerCase() + '\uf8ff')
      .get()
      .then((snapshot) => {
        let tempArr = [];
        snapshot.forEach((doc) => {
          tempArr.push({ id: doc.id, ...doc.data(), addedDate: this.formatDate(doc.data().createdAt.seconds) });
        });
        return tempArr;
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      })
      .then((res) => {
        firebase
          .firestore()
          .collection('users')
          .where('email', '>=', this.state.searchVal)
          .where('email', '<=', this.state.searchVal + '\uf8ff')
          .get()
          .then((snapshot) => {
            let tempArr = [...res];
            snapshot.forEach((doc) => {
              if (!tempArr.find((item) => item.id === doc.id)) {
                tempArr.push({ id: doc.id, ...doc.data(), addedDate: this.formatDate(doc.data().createdAt.seconds) });
              }
            });
            _self.setState({
              listing: tempArr,
              disableNext: true,
              isLoading: false,
              page: 1,
            });
          })
          .catch((e) => {
            _self.setState({ isLoading: false });
          });
      });
  };

  clear = () => {
    this.setState({
      searchVal: '',
    });
    this.getData();
  };

  checkEnter = (e) => {
    if (e.key === 'Enter') {
      this.search();
    }
  };

  render() {
    return (
      <>
        <Toastr ref={this.toastrRef} />
        <div className="container table-responsive">
          <div className="d-flex justify-content-between mb-3">
            <h4>User Listing</h4>
            <div className="d-flex justify-content-between">
              <input
                onKeyDown={this.checkEnter}
                type="text"
                value={this.state.searchVal}
                onChange={(e) => this.setState({ searchVal: e.target.value })}
                className="form-control mr-2"
                placeholder="Search"
              />
              <Button onClick={this.search} type="button" appearance="outline" className="mr-2">
                <EvaIcon name="search-outline" />
              </Button>
              <Button onClick={this.clear} type="button" appearance="outline" status="Danger">
                <EvaIcon name="close-outline" />
              </Button>
            </div>
            <button className="btn btn-primary" onClick={() => navigate('/admin/user-management/user-form')}>
              Add User
            </button>
          </div>
          <table className="table table-light">
            <thead className="thead-dark">
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Added Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.listing.length ? (
                this.state.listing.map((list, i) => (
                  <tr key={i}>
                    <td>{list.name}</td>
                    <td>{`${list.email} ${list.isAdmin ? '(admin)' : ''}`}</td>
                    <td>{list.addedDate}</td>
                    <td>
                      {list.status.value == 'active' ? (
                        <ButtonLink
                          appearance="outline"
                          status="Danger"
                          onClick={() => this.changeStatus(list.id, 'inactive')}
                        >
                          Deactivate
                        </ButtonLink>
                      ) : (
                        <ButtonLink
                          appearance="outline"
                          status="Success"
                          onClick={() => this.changeStatus(list.id, 'active')}
                        >
                          Activate
                        </ButtonLink>
                      )}
                    </td>
                    <td className="actions">
                      <Tooltip className="with-margin inline-block" trigger="hover" placement="right" content="Edit">
                        <Button
                          appearance="ghost"
                          onClick={() => navigate(`/admin/user-management/user-form/${list.id}`)}
                        >
                          <EvaIcon name="edit-outline" />
                        </Button>
                      </Tooltip>
                      <Tooltip className="with-margin inline-block" trigger="hover" placement="right" content="Delete">
                        <Button
                          appearance="ghost"
                          onClick={() => this.handleDelete(list.id, list.stripe_cust_id ? list.stripe_cust_id : '')}
                        >
                          <EvaIcon name="trash-outline" />
                        </Button>
                      </Tooltip>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li className={`page-item ${this.state.page == 1 && 'disabled'}`}>
                <div className="page-link cursor-pointer" onClick={this.previousPage}>
                  <EvaIcon name="arrowhead-left-outline" />
                </div>
              </li>
              <li className={`page-item ${this.state.disableNext && 'disabled'}`}>
                <div className="page-link cursor-pointer" onClick={this.nextPage}>
                  <EvaIcon name="arrowhead-right-outline" />
                </div>
              </li>
            </ul>
          </nav>
          <ConfirmModal isOpen={this.state.isModalOpen} close={this.toggleModal} accept={this.confirmDelete} />
        </div>
        <Loader isOpen={this.state.isLoading} />
      </>
    );
  }
}

export default UserListing;
