import React from 'react';

const TrackRecord = () => (
  <section className="banner-section">
    <div className="container">
      <h2 className="proven-track-h2">Customers Appreciate our Service</h2>
      <div className="proven-track-wrap">
        <div className="proven-track-box">
          <p className="proven-track-box-review">
            I have been a subscriber for many years and the first thing I do every morning is access Fortucast and read
            your financial forecast. You should be very proud. You do not last 32 years if you are not good. Very best
            luck for the future.
          </p>
          <p className="proven-track-box-name">B.L.</p>
          <p className="proven-track-box-country">South Carolina</p>
        </div>
        <div className="proven-track-box">
          <p className="proven-track-box-review">
            Since getting your full service with intra-day updates in the last year, I better understand what goes into
            just how difficult your job is and how comprehensive your “guys” and you actually are. I continue to use
            your service for different decision making processes but in the end has been a growing benefit to our
            business. I’ve ALSO gotten smarter in how to use your “stuff”. After 10 years+ getting Financial Fortucast,
            giving credit where credit is due.
          </p>
          <p className="proven-track-box-name">P.R.</p>
          <p className="proven-track-box-country">LPL Financials</p>
        </div>
        <div className="proven-track-box">
          <p className="proven-track-box-review">
            I am amazed with your service. Your direction indicators serve as a road map through the volatility of the
            financial markets. Thank you!
          </p>
          <p className="proven-track-box-name">E.G.</p>
          <p className="proven-track-box-country">Bismark, N.D.</p>
        </div>
      </div>
    </div>
  </section>
);

export default TrackRecord;
