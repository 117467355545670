import { MenuItemType } from '@paljs/ui/types';

const items: MenuItemType[] = [
  {
    title: 'Dashboard',
    icon: { name: 'home' },
    link: { to: '/admin/dashboard' },
  },
  {
    title: 'Reports',
    icon: { name: 'clipboard-outline' },
    link: { to: '/admin/report-management/report-listing' },
  },
  {
    title: 'Products',
    icon: { name: 'lock-outline' },
    link: { to: '/admin/products/products-listing' },
  },
  {
    title: 'Instruments',
    icon: { name: 'keypad-outline' },
    link: { to: '/admin/instruments/instruments-listing' },
  },
  {
    title: 'Users',
    icon: { name: 'star-outline' },
    link: { to: '/admin/user-management/user-listing' },
  },
  {
    title: 'Subscriptions',
    icon: { name: 'book-open-outline' },
    link: { to: '/admin/user-subscriptions/user-subscriptions' },
  },
];

export default items;
