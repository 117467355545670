import React, { Component } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { Link, navigate } from 'gatsby';
import Loader from './admin/modal-overlays/loader';
import ContactBoxes from './frontend/components/common/contact-boxes';
import { Helmet } from 'react-helmet';
import axios from 'axios';

class Reports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      report: {},
      isLoading: false,
      image: null,
    };
  }

  componentDidMount() {
    this.checkUser();
  }

  checkUser = () => {
    this.setState({ isLoading: true });
    let _self = this;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        _self.setState({ isLoading: false });
        this.changeReadStatus(user.uid);
      } else {
        _self.setState({ isLoading: false });
        localStorage.removeItem('user');
        localStorage.setItem('redirectTo', this.props.location.pathname);
        navigate('/login');
      }
    });
  };

  getReport = (uid) => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('reports')
      .doc(this.props.id)
      .get()
      .then((report) => {
        firebase
          .firestore()
          .collection('user_subscriptions')
          .where('userId', '==', uid)
          .where('product_id', '==', report.data().selectedProduct.value)
          .where('status.value', '==', 'active')
          .get()
          .then((snapShot) => {
            if (snapShot.docs.length === 0) {
              navigate('/dashboard');
            } else {
              axios
                .get(`${process.env.API_ENDPOINT}/return-doc`, {
                  responseType: 'arraybuffer',
                  params: { docId: this.props.id, uid },
                  headers: {
                    'Content-Type': 'application/json',
                  },
                })
                .then((response) => {
                  let url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                  this.renderPdfFile(url);
                })
                .catch((e) => {
                  _self.setState({ isLoading: false });
                });

              _self.setState({ isLoading: false, report: report.data() });
              _self.scrollUp();
            }
          });
      })
      .catch((err) => {
        _self.setState({ isLoading: false });
      });
  };

  formatDate = (seconds) => {
    let date = new Date(seconds * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  scrollUp = () => {
    window.scrollTo(0, 0);
  };

  changeReadStatus = (uid) => {
    firebase
      .firestore()
      .collection('user_reports')
      .where('userId', '==', uid)
      .where('reportId', '==', this.props.id)
      .get()
      .then((snapShot) => {
        if (snapShot.docs.length == 0) {
          firebase
            .firestore()
            .collection('user_reports')
            .add({ userId: uid, reportId: this.props.id })
            .then((res) => {})
            .catch((e) => {});
        }
        this.getReport(uid);
      })
      .catch((e) => {});
  };

  /* scrollToHeading = (id) => {
        console.log(document.getElementById('nav-tabs')?.offsetHeight);
        let offset =
           document.getElementById('nav-tabs')?.offsetHeight + document.getElementById('header')?.offsetHeight + 2; // sticky nav height
        let el = document.getElementById(id); // element you are scrolling to
        window.scroll({ top: el.offsetTop - offset, left: 0, behavior: 'smooth' });
     }; */

  onDocumentLoadSuccess = () => {};

  renderPdfFile = (url) => {
    function renderPage(page) {
      var viewport = page.getViewport(2);
      var wrapper = document.createElement('div');
      wrapper.className = 'canvas-wrapper';
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      var renderContext = {
        canvasContext: ctx,
        viewport: viewport,
      };

      canvas.height = viewport.height;
      canvas.width = viewport.width;
      wrapper.appendChild(canvas);
      document.getElementById('holder').appendChild(wrapper);

      page.render(renderContext);
    }

    function renderPages(pdfDoc) {
      for (var num = 1; num <= pdfDoc.numPages; num++) pdfDoc.getPage(num).then(renderPage);
    }

    window.PDFJS.disableWorker = true;
    window.PDFJS.getDocument(url).then(renderPages);
  };

  render() {
    const { report } = this.state;
    let report_date = report.reportDate && this.formatDate(report.reportDate.seconds);
    return (
      <>
        <Helmet>
          <link rel="stylesheet" href="/ck.css" />
          <script src="https://cdn.jsdelivr.net/npm/pdfjs-dist@2.0.385/build/pdf.min.js"></script>
          <style type="text/css">
            {`
						#holder{
							background: #fff;
  						   padding: 32px 0 16px 0;
						}
						
						.canvas-wrapper{
							margin-bottom: 16px;
						}
						
						canvas{
                     max-width: 100%;
							margin: 0 auto;
  						   display: block;
						}`}
          </style>
        </Helmet>

        <div className="details-wrapper" onContextMenu={(e) => e.preventDefault()}>
          <div className="footer-fix details-wrapper-dynamic">
            {this.state.report && (
              <div className="container pt-3">
                <div id="details-wrap">
                  {report.selectedProduct && report.reportType ? (
                    <h1>
                      {report.selectedProduct.label}-{report.reportType.label}
                    </h1>
                  ) : null}
                  <p className="report-date">
                    Report Date: <span>{report_date}</span>
                  </p>
                  {report.ov_title ? <h4>{report.ov_title}</h4> : null}
                  {report.ov_description ? (
                    <div className="row custom-table">
                      <div className="col-12">
                        <div dangerouslySetInnerHTML={{ __html: report.ov_description }} />
                      </div>
                    </div>
                  ) : null}
                  <br />

                  <div id="holder"></div>
                </div>
                <button onClick={this.scrollUp} className="scroll-up">
                  &#8679;
                </button>
              </div>
            )}

            {report.product_disclaimer ? (
              <div className="container">
                <h5>Product Disclaimer</h5>
                <div dangerouslySetInnerHTML={{ __html: report.product_disclaimer }} />
              </div>
            ) : null}

            <div className="container" id="contact-us">
              <ContactBoxes />
            </div>
            <Loader isOpen={this.state.isLoading} />
          </div>
        </div>
      </>
    );
  }
}

export default Reports;
