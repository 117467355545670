import React from 'react';
import { Card, CardBody } from '@paljs/ui/Card';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import styled from 'styled-components';
import firebase from 'gatsby-plugin-firebase';
import { Toastr } from '@paljs/ui/Toastr';
import Select from '@paljs/ui/Select';
import Loader from '../modal-overlays/loader';
import { navigate } from 'gatsby';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const statusOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

const boolOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];

const initial_state = {
  name: '',
  email: '',
  phone: '',
  address: '',
  createdAt: null,
  subscription_id: '',
  status: { label: 'Active', value: 'active' },
  sendPdf: false,
  secondaryEmail: '',
  stripe_cust_id: '',
};

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: { ...initial_state },
      errors: {},
      new_email: '',
      isLoading: false,
      oldSecEmail: '',
    };
    this.toastrRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.id) {
      this.setData();
    }
  }

  setData = () => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('users')
      .doc(this.props.id)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          let created = new Date(doc.data().createdAt.seconds * 1000);
          let secEmail = doc.data().secondaryEmail ? doc.data().secondaryEmail : '';
          _self.setState({
            data: { ...doc.data(), createdAt: created, secondaryEmail: secEmail },
            errors: {},
            isLoading: false,
            oldSecEmail: secEmail,
            oldPdfStatus: doc.data().sendPdf === true || doc.data().sendPdf === false ? doc.data().sendPdf : '',
          });
        } else {
          _self.setState({ error: 'Invalid', isLoading: false });
        }
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  handleSelect = (values, field) => {
    this.setState({ data: { ...this.state.data, [field]: values } });
  };

  onChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  handleDate = (date) => {
    this.setState({ data: { ...this.state.data, createdAt: date } });
  };

  validateFields = () => {
    let formSubmit = true;
    let tempErrors = {};

    if (!this.state.data.name) {
      tempErrors['name'] = 'This field is required';
      formSubmit = false;
    } else if (this.state.data.name && !/^[a-zA-Z ]+$/i.test(this.state.data.name)) {
      tempErrors['name'] = 'Invalid Name';
      formSubmit = false;
    } else if (!this.state.data.email) {
      tempErrors['email'] = 'This field is required';
      formSubmit = false;
    } else if (this.state.data.email && !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g.test(this.state.data.email)) {
      tempErrors['email'] = 'Invalid Email';
      formSubmit = false;
    } else if (
      this.state.data.secondaryEmail &&
      !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g.test(this.state.data.secondaryEmail)
    ) {
      tempErrors['secondaryEmail'] = 'Invalid Email';
      formSubmit = false;
    } else if (this.state.data.email === this.state.data.secondaryEmail) {
      tempErrors['secondaryEmail'] = 'Primary and secondary email cannot be same';
      formSubmit = false;
    } else if (!this.state.data.phone) {
      tempErrors['phone'] = 'This field is required';
      formSubmit = false;
    } else if (this.state.data.phone && !/^[0-9]{10}$/g.test(this.state.data.phone)) {
      tempErrors['phone'] = 'Invalid phone number';
      formSubmit = false;
    } else if (!this.state.data.address) {
      tempErrors['address'] = 'This field is required';
      formSubmit = false;
    } else if (this.state.data.sendPdf === '') {
      tempErrors['sendPdf'] = 'This field is required';
      formSubmit = false;
    }

    this.setState({ errors: tempErrors });
    return formSubmit;
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.validateFields()) {
      if (this.props.id) {
        this.updateDetails();
      } else {
        this.addData();
      }
    }
  };

  updateDetails = () => {
    let _self = this;
    this.setState({ isLoading: true });
    let newSecEmail = this.state.data.secondaryEmail;

    if (this.state.oldSecEmail !== newSecEmail || this.state.oldPdfStatus !== this.state.data.sendPdf) {
      firebase
        .firestore()
        .collection('user_subscriptions')
        .where('userId', '==', this.props.id)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.docs.length > 0) {
            var batch = firebase.firestore().batch();

            querySnapshot.forEach((doc) => {
              //console.log(doc.id)
              batch.update(doc.ref, {
                secondary_email: newSecEmail,
                sendPdf: this.state.data.sendPdf,
              });
            });

            return batch.commit();
          }
        })
        .then(() => {
          this.confirmUpdate();
        });
    } else {
      this.confirmUpdate();
    }
  };

  confirmUpdate = () => {
    let _self = this;
    firebase
      .firestore()
      .collection('users')
      .doc(this.props.id)
      .update(this.state.data)
      .then(() => {
        _self.setState({ isLoading: false });
        _self.toastrRef.current?.add('User updated successfully', 'Success', { status: 'Success' });
        setTimeout(() => {
          navigate('/admin/user-management/user-listing');
        }, 500);
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
        _self.toastrRef.current?.add('Error Updating Data', 'Error', { status: 'Danger' });
      });
  };

  addData = () => {
    let _self = this;
    this.setState({ isLoading: true });

    let config = {
      headers: { 'Content-Type': 'application/json' },
    };

    let data = { ...this.state.data };
    if (data.createdAt === null) {
      data.createdAt = firebase.firestore.Timestamp.now();
    }
    axios
      .post(`${process.env.API_ENDPOINT}/create-user`, data, config)
      .then((res) => {
        _self.setState({ isLoading: false });
        _self.toastrRef.current?.add('User created successfully', 'Success', { status: 'Success' });
        setTimeout(() => {
          navigate('/admin/user-management/user-listing');
        }, 500);
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
        if (e.response.status === 401) {
          _self.toastrRef.current?.add('User already exists with this email', 'Error', { status: 'Danger' });
        } else {
          _self.toastrRef.current?.add('Could Not Add Data', 'Error', { status: 'Danger' });
        }
      });
  };

  updatePrimaryEmail = (e) => {
    e.preventDefault();
    let _self = this;

    if (!this.state.new_email || !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g.test(this.state.new_email)) {
      _self.toastrRef.current?.add('Invalid Email', 'Error', { status: 'Danger' });
    } else {
      this.setState({ isLoading: true });

      let config = {
        headers: { 'Content-Type': 'application/json' },
      };

      let data = { uid: this.props.id, new_email: this.state.new_email, old_email: this.state.data.email };

      axios
        .post(`${process.env.API_ENDPOINT}/change-email`, data, config)
        .then((res) => {
          _self.toastrRef.current?.add('Email updated successfully', 'Success', { status: 'Success' });
          setTimeout(() => {
            navigate('/admin/user-management/user-listing');
          }, 500);
        })
        .catch((e) => {
          _self.setState({ isLoading: false });
          if (e.response.status === 401) {
            _self.toastrRef.current?.add('User already exists with this email', 'Error', { status: 'Danger' });
          } else {
            _self.toastrRef.current?.add('Could not update email', 'Error', { status: 'Danger' });
          }
        });
    }
  };

  render() {
    const { data, errors } = this.state;
    return (
      <>
        <Row>
          <Toastr ref={this.toastrRef} />
          <Col breakPoint={{ xs: 12, md: 12 }}>
            <form onSubmit={this.onSubmit}>
              <Card>
                <header>Account</header>
                <CardBody>
                  <label>Full Name</label>
                  <Input fullWidth shape="SemiRound">
                    <input
                      type="text"
                      placeholder="Enter Full Name"
                      name="name"
                      value={data.name}
                      onChange={this.onChange}
                    />
                  </Input>
                  <p className="form-error">{errors.name}</p>
                  {!this.props.id ? (
                    <>
                      <br />
                      <label>Email</label>
                      <Input fullWidth shape="SemiRound">
                        <input
                          type="text"
                          placeholder="Enter Email"
                          name="email"
                          value={data.email}
                          onChange={this.onChange}
                          readOnly={this.props.id}
                        />
                      </Input>
                      <p className="form-error">{errors.email}</p>
                    </>
                  ) : null}
                  <br />
                  <label>Secondary Email</label>
                  <Input fullWidth shape="SemiRound">
                    <input
                      type="text"
                      placeholder="Enter Secondary Email"
                      name="secondaryEmail"
                      value={data.secondaryEmail}
                      onChange={this.onChange}
                    />
                  </Input>
                  <p className="form-error">{errors.secondaryEmail}</p>
                  <br />
                  <label>Phone Number</label>
                  <Input fullWidth shape="SemiRound">
                    <input
                      type="text"
                      placeholder="Enter Phone Number"
                      name="phone"
                      value={data.phone}
                      onChange={this.onChange}
                      maxLength={10}
                    />
                  </Input>
                  <p className="form-error">{errors.phone}</p>
                  <br />
                  <label>Stripe Customer ID</label>
                  <Input fullWidth shape="SemiRound">
                    <input
                      type="text"
                      placeholder="Enter Stripe Customer ID"
                      name="stripe_cust_id"
                      value={data.stripe_cust_id}
                      onChange={this.onChange}
                    />
                  </Input>
                  <br />
                  <label>Create Date</label>
                  <br />
                  <DatePicker
                    showYearDropdown={true}
                    scrollableYearDropdown={true}
                    yearDropdownItemNumber={5}
                    className="form-control w-100"
                    selected={this.state.data.createdAt}
                    onChange={(date) => this.handleDate(date)}
                  />
                  <br />
                  <br />
                  <label>Address</label>
                  <Input fullWidth shape="SemiRound">
                    <textarea
                      rows={5}
                      placeholder="Address"
                      name="address"
                      value={data.address}
                      onChange={this.onChange}
                    />
                  </Input>
                  <p className="form-error">{errors.address}</p>
                  <br />
                  <label>Status</label>
                  <Select
                    shape="SemiRound"
                    name="status"
                    options={statusOptions}
                    placeholder="Select Status"
                    onChange={(val) => this.handleSelect(val, 'status')}
                    value={this.state.data.status}
                  />
                  <br />
                  <br />

                  <div className="form-group">
                    <label>PDF Attachment</label>
                    <br />
                    <div className="radio-wrap">
                      <div className="radio d-block">
                        <label>
                          <input
                            name="pdfAttachment"
                            type="radio"
                            value="yes"
                            checked={this.state.data.sendPdf === true}
                            onChange={() => this.setState({ data: { ...this.state.data, sendPdf: true } })}
                          />
                          Yes
                        </label>
                      </div>
                      <div className="radio d-block">
                        <label>
                          <input
                            name="pdfAttachment"
                            type="radio"
                            value="no"
                            checked={this.state.data.sendPdf === false}
                            onChange={() => this.setState({ data: { ...this.state.data, sendPdf: false } })}
                          />
                          No
                        </label>
                      </div>
                    </div>
                    <p className="form-error">{this.state.errors.sendPdf}</p>
                  </div>

                  <br />
                  <br />
                  <div className="float-right">
                    <Button shape="SemiRound">Save Details</Button>
                  </div>
                </CardBody>
              </Card>
            </form>
          </Col>
        </Row>
        {this.props.id ? (
          <Row>
            <Col breakPoint={{ xs: 12, md: 12 }}>
              <form onSubmit={this.updatePrimaryEmail}>
                <Card>
                  <header>Change primary email</header>
                  <CardBody>
                    <label>Email</label>
                    <Input fullWidth shape="SemiRound">
                      <input
                        type="text"
                        placeholder="Enter Email"
                        name="email"
                        value={data.email}
                        readOnly={this.props.id}
                      />
                    </Input>
                    <br />
                    <label>New Email</label>
                    <Input fullWidth shape="SemiRound">
                      <input
                        type="text"
                        placeholder="Enter new email"
                        name="new_email"
                        value={this.state.new_email}
                        onChange={(e) => this.setState({ new_email: e.target.value })}
                      />
                    </Input>
                    <p className="form-error">{errors.new_email}</p>
                    <br />
                    <div className="float-right">
                      <Button shape="SemiRound" type="submit">
                        Update Email
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </form>
            </Col>
          </Row>
        ) : null}
        <Loader isOpen={this.state.isLoading} />
      </>
    );
  }
}

export default UserForm;
