import React, { Component } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { navigate } from 'gatsby';
import Loader from '../pages/admin/modal-overlays/loader';
import axios from 'axios';

class UserLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      message: '',
      isLoading: false,
      showForm: false,
      showBack: false,
    };
  }

  componentDidMount() {
    let _self = this;
    this.setState({ isLoading: true, showForm: false });
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        localStorage.setItem('user', 'true');
        if (localStorage.getItem('redirectTo') !== null) {
          let tempUrl = localStorage.getItem('redirectTo');
          localStorage.removeItem('redirectTo');
          navigate(tempUrl);
        } else {
          navigate('/dashboard');
        }
      } else {
        _self.checkURL();
      }
    });
  }

  checkURL = () => {
    let _self = this;
    this.setState({ isLoading: true, message: 'Logging you in', showForm: false });
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      let email = localStorage.getItem('email');
      if (!email) {
        // in case user sends link from one device and goes to another for verifcation
        _self.setState({ message: 'Please provide your email for confirmation', isLoading: false, showForm: true });
      }
      firebase
        .auth()
        .signInWithEmailLink(email, window.location.href)
        .then((res) => {
          _self.setState({ isLoading: false });
          localStorage.removeItem('email');
          this.checkForUser(email, res.user.uid);
        })
        .catch((err) => {
          _self.setState({ isLoading: false, message: 'Invalid URL', showForm: true });
        });
    } else {
      localStorage.removeItem('user');
      _self.setState({ isLoading: false, showForm: true, message: '' });
    }
  };

  checkForUser = (email, uid) => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('users')
      .where('email', '==', email)
      .get()
      .then((snapShot) => {
        if (snapShot.docs.length == 0) {
          //put user in user collecttion if not exists
          firebase
            .firestore()
            .collection('users')
            .doc(uid)
            .set({
              createdAt: firebase.firestore.Timestamp.now(),
              email,
              status: { label: 'Active', value: 'active' },
            })
            .then(() => {
              _self.setState({ isLoading: false });
              navigate('/dashboard');
            })
            .catch((e) => {
              _self.setState({ isLoading: false });
            });
        }
      });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  validateFields = () => {
    let formSubmit = true;

    if (!this.state.email || (this.state.email && !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g.test(this.state.email))) {
      this.setState({ message: 'Please provide a valid email' });
      formSubmit = false;
    }

    return formSubmit;
  };

  onSubmit = () => {
    let _self = this;
    if (this.validateFields()) {
      firebase
        .firestore()
        .collection('users')
        .where('email', '==', this.state.email)
        .get()
        .then((snapShot) => {
          if (snapShot.docs.length > 0) {
            let doc = snapShot.docs[0];
            if (doc.data().hasOwnProperty('isAdmin')) {
              _self.setState({ message: 'Admins can only login via the admin portal', isLoading: false });
            } else {
              if (doc.data().status.value == 'active') {
                this.sendEmailLink();
              } else {
                _self.setState({ message: 'This account is inactive' });
              }
            }
          } else {
            this.sendEmailLink();
          }
        });
    }
  };

  sendEmailLink = () => {
    let _self = this;
    this.setState({ isLoading: true, showBack: false, showForm: false, message: '' });
    let params = { email: this.state.email };
    axios
      .post(`${process.env.API_ENDPOINT}/send-login-link`, params, { headers: { 'Content-Type': 'application/json' } })
      .then((res) => {
        localStorage.setItem('email', this.state.email);
        _self.setState({
          isLoading: false,
          message: 'Please check your email for the login link',
          email: '',
          showForm: false,
          showBack: true,
        });
      })
      .catch((e) => {
        _self.setState({ isLoading: false, message: 'Could not send email. Please try again!' });
      });
  };

  handleEnter = (e) => {
    if (e.keyCode === 13) {
      this.onSubmit();
    }
  };

  render() {
    return (
      <>
        <div className="logo-container">
          <div className="container">
            <div className="login-box">
              <div className="form-group">
                {this.state.showForm ? (
                  <div className="form-div">
                    <h4>Welcome to Fortucast</h4>
                    <input
                      type="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.onChange}
                      className="form-control"
                      placeholder="Email"
                      onKeyDown={(e) => this.handleEnter(e)}
                      autoFocus
                    />
                    <button className="btn btn-primary btn-block my-3" onClick={this.onSubmit}>
                      Submit
                    </button>
                  </div>
                ) : null}
                {this.state.showBack ? (
                  <div className="back-btn">
                    <button
                      className="btn mb-3 btn-primary"
                      onClick={() => this.setState({ showForm: true, showBack: false })}
                    >
                      Back
                    </button>
                  </div>
                ) : null}
                <div className="text-center">
                  <h6>{this.state.message}</h6>
                </div>
              </div>
            </div>
            <Loader isOpen={this.state.isLoading} />
          </div>
        </div>
      </>
    );
  }
}

export default UserLogin;
