import React, { useState } from 'react';
import axios from 'axios';
import Loader from './admin/modal-overlays/loader';
import SEO from '../components/SEO';
import ContactBoxes from './frontend/components/common/contact-boxes';

const ContactUs = () => {
  const [values, setValues] = useState({ name: '', phone: '', email: '', msg: '' });
  const [errors, setErrors] = useState({});
  const [successMsg, setSuccessMsg] = useState('');
  const [errMsg, setErrorMSg] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const validateFields = () => {
    let formSubmit = true;
    let tempErrors = {};

    if (!values.name || !/^[a-zA-Z ]+$/i.test(values.name)) {
      tempErrors['name'] = 'Invalid Name';
      formSubmit = false;
    } else if (!values.email || !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g.test(values.email)) {
      tempErrors['email'] = 'Invalid Email';
      formSubmit = false;
    } else if (!values.phone || !/^[0-9]{10}$/i.test(values.phone)) {
      tempErrors['phone'] = 'Invalid Phone Number';
      formSubmit = false;
    } else if (!values.msg) {
      tempErrors['msg'] = 'This field is required';
      formSubmit = false;
    }

    setErrors(tempErrors);
    return formSubmit;
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (validateFields()) {
      setLoading(true);
      axios
        .post(`${process.env.API_ENDPOINT}/contact-us`, values, { headers: { 'Content-Type': 'application/json' } })
        .then((res) => {
          setErrors({});
          setValues({ name: '', phone: '', email: '', msg: '' });
          setLoading(false);
          setSuccessMsg('Successfully submitted the form');
        })
        .catch((e) => {
          //console.log(e);
          setLoading(false);
          setErrorMSg('Could not submit the form');
        });
    }
  };

  return (
    <>
      <SEO
        title="Contact Us | Customer Care • Fortucast"
        description="Contact us. We are here to help."
        keywords=""
        author="Barry Rosen"
        site_name="Fortucast"
        type="website"
        og_url={`${process.env.SITE_URL}/contact-us`}
        og_title="Contact Us | Customer Care • Fortucast"
        og_description="Contact us. We are here to help."
        og_image="og-white-logo.png"
        og_image_type="image/png"
        og_image_width="1200"
        og_image_height="630"
        og_image_alt="Fortucast Market Timing"
        twitter_title="Contact Us | Customer Care • Fortucast"
        twitter_description="Contact us. We are here to help."
        twitter_image="og-twitter-image.png"
        twitter_image_alt="Fortucast Market Timing"
        twitter_card="summary"
        twitter_site="@fortucast"
        twitter_creator="@fortucast"
      />
      <div className="contact-container">
        <div className="container">
          <h1 className="text-white-new text-center mt-4 mb-2">Contact Us</h1>
          <h5 className="text-white-new text-center mb-4">We're here to help</h5>
          <div className="img-contact">
            <img src="/images/contact-hero.jpg" />
          </div>
          <ContactBoxes />
        </div>
        <div className="in-touch-wrap">
          <div className="container">
            <div className="touch-wrap">
              <h5 className="text-center">Get In Touch</h5>
              <h6 className="text-center mb-4">We are here ready to help you</h6>
              <form onSubmit={submitForm}>
                <div className="row">
                  <div className="col-6">
                    <input
                      type="text"
                      placeholder="Name"
                      className="newsletter-form-input"
                      onChange={handleChange}
                      name="name"
                      value={values.name}
                    />
                    <p className="form-error mt-1">{errors.name}</p>
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      placeholder="Email"
                      className="newsletter-form-input"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    <p className="form-error mt-1">{errors.email}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <input
                      type="text"
                      placeholder="Phone"
                      className="newsletter-form-input"
                      onChange={handleChange}
                      name="phone"
                      value={values.phone}
                      maxLength={10}
                    />
                    <p className="form-error mt-1">{errors.phone}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <textarea
                      className="w-100"
                      placeholder="Message"
                      onChange={handleChange}
                      value={values.msg}
                      name="msg"
                      rows="5"
                    />
                    <p className="form-error mt-1">{errors.msg}</p>
                  </div>
                </div>
                <button className="btn btn-primary w-100 send-btn" type="submit">
                  Send Message
                </button>
              </form>
              <div className="text-center mt-3 d-flex btm-text-sec">
                <div className="ques ques-black">
                  <img src="/images/question.svg" />
                </div>
                <div className="ques ques-white">
                  <img src="/images/question-white.svg" />
                </div>
                <p>
                  Are you an existing client? <a href="tel:800-788-2796">Call Us</a>
                </p>
              </div>
              <p className="text-center">{successMsg}</p>
              <p className="text-center">{errMsg}</p>
            </div>
            <Loader isOpen={loading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
