import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import React from 'react';
import { Link, navigate } from 'gatsby';
import Auth, { Group } from '../../../components/Auth';
import firebase from 'gatsby-plugin-firebase';
import Loader from '../modal-overlays/loader';

const INITIAL_STATE = {
  email: '',
  password: '',
  error: '',
  isLoading: false,
};

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE, isLoading: false };
  }

  componentDidMount() {
    let _self = this;
    this.setState({ isLoading: true });
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.data().hasOwnProperty('isAdmin') && doc.data().isAdmin === true) {
              _self.setState({ isLoading: false });
              navigate('/admin/dashboard');
            } else {
              _self.setState({ error: 'Only admin users can log in', isLoading: false });
            }
          })
          .catch((e) => {
            _self.setState({ isLoading: false });
          });
      } else {
        _self.setState({ isLoading: false });
      }
    });
  }

  onSubmit = (event) => {
    event.preventDefault();
    let _self = this;
    this.setState({ isLoading: true });
    const { email, password } = this.state;
    firebase
      .firestore()
      .collection('users')
      .where('email', '==', email)
      .get()
      .then((snapShot) => {
        if (snapShot.docs.length > 0) {
          let doc = snapShot.docs[0];
          if (doc.data().hasOwnProperty('isAdmin') && doc.data().isAdmin === true) {
            firebase
              .auth()
              .signInWithEmailAndPassword(email, password)
              .then((res) => {
                _self.setState({ isLoading: false });
                localStorage.setItem('user', 'true');
                navigate('/admin/dashboard');
              })
              .catch((e) => {
                _self.setState({ isLoading: false, error: 'Invalid Credentials' });
              });
          } else {
            _self.setState({ isLoading: false, error: 'Admin Credentials Disabled' });
          }
        } else {
          _self.setState({ isLoading: false, error: 'No Such User' });
        }
      });
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password } = this.state;

    return (
      <Auth title="Login" subTitle="Hello! Login with your email">
        <h6 style={{ color: 'red', textAlign: 'center' }}>{this.state.error}</h6>
        <form onSubmit={this.onSubmit}>
          <InputGroup fullWidth>
            <input type="email" placeholder="Email Address" value={email} onChange={this.onChange} name="email" />
          </InputGroup>
          <InputGroup fullWidth>
            <input type="password" placeholder="Password" value={password} onChange={this.onChange} name="password" />
          </InputGroup>
          <Group>{/*  <Link to="/admin/auth/request-password">Forgot Password?</Link> */}</Group>
          <Button status="Success" type="submit" shape="SemiRound" fullWidth>
            Login
          </Button>
        </form>
        <Loader isOpen={this.state.isLoading} />
      </Auth>
    );
  }
}

export default Login;
