import React, { Component } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { navigate, Link } from 'gatsby';
import Loader from './admin/modal-overlays/loader';
import SEO from '../components/SEO';
import { Helmet } from 'react-helmet';

class UserProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_products: [],
      additional_products: [],
      isLoading: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.checkUser();
    }, 500);
  }

  checkUser = () => {
    this.setState({ isLoading: true });
    let _self = this;
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        _self.setState({ isLoading: false });
        this.getAllProducts(user.uid);
      } else {
        navigate('/login');
        localStorage.removeItem('user');
        _self.setState({ isLoading: false });
      }
    });
  };

  getAllProducts = (uid) => {
    firebase
      .firestore()
      .collection('products')
      .get()
      .then((snapShot) => {
        let tempArr = [];
        snapShot.docs.forEach((doc) => {
          tempArr.push({ id: doc.id, ...doc.data() });
        });
        this.getUserProducts(tempArr, uid);
      });
  };

  getUserProducts = (allProducts, uid) => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('user_subscriptions')
      .where('userId', '==', uid)
      .where('status.value', '==', 'active')
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length > 0) {
          let selected = [];
          snapshot.forEach((doc) => {
            let index = allProducts.findIndex((item) => item.id == doc.data().product_id);
            if (index != -1) {
              selected.push({ ...allProducts[index], purchase_date: doc.data().purchase_date });
              allProducts.splice(index, 1);
            }
          });
          _self.setState({ isLoading: false, selected_products: selected, additional_products: allProducts });
        } else {
          _self.setState({ isLoading: false, additional_products: allProducts });
        }
      })
      .catch((err) => {
        //console.log(err);
        _self.setState({ isLoading: false });
      });
  };

  goToReportList = (productId) => {
    let time = this.state.selected_products.find((item) => item.id == productId).purchase_date.seconds;

    localStorage.setItem('time', time);
    navigate(`/product-reports/${productId}`);
  };

  goToDetails = (productId) => {
    switch (productId) {
      case `${process.env.ETF_FB_ID}`:
        navigate('/market-reports/etf/#plans');
        break;
      case `${process.env.TECH_FB_ID}`:
        navigate('/market-reports/tech-stocks/#plans');
        break;
      case `${process.env.AGS_FB_ID}`:
        navigate('/market-reports/agricultural/#plans');
        break;
      case `${process.env.FIN_FB_ID}`:
        navigate('/market-reports/financial/#plans');
        break;
    }
  };

  render() {
    return (
      <>
        <meta name="description" content="user-products meta description" />
        <meta name="keywords" content="user-products, dashboard" />
        <section className="our-subscription-section footer-fix">
          <div className="container">
            {this.state.selected_products.length ? (
              <>
                <h4 className="text-center">Active Plans</h4>
                <div className="plan-wrap mb-3">
                  {this.state.selected_products.map((product, i) => (
                    <div id="tech-stocks" className="plan-box" key={i}>
                      <img
                        src={
                          product.id == `${process.env.ETF_FB_ID}`
                            ? '/images/etf-icon.png'
                            : product.id == `${process.env.TECH_FB_ID}`
                            ? '/images/tech-icon.png'
                            : product.id == `${process.env.AGS_FB_ID}`
                            ? '/images/ags-icon.png'
                            : '/images/fin-icon.png'
                        }
                        width="100px"
                        alt="product icon"
                      />
                      <h4 className="plan-box-h4">{product.name}</h4>
                      <div className="plan-box-price-p" dangerouslySetInnerHTML={{ __html: product.description }} />
                      <ul className="plan-box-price-ul">
                        {product.selectedIns.map((ins, j) => (
                          <li className="plan-box-price-ul-li" key={j}>
                            <svg className="plan-box-price-ul-li-svg">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-check"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </svg>
                            {ins.name}
                          </li>
                        ))}
                        <br />
                      </ul>
                      <a className="plan-box-price-select" href="javascript:void(0)">
                        <button className="plan-box-price-select-btn" onClick={() => this.goToReportList(product.id)}>
                          Open Report
                        </button>{' '}
                      </a>
                    </div>
                  ))}
                </div>
              </>
            ) : null}

            {this.state.additional_products.length ? (
              <>
                <h4 className="text-center">Additional Plans</h4>
                <div className="plan-wrap">
                  {this.state.additional_products.map((product, i) => (
                    <div id="tech-stocks" className="plan-box" key={i}>
                      <img
                        src={
                          product.id == `${process.env.ETF_FB_ID}`
                            ? '/images/etf-icon.png'
                            : product.id == `${process.env.TECH_FB_ID}`
                            ? '/images/tech-icon.png'
                            : product.id == `${process.env.AGS_FB_ID}`
                            ? '/images/ags-icon.png'
                            : '/images/fin-icon.png'
                        }
                        width="100px"
                        alt="product icon"
                      />
                      <h4 className="plan-box-h4">{product.name}</h4>
                      <div className="plan-box-price-p" dangerouslySetInnerHTML={{ __html: product.description }} />
                      <ul className="plan-box-price-ul">
                        {product.selectedIns.map((ins, j) => (
                          <li className="plan-box-price-ul-li" key={j}>
                            <svg className="plan-box-price-ul-li-svg">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-check"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </svg>
                            {ins.name}
                          </li>
                        ))}
                        <br />
                      </ul>
                      <a className="plan-box-price-select">
                        <button className="plan-box-price-select-btn" onClick={() => this.goToDetails(product.id)}>
                          Select Product
                        </button>{' '}
                      </a>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </div>
          <Loader isOpen={this.state.isLoading} />
        </section>
      </>
    );
  }
}

export default UserProducts;
