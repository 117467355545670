import React, { Component } from 'react';
import { navigate } from 'gatsby';
import Tooltip from '@paljs/ui/Tooltip';
import firebase from 'gatsby-plugin-firebase';
import ConfirmModal from '../modal-overlays/confirm-modal';
import { Toastr } from '@paljs/ui/Toastr';
import { EvaIcon } from '@paljs/ui/Icon';
import { Button } from '@paljs/ui/Button';
import Loader from '../modal-overlays/loader';

class ProductListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listing: [],
      isModalOpen: false,
      deleteId: '',
      lastDoc: null,
      firstDoc: null,
      limit: 10,
      disableNext: false,
      page: 1,
      isLoading: false,
    };
    this.toastrRef = React.createRef();
  }

  componentDidMount() {
    this.getData();
  }

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  getData = () => {
    this.setState({ isLoading: true });
    let _self = this;
    firebase
      .firestore()
      .collection('products')
      .orderBy('createdAt', 'desc')
      .limit(this.state.limit)
      .get()
      .then((snapshot) => {
        let tempArr = [];
        let temp = snapshot.docs[snapshot.docs.length - 1];
        snapshot.forEach((doc) => {
          tempArr.push({ id: doc.id, ...doc.data() });
        });
        _self.setState({
          listing: tempArr,
          lastDoc: temp,
          isLoading: false,
          disableNext: !tempArr.length ? true : false,
        });
      });
  };

  handleDelete = (id) => {
    this.setState({ deleteId: id, isModalOpen: true });
  };

  nextPage = () => {
    this.setState({ isLoading: true });
    let _self = this;
    firebase
      .firestore()
      .collection('products')
      .orderBy('createdAt', 'desc')
      .startAfter(this.state.lastDoc)
      .limit(this.state.limit)
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length == 0) {
          _self.setState({ disableNext: true, isLoading: false });
        } else {
          let tempArr = [];
          let last = snapshot.docs[snapshot.docs.length - 1];
          let first = snapshot.docs[0];
          snapshot.forEach((doc) => {
            tempArr.push({ id: doc.id, ...doc.data() });
          });
          _self.setState({
            listing: tempArr,
            firstDoc: first,
            lastDoc: last,
            page: this.state.page + 1,
            isLoading: false,
          });
        }
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  previousPage = () => {
    this.setState({ isLoading: true });
    let _self = this;
    firebase
      .firestore()
      .collection('products')
      .orderBy('createdAt', 'desc')
      .endBefore(this.state.firstDoc)
      .limitToLast(this.state.limit)
      .get()
      .then((snapshot) => {
        let tempArr = [];
        let last = snapshot.docs[snapshot.docs.length - 1];
        let first = snapshot.docs[0];
        snapshot.forEach((doc) => {
          tempArr.push({ id: doc.id, ...doc.data() });
        });
        _self.setState({
          listing: tempArr,
          firstDoc: first,
          lastDoc: last,
          page: this.state.page - 1,
          disableNext: false,
          isLoading: false,
        });
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  confirmDelete = () => {
    this.setState({ isLoading: true });
    let _self = this;
    firebase
      .firestore()
      .collection('products')
      .doc(this.state.deleteId)
      .delete()
      .then(function () {
        _self.toastrRef.current?.add('Delete Success', 'Success', { status: 'Success' });
        _self.setState({ isModalOpen: false, page: 1, isLoading: false });
        _self.getData();
      })
      .catch(function (error) {
        _self.toastrRef.current?.add('Error Deleting Data', 'Error', { status: 'Danger' });
        _self.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <>
        <Toastr ref={this.toastrRef} />
        <div className="container table-responsive">
          <div className="d-flex justify-content-between mb-3">
            <h4>Products</h4>
            <button className="btn btn-primary" onClick={() => navigate('/admin/products/products')}>
              Add products
            </button>
          </div>
          <table className="table table-light">
            <thead className="thead-dark">
              <tr>
                <th>Plan</th>
                <th>Plan Cost</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.listing.length ? (
                this.state.listing.map((list, i) => (
                  <tr key={i}>
                    <td>{list.name}</td>
                    <td>
                      {list.monthly && <p>${list.monthly} /monthly</p>}
                      {list.quarterly && <p>${list.quarterly} /quarterly</p>}
                      {list.semiannually && <p>${list.semiannually} /semi-anually</p>}
                      {list.annually && <p>${list.annually} /anually</p>}
                    </td>
                    <td>
                      <Tooltip className="with-margin inline-block" trigger="hover" placement="right" content="Edit">
                        <Button appearance="ghost" onClick={() => navigate(`/admin/products/products/${list.id}`)}>
                          <EvaIcon name="edit-outline" />
                        </Button>
                      </Tooltip>

                      <Tooltip className="with-margin inline-block" trigger="hover" placement="right" content="Delete">
                        <Button appearance="ghost" onClick={() => this.handleDelete(list.id)}>
                          <EvaIcon name="trash-outline" />
                        </Button>
                      </Tooltip>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li className={`page-item ${this.state.page == 1 && 'disabled'}`}>
                <div className="page-link cursor-pointer" onClick={this.previousPage}>
                  <EvaIcon name="arrowhead-left-outline" />
                </div>
              </li>
              <li className={`page-item ${this.state.disableNext && 'disabled'}`}>
                <div className="page-link cursor-pointer" onClick={this.nextPage}>
                  <EvaIcon name="arrowhead-right-outline" />
                </div>
              </li>
            </ul>
          </nav>
          <ConfirmModal isOpen={this.state.isModalOpen} close={this.toggleModal} accept={this.confirmDelete} />
        </div>
        <Loader isOpen={this.state.isLoading} />
      </>
    );
  }
}

export default ProductListing;
