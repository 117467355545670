import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

const ThankYou = (props) => {
  useEffect(() => {
    setTimeout(() => {
      localStorage.getItem('user') ? navigate('/dashboard') : navigate('/login');
    }, 2500);
  }, []);

  return (
    <>
      <meta name="description" content="puchase page meta description" />
      <meta name="keywords" content="thank-you, purchase" />
      <div className="thank-you-message">
        <h4>&#9654; Thank You For Purchasing</h4>
      </div>
    </>
  );
};

export default ThankYou;
