import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

const ConfirmModal = (props) => (
  <div>
    {props.isOpen && (
      <SweetAlert
        warning
        showCancel
        cancelBtnBsStyle="info"
        confirmBtnText="Yes"
        confirmBtnBsStyle="danger"
        title="Are you sure?"
        onConfirm={props.accept}
        onCancel={props.close}
      />
    )}
  </div>
);

export default ConfirmModal;
