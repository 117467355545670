import React from 'react';

const HeroSection = () => {
  return (
    <section className="banner-section">
      <div className="banner-section-content">
        <div className="banner-text-wrap">
          <h1 className="banner-text-h1">Daily Market Timing</h1>
          <p className="banner-text-p">
            Fortucast publishes daily and intraday reports on financial futures, Ag markets, ETF sectors and stock
            indices using Gann, Elliott Wave, and five cycle timing models.
          </p>
          <a href="#products" className="tech-stock-text-wrap-a">
            View Plans
            <svg className="tech-stock-text-wrap-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-right"
              >
                <line x1={5} y1={12} x2={19} y2={12} />
                <polyline points="12 5 19 12 12 19" />
              </svg>
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
