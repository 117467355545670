import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import { useLocation } from '@reach/router';

const Header = () => {
  const [showMobNav, setMobNav] = useState(false);
  const [showSubscp, setSubscp] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem('mode') == null || localStorage.getItem('mode') == 'light') {
      localStorage.setItem('mode', 'light');
      document.getElementById('gatsby-focus-wrapper')?.classList.add('light');
    } else {
      localStorage.setItem('mode', 'dark');
      document.getElementById('gatsby-focus-wrapper')?.classList.add('dark');
    }

    if (!document.getElementById('fonts')?.classList.contains('trip-a')) {
      document.getElementById('fonts')?.classList.add('trip-a');
    }
  }, []);

  const toggleNav = () => {
    setMobNav(!showMobNav);
  };

  const toggleSubscrp = () => {
    setSubscp(!showSubscp);
  };

  const handleLatest = () => {
    setMobNav(false);
    if (localStorage.getItem('user') == null) {
      navigate('/market-reports');
    } else {
      if (location.pathname.startsWith('/reports')) {
        let offset = 100; // sticky nav height
        let el = document.getElementById('contact-us'); // element you are scrolling to
        window.scroll({ top: el.offsetTop - offset, left: 0, behavior: 'smooth' });
      } else {
        navigate('/dashboard');
      }
    }
  };

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        navigate('/login');
        localStorage.removeItem('user');
        localStorage.removeItem('time');
        setTimeout(() => {
          localStorage.removeItem('redirectTo');
        }, 500);
      })
      .catch(function (error) {});
  };

  const setMode = () => {
    if (localStorage.getItem('mode') == null || localStorage.getItem('mode') == 'dark') {
      document.getElementById('gatsby-focus-wrapper')?.classList.add('light');
      document.getElementById('gatsby-focus-wrapper')?.classList.remove('dark');
      localStorage.setItem('mode', 'light');
    } else {
      document.getElementById('gatsby-focus-wrapper')?.classList.remove('light');
      document.getElementById('gatsby-focus-wrapper')?.classList.add('dark');
      localStorage.setItem('mode', 'dark');
    }
  };

  const handleRoute = (route) => {
    setMobNav(false);
    setSubscp(false);
    navigate(route);
  };

  const increase = () => {
    /* 	let temp = window.getComputedStyle(element, null).getPropertyValue('font-size');
         let num = (parseFloat(temp) * this.state.factor).toFixed(2)
         element.setAttribute("style", element.getAttribute("style") + ";font-size:" + num + "px");
         for (var i = 0; i < element.children.length; i++) {
            this.changeFont(element.children[i]);
         } */
    document.getElementById('details-wrap').style.fontSize = '120%';
  };

  const decrease = () => {
    document.getElementById('details-wrap').style.fontSize = '70%';
  };

  const initial = () => {
    document.getElementById('details-wrap').style.fontSize = 'initial';
  };

  return (
    <header className="navbar" id="header">
      {showMobNav && (
        <div className="mobile-menu">
          <div className="mobile-nav-pop">
            <ul className="mobile-nav-pop-ul">
              {localStorage.getItem('user') == null && (
                <li className="mobile-nav-pop-ul-li">
                  <a
                    className="mobile-nav-pop-ul-li-a"
                    href="javascript:void(0);"
                    onClick={() => handleRoute('/market-reports/')}
                  >
                    Subscriptions
                  </a>
                  <button className="mobile-plus" onClick={toggleSubscrp}>
                    <svg className="mobile-plus-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-plus"
                      >
                        <line x1={12} y1={5} x2={12} y2={19} />
                        <line x1={5} y1={12} x2={19} y2={12} />
                      </svg>
                    </svg>
                  </button>
                  {showSubscp && (
                    <div className="mobile-inner-menu">
                      <div className="mobile-inner-menu-content">
                        <div className="mobile-inner-menu-content-bottom-3">
                          <a
                            href="javascript:void(0);"
                            onClick={() => handleRoute('/market-reports/financial')}
                            className="mobile-inner-menu-content-a"
                          >
                            <h4 className="mobile-inner-menu-content-a-h4">
                              <img src="/images/fin-icon.png" width="16px" className="mr-2" /> Financial
                              <svg className="css-9xjur3">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-arrow-right"
                                >
                                  <line x1={5} y1={12} x2={19} y2={12} />
                                  <polyline points="12 5 19 12 12 19" />
                                </svg>
                              </svg>
                            </h4>
                            <div className="css-0" />
                            <p className="mobile-inner-menu-content-a-p">Day/Swing Futures Traders</p>
                          </a>
                          <a
                            href="javascript:void(0);"
                            onClick={() => handleRoute('/market-reports/agricultural')}
                            className="mobile-inner-menu-content-a"
                          >
                            <h4 className="mobile-inner-menu-content-a-h4">
                              <img src="/images/ags-icon.png" width="16px" className="mr-2" /> Agricultural
                              <svg className="css-9xjur3">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-arrow-right"
                                >
                                  <line x1={5} y1={12} x2={19} y2={12} />
                                  <polyline points="12 5 19 12 12 19" />
                                </svg>
                              </svg>
                            </h4>
                            <div className="css-0" />
                            <p className="mobile-inner-menu-content-a-p">Farmer Futures</p>
                          </a>
                          <a
                            href="javascript:void(0);"
                            onClick={() => handleRoute('/market-reports/etf')}
                            className="mobile-inner-menu-content-a"
                          >
                            <h4 className="mobile-inner-menu-content-a-h4">
                              <img src="/images/etf-icon.png" width="16px" className="mr-2" /> ETF
                              <svg className="css-9xjur3">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-arrow-right"
                                >
                                  <line x1={5} y1={12} x2={19} y2={12} />
                                  <polyline points="12 5 19 12 12 19" />
                                </svg>
                              </svg>
                            </h4>
                            <div className="css-0" />
                            <p className="mobile-inner-menu-content-a-p">Swing/Long-term ETF Traders</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </li>
              )}
              {localStorage.getItem('user') == null && (
                <li className="mobile-nav-pop-ul-li">
                  <a
                    className="mobile-nav-pop-ul-li-a"
                    onClick={() => handleRoute('/about')}
                    href="javascript:void(0);"
                  >
                    About
                  </a>
                </li>
              )}
              {localStorage.getItem('user') == null && (
                <li className="mobile-nav-pop-ul-li">
                  <a
                    className="mobile-nav-pop-ul-li-a"
                    href="javascript:void(0);"
                    onClick={() => handleRoute('/login')}
                  >
                    Login
                  </a>
                </li>
              )}
              {localStorage.getItem('user') && (
                <li className="mobile-nav-pop-ul-li">
                  <a className="mobile-nav-pop-ul-li-a" onClick={() => handleRoute('/profile')}>
                    Profile
                  </a>
                </li>
              )}
              {localStorage.getItem('user') && (
                <li className="mobile-nav-pop-ul-li">
                  <a className="mobile-nav-pop-ul-li-a" onClick={() => handleRoute('/dashboard')}>
                    Dashboard
                  </a>
                </li>
              )}
              {localStorage.getItem('user') && (
                <li className="mobile-nav-pop-ul-li">
                  <a className="mobile-nav-pop-ul-li-a" href="javascript:void(0);" onClick={logout}>
                    Logout
                  </a>
                </li>
              )}
            </ul>
            <div className={`mobile-theme-wrap`}>
              {location.pathname.startsWith('/reports') ? (
                <div className="trip-a" id="fonts">
                  <h6 onClick={decrease}>A</h6>
                  <h4 className="mx-2" onClick={initial}>
                    A
                  </h4>
                  <h2 onClick={increase}>A</h2>
                </div>
              ) : null}
              <button className="latest-timer" onClick={handleLatest}>
                {location.pathname.startsWith('/reports') ? 'Contact Us' : 'Latest Timer'}
              </button>
              <button id="mode-toggle" className="mobile-theme-wrap-btn" role="button" onClick={setMode}>
                <svg className="mobile-theme-wrap-btn-svg sun-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-sun"
                  >
                    <circle cx={12} cy={12} r={5} />
                    <line x1={12} y1={1} x2={12} y2={3} />
                    <line x1={12} y1={21} x2={12} y2={23} />
                    <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
                    <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
                    <line x1={1} y1={12} x2={3} y2={12} />
                    <line x1={21} y1={12} x2={23} y2={12} />
                    <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
                    <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
                  </svg>
                </svg>
                <svg className="mobile-theme-wrap-btn-svg moon-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    className="feather feather-moon"
                  >
                    <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                  </svg>
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="container">
        <div className="menu">
          <Link to="/" className="mobile-menu-logo-link">
            <img src="/images/logo-l.png" className="logo-light" id="logo-light" />
            <img src="/images/logo-d.png" className="logo-dark" id="logo-dark" />
          </Link>
          <ul className="menu-ul">
            {typeof window !== 'undefined' && !localStorage.getItem('user') ? (
              <li className="menu-ul-li drop-down">
                <Link className="menu-ul-li-a" to="/market-reports/">
                  Subscriptions
                </Link>
                <div className="menu-hover-wrap">
                  <div className="menu-hover-content">
                    <div className="menu-hover-content-box">
                      <Link to="/market-reports/financial" className="menu-hover-content-a">
                        <h4 className="menu-hover-content-h4">
                          <img src="/images/fin-icon.png" width="25px" className="mr-2" /> Financial
                          <svg className="css-9xjur3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-arrow-right"
                            >
                              <line x1={5} y1={12} x2={19} y2={12} />
                              <polyline points="12 5 19 12 12 19" />
                            </svg>
                          </svg>
                        </h4>
                        <div className="css-0" />
                        <p className="menu-hover-content-p">Day/Swing Futures Traders</p>
                      </Link>
                      <Link to="/market-reports/agricultural" className="menu-hover-content-a">
                        <h4 className="menu-hover-content-h4">
                          <img src="/images/ags-icon.png" width="25px" className="mr-2" /> Agricultural
                          <svg className="css-9xjur3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-arrow-right"
                            >
                              <line x1={5} y1={12} x2={19} y2={12} />
                              <polyline points="12 5 19 12 12 19" />
                            </svg>
                          </svg>
                        </h4>
                        <div className="css-0" />
                        <p className="menu-hover-content-p">Farmer Futures</p>
                      </Link>
                      <Link to="/market-reports/etf" className="menu-hover-content-a">
                        <h4 className="menu-hover-content-h4">
                          <img src="/images/etf-icon.png" width="25px" className="mr-2" /> ETF
                          <svg className="css-9xjur3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-arrow-right"
                            >
                              <line x1={5} y1={12} x2={19} y2={12} />
                              <polyline points="12 5 19 12 12 19" />
                            </svg>
                          </svg>
                        </h4>
                        <div className="css-0" />
                        <p className="menu-hover-content-p">Swing/Long-term ETF Traders</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
            ) : null}
            {typeof window !== 'undefined' && !localStorage.getItem('user') ? (
              <li className="menu-ul-li">
                <Link className="menu-ul-li-a" to="/about">
                  About
                </Link>
              </li>
            ) : null}
            {typeof window !== 'undefined' && localStorage.getItem('user') ? (
              <li className="menu-ul-li">
                <Link className="menu-ul-li-a" to="/profile">
                  Profile
                </Link>
              </li>
            ) : null}
            {typeof window !== 'undefined' && localStorage.getItem('user') ? (
              <li className="menu-ul-li">
                <Link className="menu-ul-li-a" to="/dashboard">
                  Dashboard
                </Link>
              </li>
            ) : null}
            {typeof window !== 'undefined' && window.localStorage.getItem('user') ? (
              <li className="menu-ul-li">
                <a className="menu-ul-li-a" href="javascript:void(0)" onClick={logout}>
                  Logout
                </a>
              </li>
            ) : (
              <li className="menu-ul-li">
                <Link className="menu-ul-li-a" to="/login">
                  Login
                </Link>
              </li>
            )}
          </ul>
          {location.pathname.startsWith('/reports') ? (
            <div>
              <div className="trip-a">
                <h6 onClick={decrease}>A</h6>
                <h4 className="mx-2" onClick={initial}>
                  A
                </h4>
                <h2 onClick={increase}>A</h2>
              </div>
            </div>
          ) : null}
          <div className={`${location.pathname.startsWith('/reports') ? 'size-div' : ''}`}>
            <button className="latest-timer" onClick={handleLatest}>
              {location.pathname.startsWith('/reports') ? 'Contact Us' : 'Latest Timer'}
            </button>
          </div>
          <button id="mode-toggle" className="mode-toggle" onClick={setMode}>
            <svg className="icon-svg sun-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-sun"
              >
                <circle cx={12} cy={12} r={5} />
                <line x1={12} y1={1} x2={12} y2={3} />
                <line x1={12} y1={21} x2={12} y2={23} />
                <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
                <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
                <line x1={1} y1={12} x2={3} y2={12} />
                <line x1={21} y1={12} x2={23} y2={12} />
                <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
                <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
              </svg>
            </svg>
            <svg className="icon-svg moon-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-moon"
              >
                <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
              </svg>
            </svg>
          </button>
        </div>
        <div className="mobile-menu">
          <div className="mobile-menu-logo">
            <Link to="/" className="mobile-menu-logo-link">
              <img src="/images/logo-l.png" className="logo-light" />
              <img src="/images/logo-d.png" className="logo-dark" />
            </Link>
            <div className="mobile-menu-right-part">
              <button className="btn-mob-nav" onClick={toggleNav}>
                <svg className="btn-mob-nav-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-menu"
                  >
                    <line x1={3} y1={12} x2={21} y2={12} />
                    <line x1={3} y1={6} x2={21} y2={6} />
                    <line x1={3} y1={18} x2={21} y2={18} />
                  </svg>
                </svg>
              </button>
            </div>
          </div>
          <div className="mobile-nav-pop" style={{ display: 'none' }}>
            <div className="mobile-nav-pop-header">
              <a href="/" className="mobile-nav-pop-header-a">
                Fortucast
              </a>
              <button className="mobile-nav-pop-svg">
                <svg className="mobile-nav-pop-svg-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-x"
                  >
                    <line x1={18} y1={6} x2={6} y2={18} />
                    <line x1={6} y1={6} x2={18} y2={18} />
                  </svg>
                </svg>
              </button>
            </div>
            <ul className="mobile-nav-pop-ul">
              <li className="mobile-nav-pop-ul-li">
                <Link className="mobile-nav-pop-ul-li-a" to="/market-reports/">
                  Subscriptions
                </Link>
                <button className="mobile-plus">
                  <svg className="mobile-plus-svg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-plus"
                    >
                      <line x1={12} y1={5} x2={12} y2={19} />
                      <line x1={5} y1={12} x2={19} y2={12} />
                    </svg>
                  </svg>
                </button>
                <div className="mobile-inner-menu" style={{ display: 'none' }}>
                  <div className="mobile-inner-menu-content">
                    <div className="mobile-inner-menu-content-bottom-3">
                      <Link to="/market-reports/financial" className="mobile-inner-menu-content-a">
                        <h4 className="mobile-inner-menu-content-a-h4">
                          Financial
                          <svg className="css-9xjur3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-arrow-right"
                            >
                              <line x1={5} y1={12} x2={19} y2={12} />
                              <polyline points="12 5 19 12 12 19" />
                            </svg>
                          </svg>
                        </h4>
                        <div className="css-0" />
                        <p className="mobile-inner-menu-content-a-p">Day/Swing Futures Traders</p>
                      </Link>
                      <Link to="/market-reports/agricultural" className="mobile-inner-menu-content-a">
                        <h4 className="mobile-inner-menu-content-a-h4">
                          Agricultural
                          <svg className="css-9xjur3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-arrow-right"
                            >
                              <line x1={5} y1={12} x2={19} y2={12} />
                              <polyline points="12 5 19 12 12 19" />
                            </svg>
                          </svg>
                        </h4>
                        <div className="css-0" />
                        <p className="mobile-inner-menu-content-a-p">Farmer Futures</p>
                      </Link>
                      <Link to="/market-reports/etf" className="mobile-inner-menu-content-a">
                        <h4 className="mobile-inner-menu-content-a-h4">
                          ETF
                          <svg className="css-9xjur3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-arrow-right"
                            >
                              <line x1={5} y1={12} x2={19} y2={12} />
                              <polyline points="12 5 19 12 12 19" />
                            </svg>
                          </svg>
                        </h4>
                        <div className="css-0" />
                        <p className="mobile-inner-menu-content-a-p">Swing/Long-term ETF Traders</p>
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
              <li className="mobile-nav-pop-ul-li">
                <a className="mobile-nav-pop-ul-li-a" href="learn.html">
                  Learn
                </a>
              </li>
              <li className="mobile-nav-pop-ul-li">
                <Link className="mobile-nav-pop-ul-li-a" to="/about">
                  About
                </Link>
              </li>
            </ul>
            <div className={`mobile-theme-wrap`}>
              <button className="latest-timer" onClick={handleLatest}>
                {location.pathname.startsWith('/reports') ? 'Contact Us' : 'Latest Timer'}
              </button>
              <button id="mode-toggle" className="mobile-theme-wrap-btn" role="button">
                <svg className="mobile-theme-wrap-btn-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-sun"
                  >
                    <circle cx={12} cy={12} r={5} />
                    <line x1={12} y1={1} x2={12} y2={3} />
                    <line x1={12} y1={21} x2={12} y2={23} />
                    <line x1="4.22" y1="4.22" x2="5.64" y2="5.64" />
                    <line x1="18.36" y1="18.36" x2="19.78" y2="19.78" />
                    <line x1={1} y1={12} x2={3} y2={12} />
                    <line x1={21} y1={12} x2={23} y2={12} />
                    <line x1="4.22" y1="19.78" x2="5.64" y2="18.36" />
                    <line x1="18.36" y1="5.64" x2="19.78" y2="4.22" />
                  </svg>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
