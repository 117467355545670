import React, { useEffect, useState } from 'react';
import { LayoutFooter } from '@paljs/ui/Layout';
import firebase from 'gatsby-plugin-firebase';
import { navigate } from 'gatsby';
import Loader from '../pages/admin/modal-overlays/loader';

const AdminFooter = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.data().hasOwnProperty('isAdmin') && doc.data().isAdmin === true) {
            } else {
              localStorage.removeItem('user');
              navigate('/admin/auth/logout');
            }
            setLoading(false);
          })
          .catch((e) => {
            setLoading(false);
            //console.log(e);
          });
      } else {
        setLoading(false);
        navigate('/admin/auth/login');
      }
    });
  }, []);

  return (
    <>
      <LayoutFooter>Copyright @{new Date().getFullYear()} Fortucast</LayoutFooter>
      <Loader isOpen={loading} />
    </>
  );
};

export default AdminFooter;
