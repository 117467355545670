import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

const Loader = (props) => (
  <div className="custom-loader">
    {props.isOpen && (
      <SweetAlert
        closeOnClickOutside={false}
        title=""
        onConfirm={() => {}}
        onCancel={() => {}}
        customButtons={<React.Fragment />}
      >
        <div className="spinner-border text-primary"></div>
      </SweetAlert>
    )}
  </div>
);

export default Loader;
