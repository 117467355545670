import React, { Component } from 'react';
import { navigate } from 'gatsby';
import Tooltip from '@paljs/ui/Tooltip';
import firebase from 'gatsby-plugin-firebase';
import ConfirmModal from '../modal-overlays/confirm-modal';
import { Toastr } from '@paljs/ui/Toastr';
import { EvaIcon } from '@paljs/ui/Icon';
import { Button } from '@paljs/ui/Button';
import Loader from '../modal-overlays/loader';
import { changeData } from './dtn-string';

class ReportListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listing: [],
      isModalOpen: false,
      deleteId: '',
      lastDoc: null,
      firstDoc: null,
      limit: 10,
      disableNext: false,
      page: 1,
      docUrl: null,
    };
    this.toastrRef = React.createRef();
  }

  componentDidMount() {
    this.getData();
  }

  toggleModal = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  };

  getData = () => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('reports')
      .orderBy('createdAt', 'desc')
      .limit(this.state.limit)
      .get()
      .then((snapshot) => {
        let tempArr = [];
        let temp = snapshot.docs[snapshot.docs.length - 1];
        snapshot.forEach((doc) => {
          tempArr.push({
            id: doc.id,
            ...doc.data(),
            addedDate: this.formatDate(doc.data().createdAt.seconds),
            reportDate: this.formatDate(doc.data().reportDate.seconds),
          });
        });
        _self.setState({
          listing: tempArr,
          lastDoc: temp,
          disableNext: !tempArr.length ? true : false,
          isLoading: false,
        });
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  handleDelete = (id, docUrl) => {
    this.setState({ deleteId: id, isModalOpen: true, docUrl });
  };

  nextPage = () => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('reports')
      .orderBy('createdAt', 'desc')
      .startAfter(this.state.lastDoc)
      .limit(this.state.limit)
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length == 0) {
          _self.setState({ disableNext: true, isLoading: false });
        } else {
          let tempArr = [];
          let last = snapshot.docs[snapshot.docs.length - 1];
          let first = snapshot.docs[0];
          snapshot.forEach((doc) => {
            tempArr.push({
              id: doc.id,
              ...doc.data(),
              addedDate: this.formatDate(doc.data().createdAt.seconds),
              reportDate: this.formatDate(doc.data().reportDate.seconds),
            });
          });
          _self.setState({
            listing: tempArr,
            firstDoc: first,
            lastDoc: last,
            page: this.state.page + 1,
            isLoading: false,
          });
        }
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  previousPage = () => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('reports')
      .orderBy('createdAt', 'desc')
      .endBefore(this.state.firstDoc)
      .limitToLast(this.state.limit)
      .get()
      .then((snapshot) => {
        let tempArr = [];
        let last = snapshot.docs[snapshot.docs.length - 1];
        let first = snapshot.docs[0];
        snapshot.forEach((doc) => {
          tempArr.push({
            id: doc.id,
            ...doc.data(),
            addedDate: this.formatDate(doc.data().createdAt.seconds),
            reportDate: this.formatDate(doc.data().reportDate.seconds),
          });
        });
        _self.setState({
          listing: tempArr,
          firstDoc: first,
          lastDoc: last,
          page: this.state.page - 1,
          disableNext: false,
          isLoading: false,
        });
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  confirmDelete = () => {
    let _self = this;
    this.setState({ isLoading: true });
    if (this.state.docUrl) {
      let pdfRef = firebase.storage().ref('reports/' + this.state.deleteId);
      pdfRef
        .delete()
        .then(() => {
          this.deleteDoc();
        })
        .catch((e) => {
          _self.setState({ isLoading: false });
          _self.toastrRef.current?.add('Error Deleting Data', 'Error', { status: 'Danger' });
        });
    } else {
      this.deleteDoc();
    }
  };

  deleteDoc = () => {
    let _self = this;

    firebase
      .firestore()
      .collection('reports')
      .doc(this.state.deleteId)
      .delete()
      .then(function () {
        _self.toastrRef.current?.add('Deleted Successfully', 'Success', { status: 'Success' });
        _self.setState({ isModalOpen: false, page: 1, isLoading: false });
        _self.getData();
      })
      .catch(function (error) {
        _self.setState({ isLoading: false });
        _self.toastrRef.current?.add('Error Deleting Data', 'Error', { status: 'Danger' });
      });
  };

  formatDate = (seconds) => {
    let date = new Date(seconds * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  copyReport = (id) => {
    let report = this.state.listing.find((item) => item.id == id);

    let params = {
      prod_name: report.selectedProduct.label,
      ov_title: report.ov_title,
      ov_description: report.ov_description,
      sections: report.sections,
      tabsData: report.tabsData,
      rep_type: report.reportType.label,
      updated: report.updatedAt,
      rep_date: report.reportDate,
      isCopy: true,
      terms: report.product_disclaimer,
    };
    let string = changeData(params);

    this.copyToClipboard(string);
  };

  copyToClipboard = (string) => {
    var dummy = document.createElement('textarea');
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = string;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  };

  render() {
    return (
      <>
        <Toastr ref={this.toastrRef} />
        <div className="container table-responsive">
          <div className="d-flex justify-content-between mb-3">
            <h4>Reports</h4>
            <Button size="tiny" onClick={() => navigate('/admin/report-management/add-report')}>
              Add Report
            </Button>
          </div>
          <table className="table table-light">
            <thead className="thead-dark">
              <tr>
                <th>Product</th>
                <th>Time</th>
                <th>Instruments</th>
                <th>Created At</th>
                <th>Report Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.listing.length ? (
                this.state.listing.map((list, i) => (
                  <tr key={i}>
                    <td>{list.selectedProduct && list.selectedProduct.label}</td>
                    <td>{list.reportType && list.reportType.label}</td>
                    <td>
                      {list.tabsData &&
                        list.tabsData.map((item, i) => (
                          <p key={i}>
                            {item.label} <span className="draft-color">({item.status.label})</span>
                          </p>
                        ))}
                    </td>
                    <td>{list.addedDate}</td>
                    <td>{list.reportDate}</td>
                    <td>{list.reportStatus}</td>
                    <td className="actions">
                      <Tooltip className="with-margin inline-block" trigger="hover" placement="right" content="Edit">
                        <Button
                          appearance="ghost"
                          onClick={() => navigate(`/admin/report-management/add-report/${list.id}`)}
                        >
                          <EvaIcon name="edit-outline" />
                        </Button>
                      </Tooltip>

                      <Tooltip className="with-margin inline-block" trigger="hover" placement="right" content="Delete">
                        <Button appearance="ghost" onClick={() => this.handleDelete(list.id, list.docUrl)}>
                          <EvaIcon name="trash-outline" />
                        </Button>
                      </Tooltip>

                      <Tooltip className="with-margin inline-block" trigger="hover" placement="right" content="Copy">
                        <Button appearance="ghost" onClick={() => this.copyReport(list.id)}>
                          <EvaIcon name="copy-outline" />
                        </Button>
                      </Tooltip>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No Data</td>
                </tr>
              )}
            </tbody>
          </table>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li className={`page-item ${this.state.page == 1 && 'disabled'}`}>
                <div className="page-link cursor-pointer" onClick={this.previousPage}>
                  <EvaIcon name="arrowhead-left-outline" />
                </div>
              </li>
              <li className={`page-item ${this.state.disableNext && 'disabled'}`}>
                <div className="page-link cursor-pointer" onClick={this.nextPage}>
                  <EvaIcon name="arrowhead-right-outline" />
                </div>
              </li>
            </ul>
          </nav>
          <ConfirmModal isOpen={this.state.isModalOpen} close={this.toggleModal} accept={this.confirmDelete} />
          <Loader isOpen={this.state.isLoading} />
        </div>
      </>
    );
  }
}

export default ReportListing;
