import React, { Component } from 'react';
import Select from '@paljs/ui/Select';
import { InputGroup } from '@paljs/ui/Input';
import styled from 'styled-components';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import { Card, CardBody } from '@paljs/ui/Card';
import { Toastr } from '@paljs/ui/Toastr';
import firebase from 'gatsby-plugin-firebase';
import { navigate } from 'gatsby';
import Loader from '../modal-overlays/loader';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const statusOptions = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

class AddSubscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      selectedUser: {},
      products: [],
      selectedProduct: {},
      selectedStatus: {},
      errors: {},
      stripe_subscription_id: '',
      puchase_date: null,
      isLoading: false,
    };
    this.toastrRef = React.createRef();
  }

  componentDidMount() {
    this.getUsers();
    this.getProducts();
  }

  getUsers = () => {
    let _self = this;
    firebase
      .firestore()
      .collection('users')
      .get()
      .then((snapShot) => {
        let tempUsers = [];
        snapShot.forEach((doc) => {
          let name = doc.data().name ? doc.data().name : '';
          tempUsers.push({
            value: doc.id,
            label: name ? `${name} - ${doc.data().email}` : doc.data().email,
            email: doc.data().email,
            sendPdf: doc.data().sendPdf === true || doc.data().sendPdf === false ? doc.data().sendPdf : false,
            secondary_email: doc.data().secondaryEmail ? doc.data().secondaryEmail : '',
          });
        });
        _self.setState({ users: tempUsers });
      })
      .catch((e) => {});
  };

  getProducts = () => {
    let _self = this;
    firebase
      .firestore()
      .collection('products')
      .get()
      .then((snapShot) => {
        let tempProducts = [];
        snapShot.forEach((doc) => {
          let tempObj = { label: doc.data().name, options: [] };
          if (doc.data().monthly) {
            tempObj['options'].push({
              label: `${doc.data().name} - monthly - $${doc.data().monthly}`,
              value: `monthly,${doc.id}`,
              term: 'Monthly',
              product_name: doc.data().name,
            });
          }
          if (doc.data().quarterly) {
            tempObj['options'].push({
              label: `${doc.data().name} - quarterly - $${doc.data().quarterly}`,
              value: `quarterly,${doc.id}`,
              term: 'Quarterly',
              product_name: doc.data().name,
            });
          }
          if (doc.data().semiannually) {
            tempObj['options'].push({
              label: `${doc.data().name} - semiannually - $${doc.data().semiannually}`,
              value: `semiannually,${doc.id}`,
              term: 'Semi-Annually',
              product_name: doc.data().name,
            });
          }
          if (doc.data().annually) {
            tempObj['options'].push({
              label: `${doc.data().name} - annually - $${doc.data().annually}`,
              value: `annually,${doc.id}`,
              term: 'Annually',
              product_name: doc.data().name,
            });
          }
          tempProducts.push(tempObj);
        });
        _self.setState({ products: tempProducts });
      });
  };

  validateFields = () => {
    let formSubmit = true;
    let tempErrors = {};

    if (!this.state.selectedUser.value) {
      tempErrors['user'] = 'This field is required';
      formSubmit = false;
    } else if (!this.state.selectedProduct.value) {
      tempErrors['product'] = 'This field is required';
      formSubmit = false;
    } else if (!this.state.selectedStatus.value) {
      tempErrors['status'] = 'This field is required';
      formSubmit = false;
    }
    this.setState({ errors: tempErrors });
    return formSubmit;
  };

  checkUserSubsc = (uid, prod_id) => {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection('user_subscriptions')
        .where('userId', '==', uid)
        .where('product_id', '==', prod_id)
        .get()
        .then((snapShot) => {
          if (snapShot.docs.length == 0) {
            return resolve(true);
          } else {
            return reject(false);
          }
        });
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    let _self = this;
    let { selectedProduct } = this.state;
    let { selectedUser } = this.state;
    if (this.validateFields()) {
      this.setState({ isLoading: true });
      this.checkUserSubsc(selectedUser.value, selectedProduct.value.split(',')[1])
        .then(() => {
          firebase
            .firestore()
            .collection('user_subscriptions')
            .doc()
            .set({
              product_id: selectedProduct.value.split(',')[1],
              term: selectedProduct.term,
              product_name: selectedProduct.product_name,
              status: this.state.selectedStatus,
              userId: selectedUser.value,
              user_email: selectedUser.email,
              secondary_email: selectedUser.secondary_email,
              sendPdf: selectedUser.sendPdf,
              purchase_date: this.state.purchase_date ? this.state.purchase_date : firebase.firestore.Timestamp.now(),
              stripe_subscription_id: this.state.stripe_subscription_id,
            })
            .then((res) => {
              _self.setState({ isLoading: false });
              navigate('/admin/user-subscriptions/user-subscriptions');
            })
            .catch((e) => {
              _self.setState({ isLoading: false });
              _self.toastrRef.current?.add('Could Not Add Data', 'Error', { status: 'Danger' });
            });
        })
        .catch((e) => {
          _self.setState({ isLoading: false });
          _self.toastrRef.current?.add('Subscription exists', 'Error', { status: 'Danger' });
        });
    }
  };

  handleSelect = (values, field) => {
    this.setState({ [field]: values });
  };

  render() {
    return (
      <Row>
        <Toastr ref={this.toastrRef} />
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <form onSubmit={this.onSubmit}>
              <CardBody>
                <label>Users</label>
                <Select
                  shape="SemiRound"
                  options={this.state.users}
                  placeholder="Select User"
                  onChange={(val) => this.handleSelect(val, 'selectedUser')}
                  value={this.state.selectedUser}
                />
                <p className="form-error">{this.state.errors.user}</p>
                <br />
                <label>Product</label>
                <Select
                  shape="SemiRound"
                  options={this.state.products}
                  placeholder="Select Product"
                  onChange={(val) => this.handleSelect(val, 'selectedProduct')}
                  value={this.state.selectedProduct}
                />
                <p className="form-error">{this.state.errors.product}</p>
                <br />
                <label>Status</label>
                <Select
                  shape="SemiRound"
                  options={statusOptions}
                  placeholder="Select Status"
                  onChange={(val) => this.handleSelect(val, 'selectedStatus')}
                  value={this.state.selectedStatus}
                />
                <p className="form-error">{this.state.errors.status}</p>
                <br />
                <label>Purchase Date</label>
                <br />
                <DatePicker
                  showYearDropdown={true}
                  scrollableYearDropdown={true}
                  yearDropdownItemNumber={5}
                  className="form-control w-100"
                  selected={this.state.purchase_date}
                  onChange={(date) => this.setState({ purchase_date: date })}
                />
                <br />
                <br />
                <label>Stripe Subscription ID</label>
                <Input fullWidth shape="SemiRound">
                  <input
                    type="text"
                    placeholder="Enter Stripe Subscription ID"
                    value={this.state.stripe_subscription_id}
                    onChange={(e) => this.setState({ stripe_subscription_id: e.target.value })}
                  />
                </Input>
                <br />
                <br />
                <Button shape="SemiRound" type="submit" className="float-right">
                  Save
                </Button>
              </CardBody>
            </form>
          </Card>
        </Col>
        <Loader isOpen={this.state.isLoading} />
      </Row>
    );
  }
}

export default AddSubscription;
