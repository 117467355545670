import React from 'react';
import { Link } from 'gatsby';

const Footer = (props) => {
  return (
    <section
      id="custom-footer"
      className={`footer-section ${
        props.location.search('dashboard') >= 0 ||
        props.location.search('product-reports') >= 0 ||
        props.location.search('reports') >= 0
          ? 'small-footer-section'
          : ''
      }`}
    >
      <div className="container">
        {props.location.search('dashboard') >= 0 ||
        props.location.search('product-reports') >= 0 ||
        props.location.search('reports') >= 0 ? null : (
          <>
            <div className="footer-wrapper">
              <div className="footer-wrapper-about">
                <h4 className="plan-box-h4">Fortucast</h4>
                <p className="footer-about-p">
                  Fortucast Market Timing Inc. publishes monthly, daily and intraday reports on financial futures,
                  indices, agricultural markets, ETF sectors &amp; stocks using Gann, Elliott wave and five cyclical
                  timing models.
                  <br />
                  Serving Traders Since <strong className="css-0">1987</strong>.
                </p>
                <a href="mailto:info@fortucast.com" className="footer-about-a">
                  <svg className="footer-about-svg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-mail"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                      <polyline points="22,6 12,13 2,6" />
                    </svg>
                  </svg>
                  info@fortucast.com
                </a>
              </div>
              <ul className="footer-ul">
                <li className="footer-ul-li">
                  <a aria-current="page" className="footer-ul-li-a" href="">
                    Investments
                  </a>
                  <ul className="footer-sub-ul">
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/market-reports/etf">
                        Stocks
                      </Link>
                    </li>
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/market-reports/agricultural">
                        Futures
                      </Link>
                    </li>
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/market-reports/etf">
                        ETFs
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="footer-ul-li">
                  <a aria-current="page" className="footer-ul-li-a" href="/">
                    Markets
                  </a>
                  <ul className="footer-sub-ul">
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/market-reports/etf">
                        Stocks
                      </Link>
                    </li>
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/market-reports/etf">
                        Indices
                      </Link>
                    </li>
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/market-reports/financial">
                        Metals
                      </Link>
                    </li>
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/market-reports/agricultural">
                        Meats
                      </Link>
                    </li>
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/market-reports/agricultural">
                        Grains and Oilseeds
                      </Link>
                    </li>
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/market-reports/financial">
                        Energies
                      </Link>
                    </li>
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/market-reports/financial">
                        Financials
                      </Link>
                    </li>
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/market-reports/etf">
                        Currencies
                      </Link>
                    </li>
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/market-reports/etf">
                        Crypto
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="footer-ul-li">
                  <Link aria-current="page" className="footer-ul-li-a" to="/market-reports/">
                    Subscriptions
                  </Link>
                  <ul className="footer-sub-ul">
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/market-reports/">
                        Trial
                      </Link>
                    </li>
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/market-reports/">
                        Quarterly
                      </Link>
                    </li>
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/market-reports/">
                        Annual
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="footer-ul-li">
                  <a aria-current="page" className="footer-ul-li-a" href="#">
                    Profile
                  </a>
                  <ul className="footer-sub-ul">
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/login">
                        Login
                      </Link>
                    </li>
                    <li className="footer-sub-ul-li">
                      <a className="footer-sub-ul-li-a" href="#">
                        Sign Up
                      </a>
                    </li>
                    <li className="footer-sub-ul-li">
                      <Link className="footer-sub-ul-li-a" to="/contact-us">
                        Contact Us
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </>
        )}
        <div className="footer-copyright-wrap">
          <p className="footer-copyright-wrap-p">
            © {new Date().getFullYear()} Fortucast. All Rights Reserved.{' '}
            <Link className="footer-copyright-wrap-a" to="/privacy">
              Privacy Policy
            </Link>
            .{' '}
            <Link className="footer-copyright-wrap-a" to="/terms">
              {' '}
              Terms of Service
            </Link>
            . All Prices in{' '}
            <svg className="footer-copyright-wrap-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-dollar-sign"
              >
                <line x1={12} y1={1} x2={12} y2={23} />
                <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
              </svg>
            </svg>
            USD
          </p>
          <div className="footer-icon-svg">
            <a
              target="_blank"
              href="https://twitter.com/fortucast"
              className="footer-copyright-wrap-a"
              rel="noreferrer"
            >
              <svg className="icon-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-twitter"
                >
                  <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z" />
                </svg>
              </svg>
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/fortucast/"
              className="footer-copyright-wrap-a"
              rel="noreferrer"
            >
              <svg className="icon-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-facebook"
                >
                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z" />
                </svg>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Footer;
