import React from 'react';

const OurAdvantage = () => (
  <section className="grey-section-wrapper">
    <div className="container">
      <h2 className="grey-section-h2">Our Advantage</h2>
      <p className="grey-section-p">What Makes Fortucast Different</p>
      <div className="avd-wrap">
        <div className="avd-wrap-box">
          <div className="avd-wrap-box-round">
            <svg className="avd-wrap-box-round-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-shuffle"
              >
                <polyline points="16 3 21 3 21 8" />
                <line x1={4} y1={20} x2={21} y2={3} />
                <polyline points="21 16 21 21 16 21" />
                <line x1={15} y1={15} x2={21} y2={21} />
                <line x1={4} y1={4} x2={9} y2={9} />
              </svg>
            </svg>
          </div>
          <h3 className="avd-wrap-box-round-h3">Key Cycle Timing Signals</h3>
          <p className="avd-wrap-box-round-p">
            Gann research and hidden secrets plus in-depth Elliott Wave research provide insights beyond traditional
            models
          </p>
        </div>
        <div className="avd-wrap-box">
          <div className="avd-wrap-box-round">
            <svg className="avd-wrap-box-round-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-activity"
              >
                <polyline points="22 12 18 12 15 21 9 3 6 12 2 12" />
              </svg>
            </svg>
          </div>
          <h3 className="avd-wrap-box-round-h3">5 Types of Cycle Analysis</h3>
          <p className="avd-wrap-box-round-p">
            With 23 years of experience we are not faked by the same traps and false signals that many other analysts
            fall into
          </p>
        </div>
        <div className="avd-wrap-box">
          <div className="avd-wrap-box-round">
            <svg className="avd-wrap-box-round-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-trending-up"
              >
                <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
                <polyline points="17 6 23 6 23 12" />
              </svg>
            </svg>
          </div>
          <h3 className="avd-wrap-box-round-h3">Specific Trade Recommendations</h3>
          <p className="avd-wrap-box-round-p">
            Proprietary timing models help to filter out ambiguous patterns and indicate market strength or weakness
            during intraday periods, as well as trends for a particular market day or week
          </p>
        </div>
        <div className="avd-wrap-box">
          <div className="avd-wrap-box-round">
            <svg className="avd-wrap-box-round-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-target"
              >
                <circle cx={12} cy={12} r={10} />
                <circle cx={12} cy={12} r={6} />
                <circle cx={12} cy={12} r={2} />
              </svg>
            </svg>
          </div>
          <h3 className="avd-wrap-box-round-h3">Key Summaries</h3>
          <p className="avd-wrap-box-round-p">
            Summarizes and highlights most important news developments and their potential impact
          </p>
        </div>
        <div className="avd-wrap-box">
          <div className="avd-wrap-box-round">
            <svg className="avd-wrap-box-round-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-compass"
              >
                <circle cx={12} cy={12} r={10} />
                <polygon points="16.24 7.76 14.12 14.12 7.76 16.24 9.88 9.88 16.24 7.76" />
              </svg>
            </svg>
          </div>
          <h3 className="avd-wrap-box-round-h3">Long Term Recommendations</h3>
          <p className="avd-wrap-box-round-p">Long Term guidance to help grow and protect your portfolio</p>
        </div>
        <div className="avd-wrap-box">
          <div className="avd-wrap-box-round">
            <svg className="avd-wrap-box-round-svg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-sunrise"
              >
                <path d="M17 18a5 5 0 0 0-10 0" />
                <line x1={12} y1={2} x2={12} y2={9} />
                <line x1="4.22" y1="10.22" x2="5.64" y2="11.64" />
                <line x1={1} y1={18} x2={3} y2={18} />
                <line x1={21} y1={18} x2={23} y2={18} />
                <line x1="18.36" y1="11.64" x2="19.78" y2="10.22" />
                <line x1={23} y1={22} x2={1} y2={22} />
                <polyline points="8 6 12 2 16 6" />
              </svg>
            </svg>
          </div>
          <h3 className="avd-wrap-box-round-h3">Pre-opening comments</h3>
          <p className="avd-wrap-box-round-p">Nightly Publishes with Intraday Updates available</p>
        </div>
      </div>
    </div>
  </section>
);

export default OurAdvantage;
