import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loader from './admin/modal-overlays/loader';
import ContactBoxes from './frontend/components/common/contact-boxes';
import firebase from 'gatsby-plugin-firebase';
import { navigate, Link } from 'gatsby';

const Profile = (props) => {
  const [oldData, setOldData] = useState({});
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [errMsg, setErrorMSg] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    setLoading(true);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setLoading(false);
        firebase
          .firestore()
          .collection('users')
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setOldData({ ...doc.data(), uid: user.uid });
              setValues(doc.data());
            }
          });
      } else {
        setLoading(false);
        navigate('/login');
        localStorage.removeItem('user');
      }
    });
  };

  const submitForm = (e) => {
    e.preventDefault();
    if (validateFields()) {
      setLoading(true);
      if (oldData.email !== values.email) {
        let config = {
          headers: { 'Content-Type': 'application/json' },
        };

        let data = { uid: oldData.uid, new_email: values.email, old_email: oldData.email };

        axios
          .post(`${process.env.API_ENDPOINT}/change-email`, data, config)
          .then((res) => {
            saveAllData();
          })
          .catch((e) => {
            setErrorMSg('Could not submit the form');
            setLoading(false);
            if (e.response.status === 401) {
              setErrorMSg('User already exists with this email');
            } else {
              setErrorMSg('Could not update email');
            }
          });
      } else {
        saveAllData();
      }
    }
  };

  const saveAllData = () => {
    firebase
      .firestore()
      .collection('users')
      .doc(oldData.uid)
      .update(values)
      .then(() => {
        setLoading(false);
        setTimeout(() => {
          navigate('/dashboard');
        }, 500);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const validateFields = () => {
    let formSubmit = true;
    let tempErrors = {};

    if (!values.email || !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g.test(values.email)) {
      tempErrors['email'] = 'Invalid Email';
      formSubmit = false;
    } else if (!values.phone) {
      tempErrors['phone'] = 'This field is required';
      formSubmit = false;
    } else if (values.phone && !/^[0-9]{10}$/g.test(values.phone)) {
      tempErrors['phone'] = 'Invalid phone number';
      formSubmit = false;
    } else if (!values.address) {
      tempErrors['address'] = 'This field is required';
      formSubmit = false;
    }

    setErrors(tempErrors);
    return formSubmit;
  };

  return (
    <div className="contact-container">
      <div className="in-touch-wrap">
        <div className="container">
          <h3 className="text-white-new text-center mt-4 mb-4">Profile</h3>
        </div>
        <div className="container">
          <div className="touch-wrap">
            <form onSubmit={submitForm}>
              <div className="row">
                <div className="col-12">
                  <label>Primary Email</label>
                  <input
                    type="text"
                    placeholder="Email"
                    className="newsletter-form-input"
                    name="email"
                    value={values.email}
                    readOnly
                  />
                  <p className="form-error mt-1">{errors.email}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Secondary Email</label>
                  <input
                    type="text"
                    placeholder="Secondary Email"
                    className="newsletter-form-input"
                    name="secondaryEmail"
                    readOnly
                    disabled
                    value={values.secondaryEmail}
                  />
                </div>
              </div>
              <p></p>
              <div className="row">
                <div className="col-12">
                  <label>Phone</label>
                  <input
                    type="text"
                    placeholder="Phone"
                    className="newsletter-form-input"
                    onChange={handleChange}
                    name="phone"
                    value={values.phone}
                    maxLength={10}
                  />
                  <p className="form-error mt-1">{errors.phone}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Address</label>
                  <textarea
                    className="w-100"
                    placeholder="Address"
                    onChange={handleChange}
                    value={values.address}
                    name="address"
                    rows="5"
                  />
                  <p className="form-error mt-1">{errors.address}</p>
                </div>
              </div>
              <button className="btn btn-primary w-100 send-btn" type="submit">
                Save
              </button>
            </form>
            <p className="text-center" style={{ marginTop: '10px' }}>
              {errMsg}
            </p>
          </div>
          <Loader isOpen={loading} />
        </div>
      </div>
    </div>
  );
};

export default Profile;
