import React, { Component } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { navigate } from 'gatsby';
import Loader from './admin/modal-overlays/loader';

class ReportList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reports: [],
      isLoading: false,
      readIds: [],
    };
  }

  componentDidMount() {
    this.checkUser();
  }

  checkUser = () => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.checkSubsc(user.uid);
      } else {
        localStorage.removeItem('user');
        localStorage.setItem('redirectTo', this.props.location.pathname);
        navigate('/login');
      }
    });
  };

  checkSubsc = (uid) => {
    firebase
      .firestore()
      .collection('user_subscriptions')
      .where('userId', '==', uid)
      .where('product_id', '==', this.props.id)
      .where('status.value', '==', 'active')
      .get()
      .then((snapShot) => {
        if (snapShot.docs.length === 0) {
          navigate('/dashboard');
        } else {
          this.getReportList(uid, snapShot.docs[0].data().purchase_date.seconds);
        }
      });
  };

  getReportList = (uid, purchase_date) => {
    //get only those reports whose product matches selected product and whose time >= report date
    let _self = this;
    this.setState({ isLoading: true });
    let time = new Date(parseInt(purchase_date * 1000));
    time.setHours(0);
    time.setMinutes(0);
    time.setSeconds(0);

    firebase
      .firestore()
      .collection('reports')
      .orderBy('reportDate', 'desc')
      .where('selectedProduct.value', '==', this.props.id)
      .where('published', '==', 'yes')
      .where('reportDate', '>=', time)
      .get()
      .then((snapshot) => {
        firebase
          .firestore()
          .collection('user_reports')
          .where('userId', '==', uid)
          .get()
          .then((snapShot) => {
            let readIds = [];
            snapShot.forEach((doc) => {
              readIds.push(doc.data().reportId);
            });
            let tempArr = [];
            for (let i in snapshot.docs) {
              let doc = snapshot.docs[i];
              tempArr.push({
                id: doc.id,
                title: doc.data().ov_title,
                reportDate: _self.formatDate(doc.data().reportDate.seconds),
                reportTime: doc.data().reportType.label,
              });
            }
            _self.setState({ isLoading: false, reports: tempArr, readIds });
          })
          .catch((e) => {
            _self.setState({ isLoading: false });
          });
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  formatDate = (seconds) => {
    let date = new Date(seconds * 1000);
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  };

  viewReport = (id) => {
    navigate(`/reports/${id}`);
  };

  render() {
    return (
      <>
        <div>
          <div style={{ minHeight: 'calc(100vh - 175px)' }}>
            <div className="container mt-1">
              <h3 className="report-list-header">Report List</h3>
              <div className="table-responsive">
                <table className="table table-hover w-100">
                  <thead className="thead-dark">
                    <tr>
                      <th>Overview Title</th>
                      <th>Report Date</th>
                      <th>Report Time</th>
                      <th>Action</th>
                      <th>Read/Unread</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.reports.length ? (
                      this.state.reports.map((report, i) => (
                        <tr key={i}>
                          <td>{report.title}</td>
                          <td>{report.reportDate}</td>
                          <td>{report.reportTime}</td>
                          <td>
                            <button className="btn btn-primary btn-sm" onClick={() => this.viewReport(report.id)}>
                              View Report
                            </button>
                          </td>
                          <td>
                            {this.state.readIds.find((item) => item == report.id) !== undefined ? 'Read' : 'Unread'}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4">
                          <div className="text-center">No Reports</div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <Loader isOpen={this.state.isLoading} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ReportList;
