import React, { useEffect, useState } from 'react';
import NewsLetter from '../frontend/components/newsletter';
import OurAdvantage from '../frontend/components/our-advantage';
import TrackRecord from '../frontend/components/trackRecord';
import Checkout from '../checkout';
import SEO from '../../components/SEO';
import firebase from 'gatsby-plugin-firebase';

const Agricultural = (props) => {
  const [showModal, setModal] = useState(false);
  const [plan, setPlan] = useState({});
  const [coupon, setCoupon] = useState('');

  const confirmPlan = (details) => {
    setPlan({ ...plan, ...details });
    setModal(true);
  };

  useEffect(() => {
    let url = new URL(props.location.href);
    let params = new URLSearchParams(url.search);
    if (params.get('type') === 'email-link') {
      document.getElementById(params.get('plan'))?.click();
      setCoupon(params.get('coupon'));
    }
    getImages();
  }, []);

  const getImages = () => {
    firebase
      .firestore()
      .collection('products')
      .doc(`${process.env.AGS_FB_ID}`)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setPlan(doc.data());
          if (doc.data().selectedIns.length > 0) {
            document.querySelectorAll('.stock-wrap-box').forEach((e) => e.remove());
            doc.data().selectedIns.map((item) => {
              item.ref.get().then((res) => {
                let toInsert = document.createElement('div');
                toInsert.className = 'stock-wrap-box';
                toInsert.innerHTML = `
                        <figure class="stock-wrap-box-fig">
                           <div class=" gatsby-image-wrapper">
                              <div aria-hidden="true" class="stock-box-img-guard" />
                              <img
                                 aria-hidden="true"
                                 src="${res.data().logoUrl}"
                                 alt="${res.data().name}"
                                 class="img-class ${!res.data().logoUrl ? 'invisible' : ''}"
                              />
                           </div>
                        </figure>
                        <div class="stock-box-text">
                           <p class="stock-box-text-title">${res.data().name}</p>
                           <p class="stock-box-text-title-short">${res.data().symbol}</p>
                        </div>
                  `;
                let d = document.getElementById('stock-wrap');
                if (d) {
                  d.appendChild(toInsert);
                }
              });
            });
          }
        }
      });
  };

  return (
    <>
      <SEO
        title="Commodity Trading | Agricultural Commodity Futures Daily Forecast Service • Fortucast"
        description="Daily Agricultural Market Timer is Fortucast's most popular hedging product. Covering futures markets for wheat, corn, soy, feeder and live cattle, and hogs. Get news, weather, and specific trades."
        keywords="commodity trading, commodity forecast service, agricultural forecast, ag research, corn futures, wheat futures, cattle futures, hog futures, elliott wave, barry rosen, commodity research, hedging recommendations, farmer"
        author="Barry Rosen"
        site_name="Fortucast"
        type="website"
        og_url={`${process.env.SITE_URL}/market-reports/agricultural`}
        og_title="Commodity Trading | Agricultural Commodity Futures Daily Forecast Service • Fortucast"
        og_description="Daily Agricultural Market Timer is Fortucast's most popular hedging product. Covering futures markets for wheat, corn, soy, feeder and live cattle, and hogs. Get news, weather, and specific trades."
        og_image="og-ags.png"
        og_image_type="image/png"
        og_image_width="1200"
        og_image_height="630"
        og_image_alt="Daily Agricultural Market Timer"
        twitter_title="Commodity Trading | Agricultural Commodity Futures Daily Forecast Service • Fortucast"
        twitter_description="Daily Agricultural Market Timer is Fortucast's most popular hedging product. Covering futures markets for wheat, corn, soy, feeder and live cattle, and hogs. Get news, weather, and specific trades."
        twitter_image="ags-icon.png"
        twitter_image_alt="Daily Agricultural Market Timer"
        twitter_card="summary"
        twitter_site="@fortucast"
        twitter_creator="@fortucast"
      />
      <div>
        <section className="grey-section-wrapper">
          <div className="container">
            <div className="grey-section-content">
              <figure className="tech-stock-img-wrap">
                <div className="gatsby-image-wrapper">
                  <div aria-hidden="true" className="image-guard-agri" />
                  <img
                    aria-hidden="true"
                    src="/images/agricultural-overview.jpg"
                    alt="agricultural overview"
                    className="img-class"
                  />
                </div>
              </figure>
              <div className="tech-stock-text-wrap">
                <h1 className="tech-stock-text-wrap-h1">{plan.name || ''}</h1>
                <p className="tech-stock-text-wrap-p">
                  Exact entries and exits on 6 Ag markets and a wealth of fundamental and technical information to help
                  you succeed as a trader.
                </p>
                <a href="#plans" className="tech-stock-text-wrap-a">
                  Try it for one month
                  <svg className="tech-stock-text-wrap-svg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-arrow-right"
                    >
                      <line x1={5} y1={12} x2={19} y2={12} />
                      <polyline points="12 5 19 12 12 19" />
                    </svg>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="tech-stocks-timer-daily-wrap">
          <div className="container">
            <div className="tech-stocks-timer-daily-wrap-title">
              <h2 className="tech-stocks-timer-daily-wrap-title-h2">What's Included in the Agricultural Timer Daily</h2>
            </div>
            <div className="box-two-wrap">
              <div className="box-three-wrap-report-box">
                <svg className="box-three-wrap-report-box-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-sunrise"
                  >
                    <path d="M17 18a5 5 0 0 0-10 0" />
                    <line x1={12} y1={2} x2={12} y2={9} />
                    <line x1="4.22" y1="10.22" x2="5.64" y2="11.64" />
                    <line x1={1} y1={18} x2={3} y2={18} />
                    <line x1={21} y1={18} x2={23} y2={18} />
                    <line x1="18.36" y1="11.64" x2="19.78" y2="10.22" />
                    <line x1={23} y1={22} x2={1} y2={22} />
                    <polyline points="8 6 12 2 16 6" />
                  </svg>
                </svg>
                <div className="box-three-wrap-report-box-text">
                  <h2 className="box-three-wrap-report-box-text-h2">Morning Report</h2>
                  <p className="box-three-wrap-report-box-text-p" />
                  <div className="box-three-wrap-report-box-info">
                    {' '}
                    Includes:
                    <p className="box-three-wrap-report-box-info-p">Specific Trading Strategy</p>
                    <p className="box-three-wrap-report-box-info-p">Overnight News Assessments</p>
                    <p className="box-three-wrap-report-box-info-p">Major Calendar Updates</p>
                    <p className="box-three-wrap-report-box-info-p">Pre-opening comments for included markets</p>
                    <p className="box-three-wrap-report-box-info-p">Elliot Wave Cycle Breakdowns</p>
                    <p className="box-three-wrap-report-box-info-p">Big Picture Perspectives and Developments</p>
                    <p className="box-three-wrap-report-box-info-p">Hedging Recommendations</p>
                    <p className="box-three-wrap-report-box-info-p" />
                  </div>
                </div>
              </div>
              <div className="box-three-wrap-report-box">
                <svg className="box-three-wrap-report-box-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-sunset"
                  >
                    <path d="M17 18a5 5 0 0 0-10 0" />
                    <line x1={12} y1={9} x2={12} y2={2} />
                    <line x1="4.22" y1="10.22" x2="5.64" y2="11.64" />
                    <line x1={1} y1={18} x2={3} y2={18} />
                    <line x1={21} y1={18} x2={23} y2={18} />
                    <line x1="18.36" y1="11.64" x2="19.78" y2="10.22" />
                    <line x1={23} y1={22} x2={1} y2={22} />
                    <polyline points="16 5 12 9 8 5" />
                  </svg>
                </svg>
                <div className="box-three-wrap-report-box-text">
                  <h2 className="box-three-wrap-report-box-text-h2">Afternoon Report</h2>
                  <p className="box-three-wrap-report-box-text-p" />
                  <div className="box-three-wrap-report-box-info">
                    {' '}
                    Includes:
                    <p className="box-three-wrap-report-box-info-p">Specific Trade Recommendations</p>
                    <p className="box-three-wrap-report-box-info-p">Daily Comments and Analysis</p>
                    <p className="box-three-wrap-report-box-info-p">Elliot Wave Cycle Breakdowns</p>
                    <p className="box-three-wrap-report-box-info-p">Weekly Chart Outlook</p>
                    <p className="box-three-wrap-report-box-info-p">Big Picture Perspectives and Developments</p>
                    <p className="box-three-wrap-report-box-info-p">Hedging Recommendations</p>
                    <p className="box-three-wrap-report-box-info-p" />
                  </div>
                </div>
              </div>
            </div>
            <div className="tech-stocks-timer-daily-wrap-title">
              <div className="border-top-text">
                <p className="border-top-text-p">
                  Daily volume of reports and updates may increase or decrease to align with market volataility
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="grey-section-wrapper">
          <div className="container">
            <h2 className="grey-section-h2">Futures Markets Covered</h2>
            <p className="grey-section-p" />
            <div className="future-wrap" id="stock-wrap">
              <div className="stock-wrap-box">
                <figure className="stock-wrap-box-fig">
                  <div className=" gatsby-image-wrapper">
                    <div aria-hidden="true" className="stock-box-img-guard" />
                    <img
                      aria-hidden="true"
                      src="/images/fortucast-agricultural-wheat.jpg"
                      alt="agricultural wheat"
                      className="img-class"
                    />
                  </div>
                </figure>
                <div className="stock-box-text">
                  <p className="stock-box-text-title">Wheat</p>
                </div>
              </div>
              <div className="stock-wrap-box">
                <figure className="stock-wrap-box-fig">
                  <div className=" gatsby-image-wrapper">
                    <div aria-hidden="true" className="stock-box-img-guard" />
                    <img
                      aria-hidden="true"
                      src="/images/fortucast-agricultural-corn.jpg"
                      alt="agricultural corn"
                      className="img-class"
                    />
                  </div>
                </figure>
                <div className="stock-box-text">
                  <p className="stock-box-text-title">Corn</p>
                </div>
              </div>
              <div className="stock-wrap-box">
                <figure className="stock-wrap-box-fig">
                  <div className=" gatsby-image-wrapper">
                    <div aria-hidden="true" style={{ width: '100%', paddingBottom: '71%' }} />
                    <img
                      aria-hidden="true"
                      src="/images/fortucast-agricultural-soy.jpg"
                      alt="agricultural soy"
                      className="img-class"
                    />
                  </div>
                </figure>
                <div className="stock-box-text">
                  <p className="stock-box-text-title">Soybean</p>
                </div>
              </div>
              <div className="stock-wrap-box">
                <figure className="stock-wrap-box-fig">
                  <div className=" gatsby-image-wrapper">
                    <div aria-hidden="true" style={{ width: '100%', paddingBottom: '59%' }} />
                    <img
                      aria-hidden="true"
                      src="/images/fortucast-agricultural-feeder-cattle.jpg"
                      alt="feeder cattle"
                      className="img-class"
                    />
                  </div>
                </figure>
                <div className="stock-box-text">
                  <p className="stock-box-text-title">Feeder Cattle</p>
                </div>
              </div>
              <div className="stock-wrap-box">
                <figure className="stock-wrap-box-fig">
                  <div className=" gatsby-image-wrapper">
                    <div aria-hidden="true" className="stock-box-img-guard" />
                    <img
                      aria-hidden="true"
                      src="/images/fortucast-agricultural-lean-hog.jpg"
                      alt="lean hog"
                      className="img-class"
                    />
                  </div>
                </figure>
                <div className="stock-box-text">
                  <p className="stock-box-text-title">Lean Hogs</p>
                </div>
              </div>
              <div className="stock-wrap-box">
                <figure className="stock-wrap-box-fig">
                  <div className=" gatsby-image-wrapper">
                    <div aria-hidden="true" className="stock-box-img-guard" />
                    <img
                      aria-hidden="true"
                      src="/images/fortucast-agricultural-live-cattle.jpg"
                      alt="live cattle"
                      className="img-class"
                    />
                  </div>
                </figure>
                <div className="stock-box-text">
                  <p className="stock-box-text-title">Live Cattle</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="tech-stocks-timer-daily-wrap" id="plans">
          <div className="container">
            <div className="tech-stocks-timer-daily-wrap-title">
              <h2 className="tech-stocks-timer-daily-wrap-title-h2">Subscription Plan</h2>
              <p className="subscription-plan-p">Choose the payment plan that’s right for you</p>
              <div className="line"></div>
              <h2 className="tech1-stocks-timer-daily-wrap-title-h2">Limited Time Only</h2>
              <p className="subscription-plan-p">
                <div className="img-icon2">
                  <img src="/images/award-black.svg" className="award-black" />
                  <img src="/images/award-white.svg" className="award-white" />
                </div>
                Get a {process.env.TRIAL_DAYS}-Day Trial for only ${process.env.TRIAL_PRICE}
              </p>
              <a href="https://bit.ly/fc-ags" target="_blank" className="sample-report-btn">
                Download a Sample Agricultural Futures Report
                <svg className="tech-stock-text-wrap-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-arrow-right"
                  >
                    <line x1={5} y1={12} x2={19} y2={12} />
                    <polyline points="12 5 19 12 12 19" />
                  </svg>
                </svg>
              </a>
            </div>
            <div className="subscription-plan-box-wrap">
              <div className="subscription-plan-box-wrap-left">
                <h3 className="subscription-plan-box-wrap-h3">Annual</h3>
                <p className="border-top-text-p">
                  Access to all daily, weekly market updates. ${plan && plan.annually ? plan.annually : ''} every 12
                  months.
                </p>
                <div className="subscription-plan-box-wrap-price">
                  <p className="subscription-plan-box-wrap-price-large">
                    ${plan && plan.annually ? Math.ceil(plan.annually / 12) : ''}
                  </p>
                  <p className="subscription-plan-box-wrap-per-month">per month</p>
                </div>
                <button
                  id="annually"
                  className="subscription-plan-box-select-plan"
                  onClick={() =>
                    confirmPlan({
                      price_id: plan && plan.annually_stripe_id ? plan.annually_stripe_id : '',
                      price: plan && plan.annually ? plan.annually : '',
                      type: 'Annually',
                      product_id: `${process.env.AGS_FB_ID}`,
                    })
                  }
                >
                  Select Plan
                </button>
              </div>
              <div className="subscription-plan-box-wrap-right">
                <h3 className="subscription-plan-box-wrap-h3">Quarterly</h3>
                <p className="border-top-text-p">
                  Access to all daily, weekly market updates. ${plan && plan.quarterly ? plan.quarterly : ''} every 3
                  months.
                </p>
                <div className="subscription-plan-box-wrap-price">
                  <p className="subscription-plan-box-wrap-price-large">
                    ${plan && plan.quarterly ? Math.ceil(plan.quarterly / 3) : ''}
                  </p>
                  <p className="subscription-plan-box-wrap-per-month">per month</p>
                </div>
                <button
                  id="quarterly"
                  className="subscription-plan-box-select-plan-outline"
                  onClick={() =>
                    confirmPlan({
                      price_id: plan && plan.quarterly_stripe_id ? plan.quarterly_stripe_id : '',
                      price: plan && plan.quarterly ? plan.quarterly : '',
                      type: 'Quarterly',
                      product_id: `${process.env.AGS_FB_ID}`,
                    })
                  }
                >
                  Select Plan
                </button>
              </div>
            </div>
            <p className="plan-info-text">
              <sup>†</sup>Monthly prices shown by taking the plan payment divided by the plan term in months.
            </p>
          </div>
        </section>
        <OurAdvantage />
        <TrackRecord />
        <NewsLetter />
        {showModal && (
          <Checkout
            isOpen={showModal}
            close={() => setModal(false)}
            plan_name={plan.name}
            price={plan.price}
            plan_type={plan.type}
            product_id={plan.product_id}
            price_id={plan.price_id}
            product_img={'ags-icon'}
            stripe_prod_id={plan && plan.stripe_product_id}
            coupon_code={coupon}
            shortName={'AGS'}
          />
        )}
      </div>
    </>
  );
};

export default Agricultural;
