import React, { Component } from 'react';
import Products from './frontend/components/products';
import HeroSection from './frontend/components/hero-mission';
import WhyUs from './frontend/components/why-us';
import BannerSection from './frontend/components/banner-section';
import TrackRecord from './frontend/components/trackRecord';
import NewsLetter from './frontend/components/newsletter';
import SEO from '../components/SEO';

class Home extends Component {
  render() {
    return (
      <div>
        <SEO
          title="Expert Daily Market Forecasts using Gann and Elliot Wave Principles • Fortucast"
          description="Fortucast is the world’s leading independent financial and commodity forecasting firm. We have guided our subscribers for over 33 years."
          keywords="elliott wave, wave principle, barry rosen, gann,  market forecast, financial forecast, elliott wave theorist, ag futures, global market perspective"
          author="Barry Rosen"
          site_name="Fortucast"
          type="website"
          og_url={process.env.SITE_URL}
          og_title="Expert Daily Market Forecasts using Gann and Elliot Wave Principles • Fortucast"
          og_description="Fortucast is the world’s leading independent financial and commodity forecasting firm. We have guided our subscribers for over 33 years."
          og_image="og-white-logo.png"
          og_image_type="image/png"
          og_image_width="1200"
          og_image_height="630"
          og_image_alt="Fortucast Market Timing"
          twitter_title="Expert Daily Market Forecasts using Gann and Elliot Wave Principles • Fortucast"
          twitter_description="Fortucast is the world’s leading independent financial and commodity forecasting firm. We have guided our subscribers for over 33 years."
          twitter_image="og-twitter-image.png"
          twitter_image_alt="Fortucast Market Timing"
          twitter_card="summary"
          twitter_site="@fortucast"
          twitter_creator="@fortucast"
        />
        <HeroSection />
        <WhyUs />
        <BannerSection />
        <Products />
        <TrackRecord />
        <NewsLetter />
      </div>
    );
  }
}

export default Home;
