import { Link } from 'gatsby';
import React from 'react';

const Products = () => (
  <section className="our-subscription-section" id="products">
    <div className="container">
      <div className="banner-text-wrap">
        <h2 className="our-subscription-section-h2">Our Market Reports</h2>
        <p className="our-subscription-section-p">Specific Trading Ideas.</p>
        <p className="our-subscription-section-p">Timed Elliot Waves and hidden Gann secrets.</p>
        <p className="our-subscription-section-p">Savvy news and fundamental analysis.</p>
      </div>
      <div className="plan-wrap">
        <div id="financial" className="plan-box">
          <img src="/images/fin-icon.png" width="100px" />
          <h4 className="plan-box-h4 mt-2">Financial Timer</h4>
          <p className="plan-box-price-p">For active day and swing Futures traders</p>
          <ul className="plan-box-price-ul">
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              S&amp;P 500 (E-Mini)
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              NASDAQ
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              10-Year T-Note
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Gold
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Silver
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Crude Oil Mini
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Euro FX and E-Mini
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              USD Index
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Bitcon CME
            </li>
            <br />
            <li className="css-t7zqhl">
              <svg className="css-12mcvjl">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-circle"
                >
                  <circle cx={12} cy={12} r={10} />
                </svg>
              </svg>
              Intraday Updates Available
            </li>
          </ul>
          <Link className="plan-box-price-select" to="/market-reports/financial">
            <button className="plan-box-price-select-btn">Learn More</button>{' '}
          </Link>
        </div>
        <div id="agricultural" className="plan-box">
          <img src="/images/ags-icon.png" width="100px" />
          <h4 className="plan-box-h4 mt-2">Agricultural Timer</h4>
          <p className="plan-box-price-p">For propserous farmers</p>
          <ul className="plan-box-price-ul">
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Corn
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Wheat
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Soybeans
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Live Cattle
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Feeder Cattle
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Lean Hogs
            </li>
            <br />
          </ul>
          <Link className="plan-box-price-select" to="/market-reports/agricultural">
            <button className="plan-box-price-select-btn">Learn More</button>{' '}
          </Link>
        </div>
        <div id="etf" className="plan-box">
          <img src="/images/etf-icon.png" width="100px" />
          <h4 className="plan-box-h4 mt-2">ETF Timer</h4>
          <p className="plan-box-price-p">For long-term, position, and swing traders</p>
          <ul className="plan-box-price-ul">
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Short and Long Positions
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Equities
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Treasuries
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Metals
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Currencies
            </li>
            <li className="plan-box-price-ul-li">
              <svg className="plan-box-price-ul-li-svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12" />
                </svg>
              </svg>
              Energies
            </li>
            <br />
          </ul>
          <Link className="plan-box-price-select" to="/market-reports/etf">
            <button className="plan-box-price-select-btn">Learn More</button>{' '}
          </Link>
        </div>
      </div>
      <p className="plan-info-text">
        <sup>†</sup>Monthly prices shown when on the annual payment plan.
      </p>
    </div>
  </section>
);

export default Products;
