import React from 'react';
import { Router } from '@reach/router';
import Instruments from './instruments/instruments';
import InstrumentListing from './instruments/instruments-listing';
import AddReport from './report-management/add-report';
import ReportListing from './report-management/report-listing';
import Products from './products/products';
import ProductListing from './products/products-listing';
import UserListing from './user-management/user-listing';
import UserForm from './user-management/user-form';
import Login from './auth/login';
import Logout from './auth/logout';
import Profile from './users/my_profile';
import Dashboard from './dashboard';
import UserSubscriptions from './user-subscriptions/user-subscriptions';
import AddSubscription from './user-subscriptions/add-subscription';
import RequestPassword from './auth/request-password';

const AdminApp = () => {
  return (
    <>
      <Router>
        <Login path="/admin" />
        <Dashboard path="/admin/dashboard" />
        <Instruments path="/admin/instruments/instruments" />
        <Instruments path="/admin/instruments/instruments/:id" />
        <InstrumentListing path="/admin/instruments/instruments-listing" />
        <AddReport path="/admin/report-management/add-report" />
        <AddReport path="/admin/report-management/add-report/:id" />
        <ReportListing path="/admin/report-management/report-listing" />
        <Products path="/admin/products/products" />
        <Products path="/admin/products/products/:id" />
        <ProductListing path="/admin/products/products-listing" />
        <UserListing path="/admin/user-management/user-listing" />
        <UserForm path="/admin/user-management/user-form" />
        <UserForm path="/admin/user-management/user-form/:id" />
        <Profile path="/admin/users/my_profile" />
        <UserSubscriptions path="/admin/user-subscriptions/user-subscriptions" />
        <AddSubscription path="/admin/user-subscriptions/add-subscription" />
        <Login path="/admin/auth/login" />
        <Logout path="/admin/auth/logout" />
        <RequestPassword path="/admin/auth/request-password" />
      </Router>
    </>
  );
};

export default AdminApp;
