import Select from '@paljs/ui/Select';
import { Card, CardBody } from '@paljs/ui/Card';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { Component } from 'react';
const isSSR = typeof window === 'undefined';
const CKEditor = !isSSR ? require('@ckeditor/ckeditor5-react') : <div />;
const ClassicEditor = !isSSR ? require('@ckeditor/ckeditor5-build-classic') : <div />;
import styled, { css } from 'styled-components';
import firebase from 'gatsby-plugin-firebase';
import { Toastr } from '@paljs/ui/Toastr';
import Loader from '../modal-overlays/loader';
import { navigate } from 'gatsby';

interface BoxProps {
  nested?: boolean;
  container?: boolean;
  row?: boolean;
  large?: boolean;
}

const Box = styled.div<BoxProps>`
  ${({ theme, nested, container, row, large }) => css`
    position: relative;
    box-sizing: border-box;
    min-height: 1rem;
    overflow: hidden;
    text-align: center;
    background: #cddaf3;
    padding: 0.75rem 0.25rem;
    border-radius: 0.25rem;
    ${large && 'height: 8rem;'};
    ${row && 'margin-bottom: 1rem  !important;'};
    ${container && 'padding: .5em;'};
    ${nested && `background-color: #cddaf3;`};
  `}
`;
const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;
const initial_state = {
  name: '',
  description: '',
  monthly: '',
  quarterly: '',
  semiannually: '',
  annually: '',
  selectedIns: [],
  selectedReports: [],
  isLoading: false,
  monthly_stripe_id: '',
  quarterly_stripe_id: '',
  semi_annually_stripe_id: '',
  annually_stripe_id: '',
  stripe_product_id: '',
  product_disclaimer: '',
  product_color: '',
  dtn_list: [],
};

const reportOptions = [
  { value: 'morning', label: 'Morning Report' },
  { value: 'afternoon', label: 'Afternoon Report' },
  { value: 'intraday', label: 'Intraday Report' },
];

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { ...initial_state },
      errors: {},
      invalidId: false,
      instrumentsOptions: [],
      isLoading: false,
    };
    this.toastrRef = React.createRef();
  }

  componentDidMount() {
    this.getData();
    if (this.props.id) {
      this.getProdctData();
    }
  }

  instrumentSelectCallback = (instrumentsArr, docData) => {
    let tempObj = { ...docData, selectedIns: instrumentsArr, dtn_list: docData.dtn_list ? docData.dtn_list : [] };
    this.setState({ data: tempObj, errors: {}, isLoading: false });
  };

  getProdctData = () => {
    this.setState({ isLoading: true });
    let _self = this;
    firebase
      .firestore()
      .collection('products')
      .doc(this.props.id)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          let temp = [];

          var itemsProcessed = 0;
          let tempData = { ...doc.data() };

          doc.data().selectedIns.forEach((item, i, array) => {
            doc
              .data()
              .selectedIns[i].ref.get()
              .then((ins) => {
                temp.push({
                  value: ins.id,
                  label: ins.data().name,
                  ref: firebase.firestore().doc('/instruments/' + ins.id),
                  dtn_code: ins.data().dtn ? ins.data().dtn.dtn_code : '',
                  order:
                    doc.data().selectedIns[i].order == 0
                      ? 0
                      : doc.data().selectedIns[i].order
                      ? doc.data().selectedIns[i].order
                      : '',
                });
                itemsProcessed++;
                if (itemsProcessed === doc.data().selectedIns.length) {
                  _self.instrumentSelectCallback(temp, tempData);
                }
              });
          });
        } else {
          _self.setState({ invalidId: true, isLoading: false });
        }
      });
  };

  getData = () => {
    let _self = this;
    firebase
      .firestore()
      .collection('instruments')
      .orderBy('name')
      .get()
      .then((InstrumentsList) => {
        let tempArr = [];
        InstrumentsList.forEach((doc) => {
          tempArr.push({
            value: doc.id,
            label: doc.data().name,
            ref: firebase.firestore().doc('/instruments/' + doc.id),
            order: '',
            dtn_code: doc.data().dtn ? doc.data().dtn.dtn_code : '',
          });
        });
        _self.setState({ instrumentsOptions: tempArr });
      });
  };

  validateFields = () => {
    let formSubmit = true;
    let tempErrors = {};

    if (!this.state.data.name) {
      tempErrors['name'] = 'This field is required';
      formSubmit = false;
    } else if (!this.state.data.description) {
      tempErrors['description'] = 'This field is required';
      formSubmit = false;
    } else if (
      !this.state.data.monthly &&
      !this.state.data.quarterly &&
      !this.state.data.semiannually &&
      !this.state.data.annually
    ) {
      tempErrors['plans'] = 'This field is required';
      formSubmit = false;
    } else if (this.state.data.monthly && !this.state.data.monthly_stripe_id) {
      tempErrors['monthly_stripe_id'] = 'Stripe Price Id is required';
      formSubmit = false;
    } else if (this.state.data.quarterly && !this.state.data.quarterly_stripe_id) {
      tempErrors['quarterly_stripe_id'] = 'Stripe Price Id is required';
      formSubmit = false;
    } else if (this.state.data.semiannually && !this.state.data.semi_annually_stripe_id) {
      tempErrors['semi_annually_stripe_id'] = 'Stripe Price Id is required';
      formSubmit = false;
    } else if (this.state.data.annually && !this.state.data.annually_stripe_id) {
      tempErrors['annually_stripe_id'] = 'Stripe Price Id is required';
      formSubmit = false;
    } else if (!this.state.data.stripe_product_id) {
      tempErrors['stripe_product_id'] = 'This field is required';
      formSubmit = false;
    } else if (!this.state.data.selectedIns.length) {
      tempErrors['instruments'] = 'At least one value is required';
      formSubmit = false;
    } else if (!this.state.data.selectedReports.length) {
      tempErrors['reports'] = 'At least one value is required';
      formSubmit = false;
    }

    this.setState({ errors: tempErrors });
    return formSubmit;
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.validateFields() && this.validateTable() && this.validateDtn()) {
      if (this.props.id) {
        this.updateDetails();
      } else {
        this.addData();
      }
    }
  };

  updateDetails = () => {
    let _self = this;
    this.setState({ isLoading: true });

    let params = { ...this.state.data };

    let temp = [];
    for (let i = 0; i < params['selectedIns'].length; i++) {
      temp.push({
        name: params['selectedIns'][i].label,
        ref: params['selectedIns'][i].ref,
        order: params['selectedIns'][i].order,
      });
    }
    temp.sort((a, b) => {
      return a.order - b.order;
    });
    params['selectedIns'] = temp;

    firebase
      .firestore()
      .collection('products')
      .doc(this.props.id)
      .update(params)
      .then(() => {
        _self.setState({ isLoading: false });
        navigate('/admin/products/products-listing');
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
        _self.toastrRef.current?.add('Could Not Add Data', 'Error', { status: 'Danger' });
      });
  };

  addData = () => {
    let _self = this;
    this.setState({ isLoading: true });

    let params = {
      ...this.state.data,
      createdAt: firebase.firestore.Timestamp.now(),
    };

    let temp = [];
    for (let i = 0; i < params['selectedIns'].length; i++) {
      temp.push({
        name: params['selectedIns'][i].label,
        ref: params['selectedIns'][i].ref,
        order: params['selectedIns'][i].order,
      });
    }
    temp.sort((a, b) => {
      return a.order - b.order;
    });
    params['selectedIns'] = temp;

    firebase
      .firestore()
      .collection('products')
      .doc()
      .set(params)
      .then(() => {
        _self.setState({ isLoading: false });
        navigate('/admin/products/products-listing');
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
        _self.toastrRef.current?.add('Could Not Add Data', 'Error', { status: 'Danger' });
      });
  };

  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSelect = (values, field) => {
    this.setState({ data: { ...this.state.data, [field]: values } });
  };

  handleInstruments = (arr, action) => {
    if (action.action === 'select-option') {
      this.setState({ data: { ...this.state.data, selectedIns: arr } });
    } else if (action.action === 'remove-value') {
      this.setState({ data: { ...this.state.data, selectedIns: arr } });
    }
  };

  changeOrderInput = (index, num) => {
    let tempArr = [...this.state.data.selectedIns];
    tempArr[index]['order'] = num ? parseInt(num) : '';
    this.setState({ data: { ...this.state.data, selectedIns: tempArr } });
  };

  handleDtnChange = (key, value, index) => {
    let tempArr = [...this.state.data.dtn_list];
    tempArr[index][key] = value;
    this.setState({ data: { ...this.state.data, dtn_list: tempArr } });
  };

  handleAddDtn = () => {
    let tempArr = [...this.state.data.dtn_list];
    tempArr.push({ dtn_code: '', dtn_name: '' });
    this.setState({ data: { ...this.state.data, dtn_list: tempArr } });
  };

  handleRemoveDtn = (index) => {
    let tempArr = [...this.state.data.dtn_list];
    tempArr.splice(index, 1);
    this.setState({ data: { ...this.state.data, dtn_list: tempArr } });
  };

  validateTable = () => {
    let _self = this;
    let formSubmit = true;

    let tempArr = [...this.state.data.selectedIns];
    let orders = tempArr.map((item) => item.order);

    if (orders.find((item) => item === '') !== undefined) {
      formSubmit = false;
      _self.toastrRef.current?.add('Order cannot be blank', 'Error', { status: 'Danger' });
    } else if (new Set(orders).size !== orders.length) {
      formSubmit = false;
      _self.toastrRef.current?.add('Duplicate order found', 'Error', { status: 'Danger' });
    } else if (orders.find((item) => item >= orders.length)) {
      formSubmit = false;
      _self.toastrRef.current?.add('Order number cannot be greater than selected items', 'Error', { status: 'Danger' });
    } else if (orders.find((item) => item < 0)) {
      formSubmit = false;
      _self.toastrRef.current?.add('Order number cannot be less than 0', 'Error', { status: 'Danger' });
    }

    return formSubmit;
  };

  validateDtn = () => {
    let formSubmit = true;
    let _self = this;
    let tempArr = [...this.state.data.dtn_list];
    if (tempArr.length == 0) {
      formSubmit = false;
      _self.toastrRef.current?.add('Please add DTN details', 'Error', { status: 'Danger' });
    } else if (tempArr.filter((item) => item.dtn_code === '').length > 0) {
      formSubmit = false;
      _self.toastrRef.current?.add('DTN code cannot be blank', 'Error', { status: 'Danger' });
    } else if (tempArr.filter((item) => item.dtn_name === '').length > 0) {
      formSubmit = false;
      _self.toastrRef.current?.add('DTN name cannot be blank', 'Error', { status: 'Danger' });
    }

    return formSubmit;
  };

  render() {
    return (
      <>
        <Toastr ref={this.toastrRef} />
        <Row>
          <Col breakPoint={{ xs: 12, md: 12 }}>
            <Card>
              <header>Add Products</header>
              <form onSubmit={this.onSubmit}>
                <CardBody>
                  <label>Product Name</label>
                  <Input fullWidth shape="SemiRound">
                    <input
                      type="text"
                      placeholder="Enter Products Name"
                      name="name"
                      value={this.state.data.name}
                      onChange={this.onChange}
                    />
                  </Input>
                  <p className="form-error">{this.state.errors.name}</p>
                  <br />
                  <label>Product Description</label>
                  {!isSSR && (
                    <CKEditor
                      editor={ClassicEditor}
                      data={this.state.data.description}
                      onChange={(event: any, editor: { getData: () => any }) => {
                        const data = editor.getData();
                        this.setState({
                          data: {
                            ...this.state.data,
                            description: data,
                          },
                        });
                      }}
                    />
                  )}
                  <p className="form-error">{this.state.errors.description}</p>
                  <br />
                  {/*  <label>Product Disclaimer</label>
                  <Input fullWidth>
                    <textarea
                      placeholder="Product Disclaimer"
                      rows={4}
                      onChange={this.onChange}
                      name="product_disclaimer"
                      value={this.state.data.product_disclaimer}
                    />
                  </Input> */}
                  <label>Product Disclaimer</label>
                  {!isSSR && (
                    <CKEditor
                      editor={ClassicEditor}
                      data={this.state.data.product_disclaimer}
                      onChange={(event: any, editor: { getData: () => any }) => {
                        const data = editor.getData();
                        this.setState({
                          data: {
                            ...this.state.data,
                            product_disclaimer: data,
                          },
                        });
                      }}
                    />
                  )}
                  <br />
                  <label>Color (eg: #fff)</label>
                  <Input fullWidth shape="SemiRound">
                    <input
                      type="text"
                      placeholder="Enter Color (#fff)"
                      name="product_color"
                      value={this.state.data.product_color}
                      onChange={this.onChange}
                    />
                  </Input>
                  <br />
                  <Row>
                    <Col breakPoint={{ xs: true }}>
                      <Box row>
                        <h5>Monthly</h5>
                        <Input fullWidth shape="SemiRound">
                          <input
                            type="number"
                            placeholder="Enter Price"
                            name="monthly"
                            value={this.state.data.monthly}
                            onChange={this.onChange}
                          />
                        </Input>
                        <h5>Stripe ID</h5>
                        <Input fullWidth shape="SemiRound">
                          <input
                            type="text"
                            placeholder="Enter Id"
                            name="monthly_stripe_id"
                            value={this.state.data.monthly_stripe_id}
                            onChange={this.onChange}
                          />
                        </Input>
                      </Box>
                      <p className="form-error">{this.state.errors.monthly_stripe_id}</p>
                    </Col>
                    <Col breakPoint={{ xs: true }}>
                      <Box row>
                        <h5>Quarterly</h5>
                        <Input fullWidth shape="SemiRound">
                          <input
                            type="number"
                            placeholder="Enter Price"
                            name="quarterly"
                            value={this.state.data.quarterly}
                            onChange={this.onChange}
                          />
                        </Input>
                        <h5>Stripe ID</h5>
                        <Input fullWidth shape="SemiRound">
                          <input
                            type="text"
                            placeholder="Enter Id"
                            name="quarterly_stripe_id"
                            value={this.state.data.quarterly_stripe_id}
                            onChange={this.onChange}
                          />
                        </Input>
                      </Box>
                      <p className="form-error">{this.state.errors.quarterly_stripe_id}</p>
                    </Col>
                    <Col breakPoint={{ xs: true }}>
                      <Box row>
                        <h5>Semi Annually</h5>
                        <Input fullWidth shape="SemiRound">
                          <input
                            type="number"
                            placeholder="Enter Price"
                            name="semiannually"
                            value={this.state.data.semiannually}
                            onChange={this.onChange}
                          />
                        </Input>
                        <h5>Stripe ID</h5>
                        <Input fullWidth shape="SemiRound">
                          <input
                            type="text"
                            placeholder="Enter Id"
                            name="semi_annually_stripe_id"
                            value={this.state.data.semi_annually_stripe_id}
                            onChange={this.onChange}
                          />
                        </Input>
                      </Box>
                      <p className="form-error">{this.state.errors.semi_annually_stripe_id}</p>
                    </Col>
                    <Col breakPoint={{ xs: true }}>
                      <Box row>
                        <h5>Annually</h5>
                        <Input fullWidth shape="SemiRound">
                          <input
                            type="number"
                            placeholder="Enter Price"
                            name="annually"
                            value={this.state.data.annually}
                            onChange={this.onChange}
                          />
                        </Input>
                        <h5>Stripe ID</h5>
                        <Input fullWidth shape="SemiRound">
                          <input
                            type="text"
                            placeholder="Enter Id"
                            name="annually_stripe_id"
                            value={this.state.data.annually_stripe_id}
                            onChange={this.onChange}
                          />
                        </Input>
                      </Box>
                      <p className="form-error">{this.state.errors.annually_stripe_id}</p>
                    </Col>
                  </Row>
                  <p className="form-error">{this.state.errors.plans}</p>
                  <br />
                  <label>Stripe Product ID</label>
                  <Input fullWidth shape="SemiRound">
                    <input
                      type="text"
                      placeholder="Enter Stripe Product ID"
                      name="stripe_product_id"
                      value={this.state.data.stripe_product_id}
                      onChange={this.onChange}
                    />
                  </Input>
                  <p className="form-error">{this.state.errors.stripe_product_id}</p>
                  <br />
                  <label>Instruments</label>
                  <Select
                    shape="SemiRound"
                    options={this.state.instrumentsOptions}
                    isMulti
                    multiple
                    placeholder="Select Instruments"
                    name="instrumentOption"
                    onChange={this.handleInstruments}
                    value={this.state.data.selectedIns}
                  />
                  <p className="form-error">{this.state.errors.instruments}</p>
                  <br />

                  {this.state.data.selectedIns.length ? (
                    <div className="ins-table">
                      <table className="table table-bordered">
                        <thead className="thead-dark">
                          <tr>
                            <th>SL No.</th>
                            <th>Name</th>
                            <th>Order</th>
                            <th>DTN Code</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.data.selectedIns.length
                            ? this.state.data.selectedIns.map((row, i) => (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{row.label}</td>
                                  <td>
                                    <input
                                      type="number"
                                      value={row.order}
                                      style={{ border: '1px solid black' }}
                                      onChange={(e) => this.changeOrderInput(i, e.target.value)}
                                    />
                                  </td>
                                  <td>{row.dtn_code}</td>
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </table>
                    </div>
                  ) : null}

                  <br />
                  <label>Report Type</label>
                  <Select
                    shape="SemiRound"
                    name="reportType"
                    options={reportOptions}
                    isMulti
                    multiple
                    placeholder="Select Report Type"
                    onChange={(arr) => this.handleSelect(arr, 'selectedReports')}
                    value={this.state.data.selectedReports}
                  />
                  <p className="form-error">{this.state.errors.reports}</p>
                  <br />

                  <label>DTN Codes</label>
                  <div>
                    {this.state.data.dtn_list.length ? (
                      <div className="ins-table">
                        <table className="table table-bordered">
                          <thead className="thead-dark">
                            <tr>
                              <th>DTN Code</th>
                              <th>DTN Name</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.dtn_list.length
                              ? this.state.data.dtn_list.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <input
                                        type="text"
                                        onChange={(e) => this.handleDtnChange('dtn_code', e.target.value, index)}
                                        value={item.dtn_code}
                                        style={{ border: '1px solid black' }}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        onChange={(e) => this.handleDtnChange('dtn_name', e.target.value, index)}
                                        value={item.dtn_name}
                                        style={{ border: '1px solid black' }}
                                      />
                                    </td>
                                    <td>
                                      <Button
                                        onClick={() => this.handleRemoveDtn(index)}
                                        type="button"
                                        status="Danger"
                                        size="Tiny"
                                      >
                                        Remove
                                      </Button>
                                    </td>
                                  </tr>
                                ))
                              : null}
                          </tbody>
                        </table>
                      </div>
                    ) : null}
                    <div className="text-right">
                      <Button shape="SemiRound" onClick={this.handleAddDtn} type="button" status="Primary" size="Small">
                        Add DTN
                      </Button>
                    </div>
                  </div>

                  <Button shape="SemiRound" type="submit" disabled={this.state.invalidId}>
                    Save Details
                  </Button>
                  {this.state.invalidId && <p className="form-error">Could not fetch data</p>}
                </CardBody>
              </form>
            </Card>
          </Col>
        </Row>
        <Loader isOpen={this.state.isLoading} />
      </>
    );
  }
}

export default Products;
