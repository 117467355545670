import React, { useState, useRef } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import themes from './themes';
import { Layout, LayoutContent, LayoutContainer, LayoutColumns, LayoutColumn } from '@paljs/ui/Layout';
import icons from '@paljs/icons';
import { SidebarRefObject } from '@paljs/ui/Sidebar';
import Header from './Header';
import SimpleLayout from './SimpleLayout';
import SidebarCustom from './Sidebar';
import FrontApp from '../pages/frontend/frontApp';
import AdminApp from '../pages/admin/adminApp';
import AdminFooter from './adminFooter';

const LayoutPage: React.FC<{ pageContext: { layout: string } }> = ({ children, pageContext, location }) => {
  const [theme] = useState<DefaultTheme['name']>('corporate');
  const [dir] = useState<'ltr'>('ltr');
  const sidebarRef = useRef<SidebarRefObject>(null);

  if (pageContext.layout == 'frontend') {
    return (
      <>
        <FrontApp location={location.pathname} />
      </>
    );
  } else {
    return (
      <ThemeProvider theme={themes(theme, dir)}>
        <div className="main-panel">
          <SimpleLayout />
          <Layout evaIcons={icons} dir={dir} className={pageContext.layout === 'auth' ? 'auth-layout' : ''}>
            {(pageContext.layout == 'auth-inner' ||
              (location &&
                location.pathname.search('/admin') >= 0 &&
                location.pathname.search('/admin/auth') == -1)) && (
              <Header dir={dir} toggleSidebar={() => sidebarRef.current?.toggle()} />
            )}
            <LayoutContainer>
              {(pageContext.layout == 'auth-inner' ||
                (location &&
                  location.pathname.search('/admin') >= 0 &&
                  location.pathname.search('/admin/auth') == -1)) && <SidebarCustom ref={sidebarRef} />}
              <LayoutContent>
                <LayoutColumns>
                  <LayoutColumn className="main-content">
                    <AdminApp />
                  </LayoutColumn>
                </LayoutColumns>
                {(pageContext.layout == 'auth-inner' ||
                  (location &&
                    location.pathname.search('/admin') >= 0 &&
                    location.pathname.search('/admin/auth') == -1)) && <AdminFooter />}
              </LayoutContent>
            </LayoutContainer>
          </Layout>
        </div>
      </ThemeProvider>
    );
  }
};

export default LayoutPage;
