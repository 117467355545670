import React, { Component } from 'react';
import { Card, CardBody } from '@paljs/ui/Card';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import { InputGroup } from '@paljs/ui/Input';
const isSSR = typeof window === 'undefined';
const CKEditor = !isSSR ? require('ckeditor4-react') : <div />;
//const ClassicEditor = !isSSR ? require('@ckeditor/ckeditor5-build-classic') : <div />;
import { Button } from '@paljs/ui/Button';
import styled from 'styled-components';
import Select from '@paljs/ui/Select';
import { Toastr } from '@paljs/ui/Toastr';
import firebase from 'gatsby-plugin-firebase';
import Popover2 from '@paljs/ui/Popover';
import ReportTab from './report-tabs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Checkbox } from '@paljs/ui/Checkbox';
import { EvaIcon } from '@paljs/ui/Icon';
import { navigate } from 'gatsby';
import PopUp from './popUp';
import Loader from '../modal-overlays/loader';
import Tooltip from '@paljs/ui/Tooltip';
import axios from 'axios';
import PasteModal from './pasteWord';
import DTNModal from './dtn-modal';
import { changeData } from './dtn-string';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const colButton = ['1/12', '2/12', '3/12', '4/12', '5/12', '6/12', '7/12', '8/12', '9/12', '10/12', '11/12', '12/12'];

const initial = {
  ov_title: 'Overview',
  ov_description: '',
  sections: [],
  tabsData: null,
};

const insObj = {
  id: '',
  label: '',
  sections: [],
  status: { label: 'Draft', value: 'draft' },
  title: '',
  subtitle: 'Trading Strategy',
  description: '',
  entry: '',
  stop: '',
  target: '',
  order: '',
};

let newDate = new Date();
newDate.setHours(0);
newDate.setMinutes(0);
newDate.setSeconds(0);

class AddReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initial,
      all_products: [],
      products: [],
      selectedProduct: '',
      times: [],
      selectedTime: '',
      reportType: '',
      instruments: [],
      deleteIndex: { type: '', index: '' },
      showConfirm: false,
      reportDate: new Date(),
      published: 'no',
      reportStatus: 'In Progress',
      popUpType: '',
      modalIndexes: {},
      popUpData: '',
      sendEmail: false,
      docId: '',
      errors: {},
      isLoading: false,
      isImgLoader: false,
      pasteModal: false,
      dtnModal: false,
      prod_dtn_codes: [],
      fileUpload: null,
      fileUrl: '',
    };

    this.toastrRef = React.createRef();
    this.tabRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.id) {
      this.setData();
    } else {
      let doc = firebase.firestore().collection('reports').doc();
      this.setState({ docId: doc.id });
    }
    this.getProducts();
  }

  setData = () => {
    // set initial values for the report if id exists in url
    this.setState({ isLoading: true });
    let _self = this;
    let storageRef = firebase.storage().ref('reports/').child(this.props.id);

    firebase
      .firestore()
      .collection('reports')
      .doc(this.props.id)
      .get()
      .then((doc) => {
        let params = {
          ...doc.data(),
          reportDate: new Date(doc.data().reportDate.seconds * 1000),
          docId: this.props.id,
          reportType: doc.data().reportType,
          isLoading: false,
          fileUrl: doc.data().docUrl,
        };
        _self.setState(params, () => this.getTimes(doc.data().selectedProduct.value));
      });
  };

  getProducts = () => {
    // get products from firestore
    let _self = this;
    firebase
      .firestore()
      .collection('products')
      .get()
      .then((snapshot) => {
        let dropdown_values = [];
        let allProducts = [];

        snapshot.forEach((doc) => {
          dropdown_values.push({ value: doc.id, label: doc.data().name });
          allProducts.push({ id: doc.id, ...doc.data() });
        });
        _self.setState({ products: dropdown_values, all_products: allProducts });
      });
  };

  getTimes = (id, type = '') => {
    //get report times for a specific product
    let _self = this;
    firebase
      .firestore()
      .collection('products')
      .doc(id)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          let insArr = [];
          let tempIns = doc.data().selectedIns;
          //let tempTabData = _self.state.tabsData;

          for (let i = 0; i < tempIns.length; i++) {
            tempIns[i].ref.get().then((ins) => {
              insArr.push({
                ...ins.data(),
                id: ins.id,
                order: tempIns[i].order,
              });
              /* if (_self.props.id) {
                                      let index = tempTabData.findIndex((item) => item.id == ins.id);
                                      if (index != -1) {
                                         tempTabData[index]['dtn_name'] = ins.data().dtn ? ins.data().dtn.dtn_name : '';
                                         tempTabData[index]['dtn_code'] = ins.data().dtn ? ins.data().dtn.dtn_code : '';
                                      }
                                   } */
            });
          }

          let params = {
            times: doc.data().selectedReports,
            instruments: insArr,
            //tabsData: tempTabData,
          };

          if (type == 'onSelect') {
            params['reportType'] = '';
            params['selectedTime'] = '';
          }
          _self.setState(params);
        } else {
          _self.setState({ error: 'Invalid Id' });
        }
      })
      .catch((e) => {});
  };

  handleSelect = (values, field) => {
    this.setState({ [field]: values });
    if (field == 'selectedProduct' && this.state.selectedProduct.value !== values.value) {
      //get new report times if selected product is different from current product
      this.setState(initial, () => this.getTimes(values.value, 'onSelect'));
    } else if (field == 'reportType' && this.state.reportType.value !== values.value) {
      if (values.value == 'intraday') {
        this.setState(initial, () => this.getLastReport('intraday'));
      } else {
        this.setState(initial, () => this.autoFillReport(values.value));
      }
    } else if (field == 'selectedTime' && this.state.selectedTime.value !== values.value) {
      //get last report of currently selected product and report time
      this.setState(initial, () => this.getLastReport());
    } else if (field == 'published' && values == 'no') {
      this.setState({ sendEmail: false });
    }
  };

  autoFillReport = (repType) => {
    let _self = this;
    //this.setState({ isLoading: true })
    firebase
      .firestore()
      .collection('reports')
      .where('selectedProduct.value', '==', this.state.selectedProduct.value)
      .orderBy('updatedAt', 'desc')
      .get()
      .then((snapShot) => {
        if (snapShot.docs.length > 0) {
          let doc = snapShot.docs[0];
          //let tempTabData = doc.data().tabsData;

          let currentIns = this.state.instruments.map((ins) => ins.id);
          //add data to tab data if it does not contain matching id

          _self.setState({
            //sections: doc.data().sections,
            //tabsData: tempTabData,
            ov_description: doc.data().ov_description,
            ov_title: doc.data().ov_title,
            selectedTime: doc.data().reportType,
            isLoading: false,
          });
        } else {
          _self.setState({ isLoading: false });
        }
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  getLastReport = (repType = '') => {
    // get last report of currently selected time and product
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('reports')
      .where('selectedProduct.value', '==', this.state.selectedProduct.value)
      .where('reportType.value', '==', repType == 'intraday' ? repType : this.state.selectedTime.value)
      .orderBy('updatedAt', 'desc')
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length > 0) {
          let doc = snapshot.docs[0];
          //let tempTabData = doc.data().tabsData;
          let currentIns = this.state.instruments.map((ins) => ins.id);

          //add data to tab data if it does not contain matching id
          /*  for (let i = 0; i < currentIns.length; i++) {
                  let tabIndex = tempTabData.findIndex((tab) => tab.id === currentIns[i]);
                  if (tabIndex === -1) {
                    tempTabData.push({
                      id: this.state.instruments[i].id,
                      label: this.state.instruments[i].name,
                      sections: [],
                      status: { label: 'Draft', value: 'draft' },
                      title: '',
                      subtitle: 'Trading Strategy',
                      description: '',
                      entry: '',
                      stop: '',
                      target: '',
                      order: this.state.instruments[i].order,
                    });
                  } else {
                    tempTabData[tabIndex]['status'] = { label: 'Draft', value: 'draft' };
                    tempTabData[tabIndex]['order'] = this.state.instruments[i].order;
                  }
                }
      
                for (let j = 0; j < tempTabData.length; j++) {
                  if (!currentIns.includes(tempTabData[j].id)) {
                    tempTabData.splice(j, 1);
                  }
                }
      
                tempTabData.sort((a, b) => {
                  return a.order - b.order;
                }); */

          let params = {
            //sections: doc.data().sections,
            //tabsData: tempTabData,
            ov_description: doc.data().ov_description,
            ov_title: doc.data().ov_title,
            isLoading: false,
          };

          if (repType == 'intraday') {
            params['selectedTime'] = { label: 'Intraday Report', value: 'intraday' };
          }

          _self.setState(params);
        } else {
          _self.setState({ isLoading: false });
        }
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  handleOverview = (field, data) => {
    //set the overview title and description
    this.setState({ [field]: data });
  };

  validateFields = () => {
    let formSubmit = true;
    let tempErrors = {};

    if (!this.state.selectedProduct) {
      tempErrors['product'] = 'This field is required';
      formSubmit = false;
    } else if (!this.state.reportType) {
      tempErrors['reportType'] = 'This field is required';
      formSubmit = false;
    } else if (!this.state.reportStatus) {
      tempErrors['reportStatus'] = 'This field is required';
      formSubmit = false;
    } else if (!this.props.id && !this.state.fileUpload && this.state.reportType.value !== 'intraday') {
      tempErrors['file'] = 'This field is required';
      formSubmit = false;
    }

    this.setState({ errors: tempErrors });
    return formSubmit;
  };

  /*saveDetailsOld = (saveType) => {
    if (this.validateFields()) {
      let tempProduct = this.state.all_products.find((item) => item.id == this.state.selectedProduct.value);
      let tempStr = JSON.stringify(this.tabRef.current.state.tabData);
      let tempTabData = JSON.parse(tempStr);

      let complTabsIds = tempTabData.filter((item) => item.status.value === 'completed').map((obj) => obj.id);

      tempTabData.forEach(function (v) {
        delete v.order;
        delete v.dtn_code;
        delete v.dtn_name;
      });

      let insEmailValues = [];
      let marketsArr = [];

      for (let i = 0; i < this.state.instruments.length; i++) {
        if (complTabsIds.includes(this.state.instruments[i].id)) {
          insEmailValues.push({
            imgUrl: this.state.instruments[i].imageUrl,
            name: this.state.instruments[i].name,
          });
          marketsArr.push(this.state.instruments[i].market);
        }
      }
      tempProduct['selectedIns'] = insEmailValues;

      if (this.state.sendEmail == true) {
        let emailParams = {
          product: tempProduct,
          report_status: this.state.reportStatus,
          report_type: this.state.reportType,
          title: this.state.ov_title,
          description: this.state.ov_description,
          doc_id: this.props.id ? this.props.id : this.state.docId,
          updatedAt: Date.now(),
          markets: marketsArr,
          report_date: this.state.reportDate,
        };
        this.sendEmails(emailParams);
      }
      this.setState({ isLoading: true });
      let _self = this;
      let params = {
        selectedProduct: this.state.selectedProduct,
        reportType: this.state.reportType,
        ov_title: this.state.ov_title,
        ov_description: this.state.ov_description,
        sections: this.state.sections,
        reportDate: this.state.reportDate,
        reportStatus: this.state.reportStatus,
        published: this.state.published,
        tabsData: tempTabData,
      };

      //set the product disclaimer if exists
      if (tempProduct.product_disclaimer && tempProduct.product_disclaimer !== '') {
        params['product_disclaimer'] = tempProduct.product_disclaimer;
      }

      // set the created date for new reports and update date for exisitng reports
      if (!this.props.id) {
        params['createdAt'] = firebase.firestore.Timestamp.now();
        params['updatedAt'] = firebase.firestore.Timestamp.now();
      } else {
        params['updatedAt'] = firebase.firestore.Timestamp.now();
      }

      let collectionRef = firebase.firestore().collection('reports');
      if (this.props.id) {
        //update the report
        collectionRef
          .doc(this.props.id)
          .update(params)
          .then(() => {
            _self.setState({ isLoading: false });
            if (saveType === 'saveClose') {
              navigate('/admin/report-management/report-listing');
            } else {
              _self.toastrRef.current?.add('Updated Details', 'Success', { status: 'Success' });
            }
          })
          .catch((err) => {
            _self.setState({ isLoading: false });
            _self.toastrRef.current?.add('Error Updating Details', 'Error', { status: 'Danger' });
          });
      } else {
        //add a new report
        collectionRef
          .doc(this.state.docId)
          .set(params)
          .then(() => {
            _self.setState({ isLoading: false });
            if (saveType === 'saveClose') {
              navigate('/admin/report-management/report-listing');
            } else {
              navigate(`/admin/report-management/add-report/${this.state.docId}`);
            }
          })
          .catch((err) => {
            _self.setState({ isLoading: false });
            _self.toastrRef.current?.add('Error Adding Details', 'Error', { status: 'Danger' });
          });
      }
    }
  };*/

  sendEmails = (params) => {
    axios
      .post(`${process.env.API_ENDPOINT}/send-emails`, params, { headers: { 'Content-Type': 'application/json' } })
      .then((res) => {})
      .catch((e) => {});
  };

  uploadImage = (file) => {
    //upload file to firebase
    let _self = this;
    this.setState({ isImgLoader: true });
    let { modalIndexes } = this.state;

    //store the image in firebase under a specific report id
    let storageRef = firebase
      .storage()
      .ref('reports/')
      .child(this.state.docId)
      .child(
        `${this.state.docId}-${modalIndexes['sectionIndex']}-${modalIndexes['rowIndex']}-${modalIndexes['colIndex']}`,
      );
    let tempArr = [...this.state.sections];
    let tempObj = tempArr[modalIndexes.sectionIndex]['rows'][modalIndexes.rowIndex]['columns'][modalIndexes.colIndex];

    storageRef
      .put(file)
      .then((result) => {
        storageRef.getDownloadURL().then((result) => {
          tempObj['colValue'] = result;
          _self.setState({ sections: tempArr, popUpType: '', isImgLoader: false });
        });
      })
      .catch((e) => {
        _self.setState({ isImgLoader: false });
      });
  };

  // NEW START

  handleDocFile = (e) => {
    this.setState({ fileUpload: e.target.files[0] });
  };

  saveDetails = (saveType) => {
    if (this.validateFields()) {
      let _self = this;
      _self.setState({ isLoading: true });

      let tempProduct = this.state.all_products.find((item) => item.id == this.state.selectedProduct.value);

      let insEmailValues = [];
      let marketsArr = [];

      for (let i = 0; i < this.state.instruments.length; i++) {
        insEmailValues.push({
          imgUrl: this.state.instruments[i].imageUrl,
          name: this.state.instruments[i].name,
        });
        marketsArr.push(this.state.instruments[i].market);
      }

      tempProduct['selectedIns'] = insEmailValues;

      let emailParams = {};

      if (this.state.sendEmail == true) {
        emailParams = {
          product: tempProduct,
          report_status: this.state.reportStatus,
          report_type: this.state.reportType,
          title: this.state.ov_title,
          description: this.state.ov_description,
          doc_id: this.props.id ? this.props.id : this.state.docId,
          updatedAt: Date.now(),
          markets: marketsArr,
          report_date: this.state.reportDate,
        };
      }

      let data = new FormData();
      data.append('doc', this.state.fileUpload);

      let params = {
        selectedProduct: this.state.selectedProduct,
        reportType: this.state.reportType,
        ov_title: this.state.ov_title,
        ov_description: this.state.ov_description,
        reportDate: this.state.reportDate,
        published: this.state.published,
        reportStatus: this.state.reportStatus,
      };

      //set the product disclaimer if exists
      if (tempProduct.product_disclaimer && tempProduct.product_disclaimer !== '') {
        params['product_disclaimer'] = tempProduct.product_disclaimer;
      }

      // set the created date for new reports and update date for exisitng reports
      if (!this.props.id) {
        params['createdAt'] = firebase.firestore.Timestamp.now();
        params['updatedAt'] = firebase.firestore.Timestamp.now();
      } else {
        params['updatedAt'] = firebase.firestore.Timestamp.now();
      }

      if (this.state.fileUpload) {
        axios
          .post(`${process.env.API_ENDPOINT}/convert-doc`, data, {
            headers: {
              'Content-Type': 'application/json',
              Document: this.props.id ? this.props.id : this.state.docId,
            },
          })
          .then((res) => {
            let storageRef = firebase.storage().ref('reports/').child(this.state.docId);
            storageRef.getDownloadURL().then((result) => {
              params['docUrl'] = result;
              if (this.state.sendEmail == true) {
                this.sendEmails({ ...emailParams, docUrl: result });
              }

              this.performSave(params, saveType);
            });
          })
          .catch((err) => {
            _self.setState({ isLoading: false });
            if (err && err.response.status === 400) {
              _self.toastrRef.current?.add(err.response.data.msg, 'Error', { status: 'Danger' });
            }
          });
      } else {
        params['docUrl'] = this.state.fileUrl;
        if (this.state.sendEmail == true) {
          this.sendEmails({ ...emailParams, docUrl: this.state.fileUrl });
        }
        this.performSave(params, saveType);
      }
    }
  };

  performSave = (params, saveType) => {
    let collectionRef = firebase.firestore().collection('reports');
    let _self = this;

    if (this.props.id) {
      //update the report
      collectionRef
        .doc(this.props.id)
        .update(params)
        .then(() => {
          _self.setState({ isLoading: false });
          if (saveType === 'saveClose') {
            navigate('/admin/report-management/report-listing');
          } else {
            document.getElementById('file-input').value = '';
            _self.setState({ fileUrl: params.docUrl, fileUpload: null });
            _self.toastrRef.current?.add('Updated Details', 'Success', { status: 'Success' });
          }
        })
        .catch((err) => {
          _self.setState({ isLoading: false });
        });
    } else {
      //add a new report
      collectionRef
        .doc(this.state.docId)
        .set(params)
        .then(() => {
          _self.setState({ isLoading: false });
          if (saveType === 'saveClose') {
            navigate('/admin/report-management/report-listing');
          } else {
            navigate(`/admin/report-management/add-report/${this.state.docId}`);
          }
        })
        .catch((err) => {
          _self.setState({ isLoading: false });
          _self.toastrRef.current?.add('Error Adding Details', 'Error', { status: 'Danger' });
        });
    }
  };

  //NEW END

  render() {
    return (
      <>
        <Row>
          <Toastr ref={this.toastrRef} />
          <Col breakPoint={{ xs: 12, md: 12 }}>
            <Card>
              <header>{this.props.id ? 'Update' : 'Add'} Report</header>
              <div className="row mx-0 mt-4">
                <div className="col-9">
                  <CardBody className="p-0 no-scroll">
                    <div className="report-box">
                      <Input fullWidth shape="SemiRound">
                        <input
                          type="text"
                          placeholder="Enter Overview"
                          name="ov_title"
                          onChange={(e) => this.handleOverview('ov_title', e.target.value)}
                          value={this.state.ov_title}
                        />
                      </Input>

                      <br />
                      {!isSSR && (
                        <CKEditor
                          data={this.state.ov_description ? this.state.ov_description : ''}
                          type="classic"
                          config={{ allowedContent: true, contentsCss: '/ck.css' }}
                          onChange={(event) => {
                            this.handleOverview('ov_description', event.editor.getData());
                          }}
                        />
                      )}
                    </div>
                    <br />
                    {this.state.fileUrl ? (
                      <div className="float-right">
                        <a href={this.state.fileUrl} target="_blank">
                          View PDF
                        </a>
                      </div>
                    ) : null}
                    <br />
                    <Input fullWidth shape="SemiRound">
                      <input
                        id="file-input"
                        type="file"
                        accept=".doc,.docx"
                        name="report_doc"
                        placeholder="Choose Document"
                        onChange={this.handleDocFile}
                      />
                    </Input>
                    <p className="form-error">{this.state.errors.file}</p>
                    <br />
                    <br />
                    <br />
                  </CardBody>
                </div>
                <div className="col-3 pl-0 sticky-box" style={{ height: '70vh', overflowY: 'auto' }}>
                  <div className="form-group">
                    <div className="small-label">Product</div>
                    <Select
                      fullwidth
                      placeholder="Select Product"
                      options={this.state.products}
                      onChange={(values) => this.handleSelect(values, 'selectedProduct')}
                      value={this.state.selectedProduct}
                      isDisabled={this.props.id ? true : false}
                    />
                    <p className="form-error">{this.state.errors.product}</p>
                  </div>
                  <div className="form-group">
                    <div className="small-label">Time</div>
                    <Select
                      fullwidth
                      placeholder="Select Report"
                      options={this.state.times}
                      onChange={(values) => this.handleSelect(values, 'reportType')}
                      value={this.state.reportType}
                      isDisabled={this.props.id ? true : false}
                    />
                    <p className="form-error">{this.state.errors.reportType}</p>
                  </div>

                  <div className="form-group">
                    <div className="small-label">Report Date</div>
                    <DatePicker
                      className="form-control w-100"
                      selected={this.state.reportDate}
                      onChange={(date) => this.setState({ reportDate: date })}
                    />
                  </div>
                  <div className="form-group">
                    <div className="small-label">Status</div>
                    <div className="radio-wrap">
                      <div className="radio d-block">
                        <label>
                          <input
                            name="reportStatus"
                            type="radio"
                            value="Not Started"
                            checked={this.state.reportStatus === 'Not Started'}
                            onChange={(e) => this.handleSelect(e.target.value, 'reportStatus')}
                          />
                          Not Started
                        </label>
                      </div>
                      <div className="radio d-block">
                        <label>
                          <input
                            name="reportStatus"
                            type="radio"
                            value="In Progress"
                            checked={this.state.reportStatus === 'In Progress'}
                            onChange={(e) => this.handleSelect(e.target.value, 'reportStatus')}
                          />
                          In Progress
                        </label>
                      </div>
                      <div className="radio d-block">
                        <label>
                          <input
                            name="reportStatus"
                            type="radio"
                            value="Completed"
                            checked={this.state.reportStatus === 'Completed'}
                            onChange={(e) => this.handleSelect(e.target.value, 'reportStatus')}
                          />
                          Completed
                        </label>
                      </div>
                    </div>
                    <p className="form-error">{this.state.errors.reportStatus}</p>
                  </div>
                  <div className="form-group">
                    <div className="small-label">Published</div>
                    <div className="radio-wrap">
                      <div className="radio d-block">
                        <label>
                          <input
                            name="published"
                            type="radio"
                            value="yes"
                            checked={this.state.published === 'yes'}
                            onChange={(e) => this.handleSelect(e.target.value, 'published')}
                          />
                          Yes
                        </label>
                      </div>
                      <div className="radio d-block">
                        <label>
                          <input
                            name="published"
                            type="radio"
                            value="no"
                            checked={this.state.published === 'no'}
                            onChange={(e) => this.handleSelect(e.target.value, 'published')}
                          />
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    {this.state.published == 'yes' && (
                      <Checkbox
                        checked={this.state.sendEmail}
                        onChange={() => this.handleSelect(!this.state.sendEmail, 'sendEmail')}
                      >
                        Send Email
                      </Checkbox>
                    )}
                  </div>
                  <div className="btn-new">
                    {' '}
                    <div className="btn-wrapper  pr-1">
                      <Button onClick={() => this.saveDetails('save')}>Save</Button>
                    </div>
                    <div className="btn-wrapper">
                      <Button onClick={() => this.saveDetails('saveClose')}>Save & Close</Button>
                    </div>
                  </div>

                  {this.props.id ? (
                    <div className="btn-pre">
                      <div className="btn-wrapper">
                        <a href={`/reports/${this.props.id}`} target="_blank">
                          PREVIEW
                        </a>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </Card>
          </Col>
        </Row>
        <Loader isOpen={this.state.isLoading} />
      </>
    );
  }
}

export default AddReport;
