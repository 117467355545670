import React from 'react';

const BannerSection = () => (
  <section className="banner-section">
    <div className="container">
      <div className="logo-set">
        <div className="logo-set-box">
          <figure className="logo-set-box-img">
            <div className=" gatsby-image-wrapper">
              <img aria-hidden="true" src="/images/logo-cnbc-128.jpg" alt="cnbc" className="img-class" />
            </div>
          </figure>
          <div className="logo-set-box-content">
            <p className="logo-set-box-content-p">
              Interviewed regarding accurate predictions on 2008 collapse in stock market
            </p>
          </div>
        </div>
        <div className="logo-set-box">
          <figure className="logo-set-box-img">
            <div className=" gatsby-image-wrapper">
              <img aria-hidden="true" src="/images/logo-timer-digest-128.jpg" alt="cnbc" className="img-class" />
              <noscript>
                &lt;picture&gt;&lt;source
                srcset="/static/7b19871c4fbb6042f5b82bf3666060da/91723/logo-timer-digest-128.jpg 200w,
                /static/7b19871c4fbb6042f5b82bf3666060da/a5260/logo-timer-digest-128.jpg 256w" sizes="(max-width: 256px)
                100vw, 256px" /&gt;&lt;img loading="lazy" sizes="(max-width: 256px) 100vw, 256px"
                srcset="/static/7b19871c4fbb6042f5b82bf3666060da/91723/logo-timer-digest-128.jpg 200w,
                /static/7b19871c4fbb6042f5b82bf3666060da/a5260/logo-timer-digest-128.jpg 256w"
                src="static/7b19871c4fbb6042f5b82bf3666060da/a5260/logo-timer-digest-128.jpg" alt=""
                style="position:absolute;top:0;left:0;opacity:1;width:100%;height:100%;object-fit:cover;object-position:center"/&gt;&lt;/picture&gt;
              </noscript>
            </div>
          </figure>
          <div className="logo-set-box-content">
            <p className="logo-set-box-content-p">Ranked in top 10 for over 10 years</p>
          </div>
        </div>
        <div className="logo-set-box">
          <figure className="logo-set-box-img">
            <div className=" gatsby-image-wrapper">
              <img
                aria-hidden="true"
                src="/images/logo-traders-world-128.jpg"
                alt="traders-world"
                className="img-class"
              />
              <noscript>
                &lt;picture&gt;&lt;source
                srcset="/static/e37db313c2ccc28e14ffc19a442e695d/91723/logo-traders-world-128.jpg 200w,
                /static/e37db313c2ccc28e14ffc19a442e695d/a5260/logo-traders-world-128.jpg 256w" sizes="(max-width:
                256px) 100vw, 256px" /&gt;&lt;img loading="lazy" sizes="(max-width: 256px) 100vw, 256px"
                srcset="/static/e37db313c2ccc28e14ffc19a442e695d/91723/logo-traders-world-128.jpg 200w,
                /static/e37db313c2ccc28e14ffc19a442e695d/a5260/logo-traders-world-128.jpg 256w"
                src="static/e37db313c2ccc28e14ffc19a442e695d/a5260/logo-traders-world-128.jpg" alt=""
                style="position:absolute;top:0;left:0;opacity:1;width:100%;height:100%;object-fit:cover;object-position:center"/&gt;&lt;/picture&gt;
              </noscript>
            </div>
          </figure>
          <div className="logo-set-box-content">
            <p className="logo-set-box-content-p">Numerous articles on geocosmic cycles to timing of markets</p>
          </div>
        </div>
        <div className="logo-set-box">
          <figure className="logo-set-box-img">
            <div className=" gatsby-image-wrapper">
              <img
                aria-hidden="true"
                src="/images/logo-soes-128.jpg"
                alt="school-of-philosophy"
                className="img-class"
              />
              <noscript>
                &lt;picture&gt;&lt;source srcset="/static/70d37d18eaa350129a13df9e0bbc5801/91723/logo-soes-128.jpg 200w,
                /static/70d37d18eaa350129a13df9e0bbc5801/a5260/logo-soes-128.jpg 256w" sizes="(max-width: 256px) 100vw,
                256px" /&gt;&lt;img loading="lazy" sizes="(max-width: 256px) 100vw, 256px"
                srcset="/static/70d37d18eaa350129a13df9e0bbc5801/91723/logo-soes-128.jpg 200w,
                /static/70d37d18eaa350129a13df9e0bbc5801/a5260/logo-soes-128.jpg 256w"
                src="static/70d37d18eaa350129a13df9e0bbc5801/a5260/logo-soes-128.jpg" alt=""
                style="position:absolute;top:0;left:0;opacity:1;width:100%;height:100%;object-fit:cover;object-position:center"/&gt;&lt;/picture&gt;
              </noscript>
            </div>
          </figure>
          <div className="logo-set-box-content">
            <p className="logo-set-box-content-p">
              Multiple speaking interviews at London School of Philosophy and Economics on long-term stock market cycles
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default BannerSection;
