import React from 'react';

const ContactBoxes = () => (
  <div className="contact-boxes-wrap ">
    <div className="row">
      <div className="col-md-4">
        <div className="contact-box-icon icon1">
          <img src="/images/mail.svg" width="100px" className="ques-black" />
          <img src="/images/mail-white.svg" width="100px" className="ques-white" />
          <h6>Fortucast Market Timing</h6>
          <p className="mt-3 mb-3">PO Box 266 Fairfield IA 52556</p>
          <p>Email: questions@fortucast.com</p>
        </div>
      </div>
      <div className="col-md-4 icon2">
        <div className="contact-box-icon">
          <img src="/images/location.svg" width="100px" className="ques-black" />
          <img src="/images/location-white.svg" width="100px" className="ques-white" />
          <h6>Office Location</h6>
          <p className="mt-3 mb-3">2090 185th St, Suite 32 Fairfield, IA 52256</p>
          <p>Hours: 8am-8 pm CT</p>
        </div>
      </div>
      <div className="col-md-4 icon3">
        <div className="contact-box-icon">
          <img src="/images/call.svg" width="100px" className="ques-black" />
          <img src="/images/call-white.svg" width="100px" className="ques-white" />
          <h6>Give us a call</h6>
          <p className="mt-3 mb-3">Phone: 800-788-2796 ext 52556</p>
          <p>Outside the US: 928-284-5740</p>
          <p>ext 5983 or 5000</p>
        </div>
      </div>
    </div>
  </div>
);

export default ContactBoxes;
