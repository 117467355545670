import React from 'react';
import Helmet from 'react-helmet';

const SEO = (props) => {
  return (
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta name="keywords" content={props.keywords} />
      <meta name="author" content={props.author} />
      <meta property="og:site_name" content={props.site_name} />
      <meta property="og:type" content={props.type} />
      <meta property="og:url" content={props.og_url} />
      <meta property="og:title" content={props.og_title} />
      <meta property="og:description" content={props.og_description} />
      <meta property="og:image" content={`${process.env.IMAGE_URL}/${props.og_image}`} />
      <meta property="og:image:type" content={props.og_image_type} />
      <meta property="og:image:width" content={props.og_image_width} />
      <meta property="og:image:height" content={props.og_image_height} />
      <meta property="og:image:alt" content={props.og_image_alt} />
      <meta property="twitter:title" content={props.twitter_title} />
      <meta property="twitter:description" content={props.twitter_description} />
      <meta property="twitter:image" content={`${process.env.IMAGE_URL}/${props.twitter_image}`} />
      <meta property="twitter:image:alt" content={props.twitter_image_alt} />
      <meta property="twitter:card" content={props.twitter_card} />
      <meta property="twitter:site" content={props.twitter_site} />
      <meta property="twitter:creator" content={props.twitter_creator} />
    </Helmet>
  );
};

export default SEO;
