import React, { useState, useEffect } from 'react';
import firebase from 'gatsby-plugin-firebase';
import Modal from 'react-modal';
import axios from 'axios';
import { navigate } from '@reach/router';

const Checkout = (props) => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [cardList, setCardList] = useState([]);
  const [user, setUser] = useState({});
  const [loader, setLoader] = useState(false);
  const [showForm, setForm] = useState(true);
  const [coupon, setCoupon] = useState('');
  const [validCoupon, setValid] = useState(false);
  const [disableCo, setDisableCo] = useState(false);
  const [price, setPrice] = useState(parseInt(props.price));
  const [card, setCard] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
  });

  useEffect(() => {
    checkUserSubsc();
  }, []);

  const checkUserSubsc = () => {
    setLoader(true);
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .firestore()
          .collection('user_subscriptions')
          .where('user_email', '==', user.email)
          .where('product_id', '==', props.product_id)
          .where('status.value', '==', 'active')
          .get()
          .then((snapShot) => {
            if (snapShot.docs.length > 0) {
              setLoader(false);
              setForm(false);
              setErrors({ subscription: 'An active subscription of this product exists' });
            } else {
              firebase
                .firestore()
                .collection('users')
                .doc(user.uid)
                .get()
                .then((res) => {
                  if (res.data().stripe_cust_id && res.data().stripe_cust_id !== '') {
                    setUser({
                      ...res.data(),
                      stripe_id: res.data().stripe_cust_id,
                      uid: user.uid,
                      email: user.email,
                      sendPdf: res.data().sendPdf === true || res.data().sendPdf === false ? res.data().sendPdf : false,
                    });
                    getPaymentMethods(res.data().stripe_cust_id);
                  } else {
                    setLoader(false);
                  }
                  if (props.coupon_code) {
                    setCoupon(props.coupon_code);
                  }
                });
            }
          });
      } else {
        setLoader(false);
        if (props.coupon_code) {
          setCoupon(props.coupon_code);
        }
      }
    });
  };

  const getPaymentMethods = (cust_id) => {
    let config = {
      headers: { 'Content-Type': 'application/json' },
    };

    let data = {
      stripe_cust_id: cust_id,
    };

    axios
      .get(`${process.env.API_ENDPOINT}/get-payment-methods`, { params: data }, config)
      .then((res) => {
        let tempArr = res.data.data.length
          ? res.data.data.map((item) => {
              return {
                pay_id: item.id,
                expired:
                  item.card.exp_month < new Date().getMonth() + 1 && item.card.exp_year === new Date().getFullYear()
                    ? true
                    : false,
                last4: item.card.last4,
              };
            })
          : [];
        setLoader(false);
        setCardList(tempArr);
      })
      .catch((e) => {
        setLoader(false);
      });
  };

  const onAfterOpen = () => {
    document.getElementsByClassName('ReactModalPortal')[0]?.classList.add('light-theme');
  };

  const handleInputFocus = (e) => {
    setCard({ ...card, focus: e.target.name });
  };

  const setEXpiry = (value) => {
    let clearValue = value.replace(/\D+/g, '');
    if (clearValue.length >= 3) {
      return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }
    return clearValue;
  };

  const setNumber = (value) => {
    let clearValue = value.replace(/\D+/g, '');
    let num = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(8, 12)} ${clearValue.slice(
      12,
      19,
    )}`;

    return num.trim();
  };

  const handleInputChange = (e) => {
    if (e.target.name === 'expiry') {
      e.target.value = setEXpiry(e.target.value);
    } else if (e.target.name === 'number') {
      e.target.value = setNumber(e.target.value);
    }

    setCard({ ...card, [e.target.name]: e.target.value });
  };

  const validateFields = () => {
    let formSubmit = true;
    let tempErrors = {};

    if (!card.number || !/^[0-9]{16,19}$/.test(card.number.split(' ').join(''))) {
      tempErrors['number'] = 'Please enter a valid card number';
      formSubmit = false;
    } else if (!card.name || !/^[a-zA-Z ]+$/i.test(card.name)) {
      tempErrors['name'] = 'Please enter a valid name';
      formSubmit = false;
    } else if (!card.expiry || card.expiry.length !== 5) {
      tempErrors['expiry'] = 'Please enter a valid expiry date';
      formSubmit = false;
    } else if (
      card.expiry.length === 5 &&
      !(parseInt(card.expiry.split('/')[0]) >= 1 && parseInt(card.expiry.split('/')[0]) <= 12)
    ) {
      tempErrors['expiry'] = 'Please enter a valid expiry month';
      formSubmit = false;
    } else if (
      card.expiry.length === 5 &&
      parseInt(card.expiry.split('/')[1]) < parseInt(new Date().getFullYear().toString().substr(-2))
    ) {
      tempErrors['expiry'] = 'Please enter a valid expiry year';
      formSubmit = false;
    } else if (
      parseInt(card.expiry.split('/')[0]) < new Date().getMonth() + 1 &&
      card.expiry.split('/')[1] === new Date().getFullYear().toString().substr(-2)
    ) {
      tempErrors['expiry'] = 'Please enter a valid expiry date';
      formSubmit = false;
    } else if (!card.cvc || !/^[0-9]{3,4}$/.test(card.cvc)) {
      tempErrors['cvc'] = 'Please enter a valid cvc code';
      formSubmit = false;
    } else if (!email || (email && !/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/g.test(email))) {
      tempErrors['email'] = 'Please enter a valid email';
      formSubmit = false;
    } else if (disableCo === true && validCoupon === false) {
      tempErrors['subscription'] = 'Please remove the invalid coupon code';
      formSubmit = false;
    }

    setErrors(tempErrors);
    return formSubmit;
  };

  const makePayment = (e) => {
    e.preventDefault();
    if (validateFields()) {
      setLoader(true);
      let config = {
        headers: { 'Content-Type': 'application/json' },
      };

      let data = {
        email: email,
        plan_name: props.plan_name,
        plan_type: props.plan_type,
        product_id: props.product_id,
        price_id: props.price_id,
        card_details: card,
        product_img: props.product_img,
        coupon_code: validCoupon ? coupon : '',
      };

      let thankPrice = parseFloat(data.plan_type == 'Annually' ? price / 12 : price / 3).toFixed(2);

      axios
        .post(`${process.env.API_ENDPOINT}/create-payment`, data, config)
        .then((res) => {
          makeUserLogin(email);
        })
        .catch((error) => {
          setLoader(false);
          setCard({
            cvc: '',
            expiry: '',
            focus: '',
            name: '',
            number: '',
          });
          removeCoupon();
          setErrors({ subscription: error.response.data.msg });
        });
    }
  };

  const useCard = (pay_id) => {
    setLoader(true);
    let config = {
      headers: { 'Content-Type': 'application/json' },
    };

    let data = {
      email: user.email,
      uid: user.uid,
      plan_name: props.plan_name,
      plan_type: props.plan_type,
      product_id: props.product_id,
      price_id: props.price_id,
      stripe_cust_id: user.stripe_id,
      pay_id: pay_id,
      product_img: props.product_img,
      coupon_code: validCoupon ? coupon : '',
      sendPdf: user.sendPdf,
      additional: user.secondaryEmail && user.secondaryEmail !== '' ? user.secondaryEmail : '',
    };

    let thankPrice = parseFloat(data.plan_type == 'Annually' ? price / 12 : price / 3).toFixed(2);

    axios
      .post(`${process.env.API_ENDPOINT}/use-existing-card`, data, config)
      .then((res) => {
        setLoader(false);
        setForm(false);
        navigate(`/thank-you/?prod=${props.shortName}&plan=${data.plan_type}&amount=${thankPrice}`);
      })
      .catch((error) => {
        setLoader(false);
        removeCoupon();
        setErrors({ subscription: error.response.data.msg });
      });
  };

  const getPromoDetails = () => {
    if (coupon == '') {
      setErrors({ coupon: 'Please enter a valid code' });
    } else {
      let config = {
        headers: { 'Content-Type': 'application/json' },
      };

      let data = {
        coupon_code: coupon,
        stripe_prod_id: props.stripe_prod_id,
      };

      axios
        .get(`${process.env.API_ENDPOINT}/coupon-details`, { params: data }, config)
        .then((res) => {
          if (res.data.data.valid) {
            let amount = price - (price * res.data.data.percent_off) / 100;
            setPrice(amount);
            setErrors({ coupon: '' });
            setValid(true);
            setDisableCo(true);
          } else {
            setDisableCo(true);
            setErrors({ coupon: 'Not a valid coupon code' });
          }
        })
        .catch((e) => {
          setDisableCo(true);
          setErrors({ coupon: e.response.data.msg });
        });
    }
  };

  const removeCoupon = () => {
    setValid(false);
    setCoupon('');
    setDisableCo(false);
    setErrors({ ...errors, coupon: '' });
    setPrice(props.price);
  };

  const makeUserLogin = (email: string) => {
    if (localStorage.getItem('user')) {
      navigate('/thank-you');
    } else {
      axios
        .post(
          `${process.env.API_ENDPOINT}/checkout-login-link`,
          { email },
          { headers: { 'Content-Type': 'application/json' } },
        )
        .then((res: any) => {
          firebase
            .auth()
            .signInWithEmailLink(email, res.data.link)
            .then((res) => {
              localStorage.setItem('user', 'true');
              navigate('/thank-you');
            })
            .catch((e) => {
              navigate('/thank-you');
            });
        });
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      className={{
        base: 'checkout-modal',
        afterOpen: 'checkout_modal-base_after-open',
      }}
      onAfterOpen={onAfterOpen}
    >
      <div className="closediv">
        <button className="btn-close" onClick={props.close}>
          &times;
        </button>
      </div>
      <section className="our-subscription-section card-modal">
        <div className="container">
          <div className="product-container">
            <h4> {props.plan_name}</h4>
            <h5>{`Pay $${process.env.TRIAL_PRICE} for unlimited access for ${
              process.env.TRIAL_DAYS
            } days then $${price} ${props.plan_type.toLowerCase()}.`}</h5>
          </div>

          {loader ? (
            <div className="spin-loader">
              <div className="spinner-border text-primary" />
            </div>
          ) : (
            <>
              <div>
                {cardList.length ? (
                  <div>
                    <table className="table table-bordered dark-color mt-4">
                      <thead>
                        <tr>
                          <th>Last Four Digits</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cardList.map((card, i) => (
                          <tr key={i}>
                            <td>{'XXXX ' + card.last4}</td>
                            <td>
                              {card.expired ? (
                                'Expired'
                              ) : (
                                <button className="btn btn-info" onClick={() => useCard(card.pay_id)}>
                                  Use
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="text-center">
                      <h6>Or</h6>
                      <h5>Add a new Card</h5>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="email-container">
                <div id="PaymentForm">
                  {showForm && (
                    <form onSubmit={makePayment}>
                      <div className="container mt-1 p-1">
                        <div className="form-group">
                          <input
                            type="tel"
                            name="number"
                            placeholder="Card Number"
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            className="form-control"
                            value={card.number}
                          />
                          <p className="form-error">{errors.number}</p>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            placeholder="Name on card"
                            className="form-control"
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            value={card.name}
                          />
                          <p className="form-error">{errors.name}</p>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <input
                                type="tel"
                                name="expiry"
                                className="form-control"
                                placeholder="Expiry"
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                value={card.expiry}
                              />
                              <p className="form-error">{errors.expiry}</p>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <input
                                type="password"
                                name="cvc"
                                className="form-control"
                                placeholder="CVC"
                                maxLength={4}
                                onChange={handleInputChange}
                                onFocus={handleInputFocus}
                                value={card.cvc}
                              />
                              <p className="form-error">{errors.cvc}</p>
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="email"
                            placeholder="Email"
                            className="form-control"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                          <p className="form-error">{errors.email}</p>
                        </div>
                        <div className="row">
                          <div className="form-group col-sm-9">
                            <input
                              type="text"
                              name="coupon"
                              placeholder="Coupon"
                              className="form-control"
                              onChange={(e) => setCoupon(e.target.value)}
                              disabled={disableCo}
                              value={coupon}
                            />
                            {validCoupon ? (
                              <p className="text-success mt-1">Successfully Applied Coupon</p>
                            ) : (
                              <p className="form-error mt-1">{errors.coupon}</p>
                            )}
                          </div>
                          <div className="col-sm-3">
                            {disableCo ? (
                              <button className="btn btn-block btn-danger" onClick={removeCoupon}>
                                Remove
                              </button>
                            ) : (
                              <button
                                className="btn btn-success btn-block"
                                type="button"
                                onClick={getPromoDetails}
                                id="apply"
                              >
                                Apply
                              </button>
                            )}
                          </div>
                        </div>
                        <button className="btn btn-primary btn-block" type="submit">
                          PAY
                        </button>
                      </div>
                    </form>
                  )}
                  <p className="mt-1 text-center">{errors.subscription}</p>
                </div>
              </div>
              <p className="refund-txt">
                <span className="refnd">REFUND POLICY:</span> No refunds on trials or the first three months of any
                subscription. 50% on any remaining portion. No refunds on discounted subscriptions.
              </p>
            </>
          )}
        </div>
      </section>
    </Modal>
  );
};

export default Checkout;
