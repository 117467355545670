import React from 'react';
import { Card, CardBody } from '@paljs/ui/Card';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import { InputGroup } from '@paljs/ui/Input';
import styled from 'styled-components';
import { Toastr } from '@paljs/ui/Toastr';
import { Button } from '@paljs/ui/Button';
import firebase from 'gatsby-plugin-firebase';
import Select from '@paljs/ui/Select';
import Loader from '../modal-overlays/loader';
import { navigate } from 'gatsby';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const initial_state = {
  name: '',
  market: '',
  types: '',
  symbol: '',
  dtn: {
    dtn_code: '',
    dtn_name: '',
  },
};
const typesOptions = [
  { value: 'Futures', label: 'Futures' },
  { value: 'Meats', label: 'Meats' },
];

class Instruments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: initial_state,
      fileUpload: null,
      errors: {},
      isLoading: false,
      logoFile: null,
    };

    this.toastrRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.id) {
      this.getInitialData();
    }
  }

  getInitialData = () => {
    let _self = this;
    this.setState({ isLoading: true });
    firebase
      .firestore()
      .collection('instruments')
      .doc(this.props.id)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          _self.setState({ data: { ...initial_state, ...doc.data() }, errors: {}, isLoading: false });
        } else {
          _self.setState({ error: 'Invalid', isLoading: false });
        }
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
      });
  };

  onChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  };

  dtnChange = (e) => {
    let tempData = { ...this.state.data };
    tempData['dtn'][e.target.name] = e.target.value;
    this.setState({ data: tempData });
  };

  handleSelect = (values, field) => {
    this.setState({ data: { ...this.state.data, [field]: values } });
  };

  handleFile = (e) => {
    this.setState({ fileUpload: e.target.files[0] });
  };

  validateFields = () => {
    let formSubmit = true;
    let tempErrors = {};

    if (!this.state.data.name) {
      tempErrors['name'] = 'This field is required';
      formSubmit = false;
    } else if (!this.state.data.market) {
      tempErrors['market'] = 'This field is required';
      formSubmit = false;
    } else if (this.state.data.market && !/^[a-zA-Z ]+$/i.test(this.state.data.market)) {
      tempErrors['market'] = 'Invalid Market';
      formSubmit = false;
    } else if (!this.state.data.types) {
      tempErrors['types'] = 'This field is required';
      formSubmit = false;
    } else if (!this.state.data.symbol) {
      tempErrors['symbol'] = 'This field is required';
      formSubmit = false;
    } else if (!this.state.data.dtn.dtn_code) {
      tempErrors['dtn_code'] = 'This field is required';
      formSubmit = false;
    } else if (!this.state.data.dtn.dtn_name) {
      tempErrors['dtn_name'] = 'This field is required';
      formSubmit = false;
    } else if (!this.props.id && !this.state.logoFile) {
      tempErrors['logo'] = 'This field is required';
      formSubmit = false;
    } else if (!this.props.id && !this.state.fileUpload) {
      tempErrors['file'] = 'This field is required';
      formSubmit = false;
    }

    this.setState({ errors: tempErrors });
    return formSubmit;
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.validateFields()) {
      if (this.props.id) {
        this.updateDetails();
      } else {
        this.addData();
      }
    }
  };

  updateDetails = () => {
    this.setState({ isLoading: true });
    let doc = firebase.firestore().collection('instruments').doc(this.props.id);
    let storageRef = firebase.storage().ref('instruments/' + doc.id);
    let logoRef = firebase.storage().ref('instrument-logos/' + doc.id);

    let tempArr = [];

    if (this.state.fileUpload != null) {
      tempArr.push(this.getFileLink(storageRef, this.state.fileUpload, false));
    }

    if (this.state.logoFile != null) {
      tempArr.push(this.getFileLink(logoRef, this.state.logoFile, true));
    }

    if (tempArr.length) {
      Promise.all(tempArr).then((values) => {
        if (values[0].isLogo) {
          this.performUpdate('', values[0].link);
        } else if (values[1] && values[1].isLogo) {
          this.performUpdate(values[0].link, values[1].link);
        } else {
          this.performUpdate(values[0].link, '');
        }
      });
    } else {
      this.performUpdate('', '');
    }
    //this.performUpdate(img_url, logo_url);
  };

  getFileLink = (ref, file, isLogo) => {
    return new Promise((resolve, reject) => {
      ref.put(file).then((result) => {
        ref.getDownloadURL().then((result) => {
          return resolve({ link: result, isLogo: isLogo });
        });
      });
    });
  };

  performUpdate = (url = '', logoUrl = '') => {
    let _self = this;
    this.setState({ isLoading: true });
    let doc = firebase.firestore().collection('instruments').doc(this.props.id);
    let params = { ...this.state.data };

    if (url !== '') {
      params['imageUrl'] = url;
    }
    if (logoUrl !== '') {
      params['logoUrl'] = logoUrl;
    }
    doc
      .update(params)
      .then(() => {
        //document.getElementById('file-input').value = '';
        //document.getElementById('logo-input').value = '';
        /* _self.setState({ data: { ...this.state.data, imageUrl: url, logoUrl : logoUrl }, fileUpload: null, , isLoading: false }); */
        _self.setState({ isLoading: false });
        setTimeout(() => {
          navigate('/admin/instruments/instruments-listing');
        }, 500);
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
        _self.toastrRef.current?.add('Error Updating Data', 'Error', { status: 'Danger' });
      });
  };

  addData = () => {
    this.setState({ isLoading: true });
    let doc = firebase.firestore().collection('instruments').doc();
    let _self = this;

    let storageRef = firebase.storage().ref('instruments/' + doc.id);
    let logoRef = firebase.storage().ref('instrument-logos/' + doc.id);

    storageRef
      .put(_self.state.fileUpload)
      .then((result) => {
        storageRef.getDownloadURL().then(function (img_url) {
          logoRef.put(_self.state.logoFile).then((result) => {
            logoRef.getDownloadURL().then(function (logo_url) {
              _self.performSave(doc, img_url, logo_url);
            });
          });
        });
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
        _self.toastrRef.current?.add('Could Not Add Image', 'Error', { status: 'Danger' });
      });
  };

  performSave = (doc, url, logoUrl) => {
    let _self = this;

    doc
      .set({ ..._self.state.data, imageUrl: url, logoUrl, createdAt: firebase.firestore.Timestamp.now() })
      .then((res) => {
        /* document.getElementById('file-input').value = '';
            _self.setState({ data: initial_state, errors: {}, fileUpload: null, isLoading: false }); */
        navigate('/admin/instruments/instruments-listing');
      })
      .catch((e) => {
        _self.setState({ isLoading: false });
        _self.toastrRef.current?.add('Error Adding Data', 'Error', { status: 'Danger' });
      });
  };

  render() {
    return (
      <>
        <Row>
          <Toastr ref={this.toastrRef} />
          <Col breakPoint={{ xs: 12, md: 12 }}>
            <form onSubmit={this.onSubmit}>
              <Card>
                <header>Instruments</header>
                <CardBody>
                  <label>Instrument Name</label>
                  <Input fullWidth shape="SemiRound">
                    <input
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={this.state.data.name}
                      onChange={this.onChange}
                    />
                  </Input>
                  <p className="form-error">{this.state.errors.name}</p>
                  <br />
                  <label>Instrument Market</label>
                  <Input fullWidth shape="SemiRound">
                    <input
                      type="text"
                      placeholder="Market"
                      value={this.state.data.market}
                      onChange={this.onChange}
                      name="market"
                    />
                  </Input>
                  <p className="form-error">{this.state.errors.market}</p>
                  <br />
                  <label>Market Types</label>
                  <Select
                    shape="SemiRound"
                    name="types"
                    options={typesOptions}
                    placeholder="Select Types"
                    onChange={(val) => this.handleSelect(val, 'types')}
                    value={this.state.data.types}
                  />
                  <p className="form-error">{this.state.errors.types}</p>
                  <br />
                  <label>Symbol</label>
                  <Input fullWidth shape="SemiRound">
                    <input
                      type="text"
                      placeholder="Symbol"
                      value={this.state.data.symbol}
                      onChange={this.onChange}
                      name="symbol"
                    />
                  </Input>
                  <p className="form-error">{this.state.errors.symbol}</p>
                  <br />
                  <label>DTN Code</label>
                  <Input fullWidth shape="SemiRound">
                    <input
                      type="text"
                      name="dtn_code"
                      placeholder="DTN Code"
                      onChange={this.dtnChange}
                      value={this.state.data.dtn.dtn_code}
                    />
                  </Input>
                  <p className="form-error">{this.state.errors.dtn_code}</p>
                  <br />
                  <label>DTN Name</label>
                  <Input fullWidth shape="SemiRound">
                    <input
                      type="text"
                      name="dtn_name"
                      placeholder="DTN Name"
                      onChange={this.dtnChange}
                      value={this.state.data.dtn.dtn_name}
                    />
                  </Input>
                  <p className="form-error">{this.state.errors.dtn_name}</p>
                  <br />
                  <label>Instrument Image</label>
                  <Input fullWidth shape="SemiRound">
                    <input
                      id="logo-input"
                      type="file"
                      name="instrument_logo"
                      placeholder="Choose Image"
                      onChange={(e) => this.setState({ logoFile: e.target.files[0] })}
                    />
                  </Input>
                  <p className="form-error">{this.state.errors.logo}</p>
                  {this.state.data.logoUrl && <img src={this.state.data.logoUrl} height="150px" />}
                  <br />
                  <br />

                  <label>Instrument Icon</label>
                  <Input fullWidth shape="SemiRound">
                    <input
                      id="file-input"
                      type="file"
                      name="instrument_icon"
                      placeholder="Choose Image"
                      onChange={(e) => this.setState({ fileUpload: e.target.files[0] })}
                    />
                  </Input>
                  <p className="form-error">{this.state.errors.file}</p>
                  {this.state.data.imageUrl && <img src={this.state.data.imageUrl} height="150px" />}
                  <br />
                  <br />
                  <Button shape="SemiRound" type="submit" className="float-right">
                    Save Details
                  </Button>
                </CardBody>
              </Card>
            </form>
          </Col>
        </Row>
        <Loader isOpen={this.state.isLoading} />
      </>
    );
  }
}

export default Instruments;
