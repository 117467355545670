import React from 'react';

const WhyUs = () => (
  <section className="why-choose-us">
    <div className="container">
      <div className="why-choose-us-content">
        <div className="why-choose-us-content-text">
          <h2 className="why-choose-us-content-text-h2">Why choose us</h2>
          <p className="why-choose-us-content-text-p">
            We provide the best in class market research to help you grow your portfolio
          </p>
          <div className="why-choose-us-content-list">
            <div className="why-choose-us-content-list-box">
              <div className="why-choose-us-content-list-box-number">
                <p className="why-choose-us-content-list-box-number-text">01</p>
              </div>
              <div className="why-choose-us-content-list-box-text">
                <h5 className="why-choose-us-content-list-box-text-h5">+33 Years in Business</h5>
                <p className="why-choose-us-content-list-box-text-p">
                  In October 2007, we predicted a major top for the stock market and a 2-3 year decline. Consistantly
                  ranked top 10 in Timer's Digest.
                </p>
              </div>
            </div>
            <div className="why-choose-us-content-list-box">
              <div className="why-choose-us-content-list-box-number">
                <p className="why-choose-us-content-list-box-number-text">02</p>
              </div>
              <div className="why-choose-us-content-list-box-text">
                <h5 className="why-choose-us-content-list-box-text-h5">Specific Trade Ideas</h5>
                <p className="why-choose-us-content-list-box-text-p">
                  Leveraging GANN, Elliot Wave, and Proprietary Cyclical analysis, we provide specific entry, stop,
                  target positions on over 30 Futures, ETF and Stock products.
                </p>
              </div>
            </div>
            <div className="why-choose-us-content-list-box">
              <div className="why-choose-us-content-list-box-number">
                <p className="why-choose-us-content-list-box-number-text">03</p>
              </div>
              <div className="why-choose-us-content-list-box-text">
                <h5 className="why-choose-us-content-list-box-text-h5">Long Term Guidance</h5>
                <p className="why-choose-us-content-list-box-text-p">
                  Savvy fundamental analysis and news coverage to help swing and long term traders. Expert hedging
                  recommendations and cyclical management
                </p>
              </div>
            </div>
            <div className="why-choose-us-content-list-box">
              <div className="why-choose-us-content-list-box-number">
                <p className="why-choose-us-content-list-box-number-text">04</p>
              </div>
              <div className="why-choose-us-content-list-box-text">
                <h5 className="why-choose-us-content-list-box-text-h5">Timely Reports</h5>
                <p className="why-choose-us-content-list-box-text-p">
                  Reports are published twice daily morning and evening, release times are between 7:30-9am CST in the
                  morning and 6-8pm CST in the evening. Reports are approximately 4-6 pages in length.
                </p>
              </div>
            </div>
          </div>
        </div>
        <figure className="image-wrapper">
          <div className="gatsby-image-wrapper">
            <img aria-hidden="true" className="chart-img" src="/images/chart.jpg" alt="why-us-chart" />
          </div>
        </figure>
      </div>
    </div>
  </section>
);

export default WhyUs;
