import React from 'react';
import SEO from '../components/SEO';

const Terms = () => (
  <>
    <SEO
      title="Terms of Service • Fortucast"
      description="Trading has large potential rewards, but also large potential risk. You must be aware of the risks and be willing to accept them in order to invest. The past performance of any trading system, methodology, strategy, or results is not necessarily indicative of future results. Please discuss with your broker or advisor to explain all risks to you before making any trading or investing decisions."
      keywords=""
      author="Barry Rosen"
      site_name="Fortucast"
      type="website"
      og_url={`${process.env.SITE_URL}/terms`}
      og_title="Terms of Service • Fortucast"
      og_description="Trading has large potential rewards, but also large potential risk. You must be aware of the risks and be willing to accept them in order to invest. The past performance of any trading system, methodology, strategy, or results is not necessarily indicative of future results. Please discuss with your broker or advisor to explain all risks to you before making any trading or investing decisions."
      og_image="og-white-logo.png"
      og_image_type="image/png"
      og_image_width="1200"
      og_image_height="630"
      og_image_alt="Fortucast Market Timing"
      twitter_title="Terms of Service • Fortucast"
      twitter_description="Trading has large potential rewards, but also large potential risk. You must be aware of the risks and be willing to accept them in order to invest. The past performance of any trading system, methodology, strategy, or results is not necessarily indicative of future results. Please discuss with your broker or advisor to explain all risks to you before making any trading or investing decisions."
      twitter_image="og-twitter-image.png"
      twitter_image_alt="Fortucast Market Timing"
      twitter_card="summary"
      twitter_site="@fortucast"
      twitter_creator="@fortucast"
    />
    <div id="gatsby-focus-wrapper">
      <main className="why-choose-us-content-text">
        <section className="grey-section-wrapper">
          <div className="container">
            <div className="cms-wrap">
              <div className="cms-wrap-content">
                <h1 className="cms-wrap-content-h1">Disclaimer</h1>
                <p className="cms-wrap-content-p">
                  HYPOTHETICAL PERFORMANCE RESULTS HAVE MANY INHERENT LIMITATIONS, SOME OF WHICH ARE DESCRIBED BELOW. NO
                  REPRESENTATION IS BEING MADE THAT ANY ACCOUNT WILL OR IS LIKELY TO ACHIEVE PROFITS OR LOSSES SIMILAR
                  TO THOSE SHOWN. IN FACT, THERE ARE FREQUENTLY SHARP DIFFERENCES BETWEEN HYPOTHETICAL PERFORMANCE
                  RESULTS AND THE ACTUAL RESULTS SUBSEQUENTLY ACHIEVED BY ANY PARTICULAR TRADING PROGRAM.{' '}
                  <br className="cms-wrap-content-ol" />
                  ONE OF THE LIMITATIONS OF HYPOTHETICAL PERFORMANCE RESULTS IS THAT THEY ARE GENERALLY PREPARED WITH
                  THE BENEFIT OF HINDSIGHT. IN ADDITION, HYPOTHETICAL TRADING DOESN'T INVOLVE FINANCIAL RISK, AND NO
                  HYPOTHETICAL TRADING RECORD CAN COMPLETELY ACCOUNT FOR THE IMPACT OF FINANCIAL RISK IN ACTUAL TRADING.
                  FOR EXAMPLE, THE ABILITY TO WITHSTAND LOSSES OR TO ADHERE TO A PARTICULAR TRADING PROGRAM IN SPITE OF
                  TRADING LOSSES ARE MATERIAL POINTS WHICH CAN ALSO ADVERSELY AFFECT ACTUAL TRADING RESULTS. THERE ARE
                  NUMEROUS OTHER FACTORS RELATED TO THE MARKETS IN GENERAL, OR TO THE IMPLEMENTATION OF ANY SPECIFIC
                  TRADING PROGRAM WHICH CANNOT BE FULLY ACCOUNTED FOR IN THE PREPARATION OF HYPOTHETICAL PERFORMANCE
                  RESULTS AND ALL OF WHICH CAN ADVERSELY AFFECT ACTUAL TRADING RESULTS.
                </p>
                <h3 className="cms-wrap-content-h3">Trading Instructions</h3>
                <p className="cms-wrap-content-p"></p>
                <ol className="cms-wrap-content-ol">
                  <li className="cms-wrap-content-ol-li">
                    Night session currencies entry orders expire at the completion of the electronic session at 3:15
                    p.m. and should be pulled until the next order comes out.
                  </li>
                  <li className="cms-wrap-content-ol-li">
                    Currency and electronic stops from the previous day should continue until the new stop is available.
                    Given market liquidity in the night session for currencies and the S &amp; P and the ease of
                    electronic trading and placing stops, we’ll assume stops are being executed for you. Plus we do have
                    foreign traders and people who stay up all night{' '}
                  </li>
                  <li className="cms-wrap-content-ol-li">
                    If we want to exclude night session entries, we’ll indicate “day session only” in the trading
                    strategy. If an order says to exit market on close, it means the electronic session. To accommodate
                    this new policy, we’ll do our best to publish as early as possible or send out new intraday updates
                    so that you can get night session entries and stops in place.
                  </li>
                  <li className="cms-wrap-content-ol-li">
                    If an exit target is hit within 1-2 ticks, we would cancel the entry order, as the pattern is
                    complete and the trade should be nullified.
                  </li>
                  <li className="cms-wrap-content-ol-li">
                    Breakout and breakdown orders on stops should only be entered during the day session and should not
                    be used at the open of a market unless they are accompanied by a limit order.
                  </li>
                  <li className="cms-wrap-content-ol-li">Note: (early only) means first 1-1 1/2 hours of trade.</li>
                  <li className="cms-wrap-content-ol-li">
                    Exit targets even if they originate from intraday updates should be executed in day or night
                    session.
                  </li>
                  <li className="cms-wrap-content-ol-li">
                    Market-on-open orders with limits are only executable during the opening range and should be pulled
                    after five minutes if not filled. If the market gaps under or over our stop at the open or if the
                    opening call indicates that it’ll, the order is negated and should not be entered later in the day.
                  </li>
                  <li className="cms-wrap-content-ol-li">
                    Fortucast assumes that if a limit order entry or exit hits within 1 or 2 ticks, that you will issue
                    a “cancel and replace” market order to complete the trade five minutes after the number was hit
                  </li>
                  <li className="cms-wrap-content-ol-li">
                    NEW: If on entries you only miss a trade within 2 ticks, then after five minutes, cancel and replace
                    the trade to the market.
                  </li>
                  <li className="cms-wrap-content-ol-li">
                    Exiting or entering market on close in the electronic session refers to the last trade there and not
                    to settlement. The electronic markets settle to the pit closes based on exchange rules and
                    regulations. For simplicity, for open profit and loss we do show the settlement, which often skews
                    where the market was trading when it closed before settlement.
                  </li>
                  <li className="cms-wrap-content-ol-li">
                    We often trade in 1-lots, but you have to adjust the number of contracts to your trading account
                    size. When we really like a trade, we specify a 2nd position.
                  </li>
                </ol>
                <p />
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  </>
);

export default Terms;
