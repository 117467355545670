import React, { useEffect, useState } from 'react';
import NewsLetter from '../frontend/components/newsletter';
import OurAdvantage from '../frontend/components/our-advantage';
import TrackRecord from '../frontend/components/trackRecord';
import Checkout from '../checkout';
import SEO from '../../components/SEO';
import firebase from 'gatsby-plugin-firebase';

const ETF = (props) => {
  const [showModal, setModal] = useState(false);
  const [plan, setPlan] = useState({});
  const [coupon, setCoupon] = useState('');

  const confirmPlan = (details) => {
    setPlan({ ...plan, ...details });
    setModal(true);
  };

  useEffect(() => {
    let url = new URL(props.location.href);
    let params = new URLSearchParams(url.search);
    if (params.get('type') === 'email-link') {
      document.getElementById(params.get('plan'))?.click();
      setCoupon(params.get('coupon'));
    }
    getImages();
  }, []);

  const getImages = () => {
    firebase
      .firestore()
      .collection('products')
      .doc(`${process.env.ETF_FB_ID}`)
      .get()
      .then(function (doc) {
        if (doc.exists) {
          setPlan(doc.data());
          if (doc.data().selectedIns.length > 0) {
            doc.data().selectedIns.map((item) => {
              item.ref.get().then((res) => {
                let toInsert = document.createElement('div');
                toInsert.className = 'stock-wrap-box';
                toInsert.innerHTML = `
                       <figure class="stock-wrap-box-fig">
                          <div class=" gatsby-image-wrapper">
                             <div aria-hidden="true" class="stock-box-img-guard" />
                             <img
                                aria-hidden="true"
                                src="${res.data().logoUrl}"
                                alt="${res.data().name}"
                                class="img-class ${!res.data().logoUrl ? 'invisible' : ''}"
                             />
                          </div>
                       </figure>
                       <div class="stock-box-text">
                          <p class="stock-box-text-title">${res.data().name}</p>
                          <p class="stock-box-text-title-short">${res.data().symbol}</p>
                       </div>
                 `;
                let d = document.getElementById('stock-wrap');
                if (d) {
                  d.appendChild(toInsert);
                }
              });
            });
          }
        }
      });
  };

  return (
    <>
      <SEO
        title="ETF Trading | Daily ETF Trading Research | Which ETFs To Buy? • Fortucast"
        description="Daily ETF Market Timer is the leading daily ETF trading research service. Covering short and long positions on U.S. stock indexes, gold, silver, crude oil, t-notes, and bitcoin."
        keywords="etf trading, etf research, gold etf, silver etf, sp500 etf, bitcoin etf"
        author="Barry Rosen"
        site_name="Fortucast"
        type="website"
        og_url={`${process.env.SITE_URL}/market-reports/etf`}
        og_title="ETF Trading | Daily ETF Trading Research | Which ETFs To Buy? • Fortucast"
        og_description="Daily ETF Market Timer is the leading daily ETF trading research service. Covering short and long positions on U.S. stock indexes, gold, silver, crude oil, t-notes, and bitcoin."
        og_image="og-etf.png"
        og_image_type="image/png"
        og_image_width="1200"
        og_image_height="630"
        og_image_alt="Daily ETF Market Timer"
        twitter_title="ETF Trading | Daily ETF Trading Research | Which ETFs To Buy? • Fortucast"
        twitter_description="Daily ETF Market Timer is the leading daily ETF trading research service. Covering short and long positions on U.S. stock indexes, gold, silver, crude oil, t-notes, and bitcoin."
        twitter_image="etf-icon.png"
        twitter_image_alt="Daily ETF Market Timer"
        twitter_card="summary"
        twitter_site="@fortucast"
        twitter_creator="@fortucast"
      />
      <div>
        <section className="grey-section-wrapper">
          <div className="container">
            <div className="grey-section-content">
              <figure className="tech-stock-img-wrap">
                <div className="gatsby-image-wrapper">
                  <div aria-hidden="true" className="image-guard-large" />
                  <img
                    aria-hidden="true"
                    src="/images/fortucast-etf-overview.jpg"
                    alt="etf overview"
                    className="img-class"
                  />
                </div>
              </figure>
              <div className="tech-stock-text-wrap">
                <h1 className="tech-stock-text-wrap-h1">{plan.name || ''}</h1>
                <p className="tech-stock-text-wrap-p">
                  For long-term, position, and swing trading including ETF recommendations for equities, treasuries,
                  currencies, metals, oil, ag markets and international stock market indices and sectors
                </p>
                <a href="#plans" className="tech-stock-text-wrap-a">
                  Try it for one month
                  <svg className="tech-stock-text-wrap-svg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-arrow-right"
                    >
                      <line x1={5} y1={12} x2={19} y2={12} />
                      <polyline points="12 5 19 12 12 19" />
                    </svg>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="tech-stocks-timer-daily-wrap">
          <div className="container">
            <div className="tech-stocks-timer-daily-wrap-title">
              <h2 className="tech-stocks-timer-daily-wrap-title-h2">What's Included in the ETF Timer Daily</h2>
            </div>
            <div className="box-three-wrap">
              <div className="box-three-wrap-blank" />
              <div className="box-three-wrap-report-box">
                <svg className="box-three-wrap-report-box-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-sunrise"
                  >
                    <path d="M17 18a5 5 0 0 0-10 0" />
                    <line x1={12} y1={2} x2={12} y2={9} />
                    <line x1="4.22" y1="10.22" x2="5.64" y2="11.64" />
                    <line x1={1} y1={18} x2={3} y2={18} />
                    <line x1={21} y1={18} x2={23} y2={18} />
                    <line x1="18.36" y1="11.64" x2="19.78" y2="10.22" />
                    <line x1={23} y1={22} x2={1} y2={22} />
                    <polyline points="8 6 12 2 16 6" />
                  </svg>
                </svg>
                <div className="box-three-wrap-report-box-text">
                  <h2 className="box-three-wrap-report-box-text-h2">Morning Report</h2>
                  <p className="box-three-wrap-report-box-text-p" />
                  <div className="box-three-wrap-report-box-info">
                    {' '}
                    Includes:
                    <p className="box-three-wrap-report-box-info-p">Specific Trading Strategy</p>
                    <p className="box-three-wrap-report-box-info-p">Overnight News Assessments</p>
                    <p className="box-three-wrap-report-box-info-p">Major Calendar Updates</p>
                    <p className="box-three-wrap-report-box-info-p">Pre-opening comments for included markets</p>
                    <p className="box-three-wrap-report-box-info-p">Elliot Wave Cycle Breakdowns</p>
                    <p className="box-three-wrap-report-box-info-p">Big Picture Perspectives and Developments</p>
                    <p className="box-three-wrap-report-box-info-p" />
                  </div>
                </div>
              </div>
            </div>
            <div className="tech-stocks-timer-daily-wrap-title">
              <div className="border-top-text">
                <p className="border-top-text-p">
                  Daily volume of reports and updates may increase or decrease to align with market volataility
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="grey-section-wrapper">
          <div className="container">
            <h2 className="grey-section-h2">ETF Strategies Included</h2>
            <p className="grey-section-p" />
            <div className="future-wrap" id="stock-wrap"></div>
            {/*<div className="strategies-wrap">
              <div direction="Long" className="strategies-wrap-box">
                <div className="strategies-wrap-box-content">
                  <p className="strategies-wrap-box-content-title">
                    S&amp;P
                    <svg className="strategies-wrap-box-content-title-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trending-up"
                      >
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
                        <polyline points="17 6 23 6 23 12" />
                      </svg>
                    </svg>
                  </p>
                  <p className="strategies-wrap-box-content-p">SPY</p>
                  <p className="strategies-wrap-box-content-p">State Street Global Advisors</p>
                </div>
              </div>
              <div direction="Short" className="strategies-wrap-box">
                <div className="strategies-wrap-box-content">
                  <p className="strategies-wrap-box-content-title">
                    S&amp;P
                    <svg className="css-8baudc">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trending-down"
                      >
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6" />
                        <polyline points="17 18 23 18 23 12" />
                      </svg>
                    </svg>
                  </p>
                  <p className="strategies-wrap-box-content-p">SH</p>
                  <p className="strategies-wrap-box-content-p">ProShares</p>
                </div>
              </div>
              <div direction="Long" className="strategies-wrap-box">
                <div className="strategies-wrap-box-content">
                  <p className="strategies-wrap-box-content-title">
                    Nasdaq
                    <svg className="strategies-wrap-box-content-title-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trending-up"
                      >
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
                        <polyline points="17 6 23 6 23 12" />
                      </svg>
                    </svg>
                  </p>
                  <p className="strategies-wrap-box-content-p">QQQ</p>
                  <p className="strategies-wrap-box-content-p">Invesco</p>
                </div>
              </div>
              <div direction="Short" className="strategies-wrap-box">
                <div className="strategies-wrap-box-content">
                  <p className="strategies-wrap-box-content-title">
                    Nasdaq
                    <svg className="css-8baudc">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trending-down"
                      >
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6" />
                        <polyline points="17 18 23 18 23 12" />
                      </svg>
                    </svg>
                  </p>
                  <p className="strategies-wrap-box-content-p">PSQ</p>
                  <p className="strategies-wrap-box-content-p">ProShares</p>
                </div>
              </div>
              <div direction="Long" className="strategies-wrap-box">
                <div className="strategies-wrap-box-content">
                  <p className="strategies-wrap-box-content-title">
                    Gold
                    <svg className="strategies-wrap-box-content-title-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trending-up"
                      >
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
                        <polyline points="17 6 23 6 23 12" />
                      </svg>
                    </svg>
                  </p>
                  <p className="strategies-wrap-box-content-p">GLD</p>
                  <p className="strategies-wrap-box-content-p">State Street Global Advisors</p>
                </div>
              </div>
              <div direction="Short" className="strategies-wrap-box">
                <div className="strategies-wrap-box-content">
                  <p className="strategies-wrap-box-content-title">
                    Gold
                    <svg className="css-8baudc">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trending-down"
                      >
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6" />
                        <polyline points="17 18 23 18 23 12" />
                      </svg>
                    </svg>
                  </p>
                  <p className="strategies-wrap-box-content-p">GLL</p>
                  <p className="strategies-wrap-box-content-p">ProShares</p>
                </div>
              </div>
              <div direction="Long" className="strategies-wrap-box">
                <div className="strategies-wrap-box-content">
                  <p className="strategies-wrap-box-content-title">
                    Silver
                    <svg className="strategies-wrap-box-content-title-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trending-up"
                      >
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
                        <polyline points="17 6 23 6 23 12" />
                      </svg>
                    </svg>
                  </p>
                  <p className="strategies-wrap-box-content-p">SLV</p>
                  <p className="strategies-wrap-box-content-p">BlackRock iShares</p>
                </div>
              </div>
              <div direction="Short" className="strategies-wrap-box">
                <div className="strategies-wrap-box-content">
                  <p className="strategies-wrap-box-content-title">
                    Silver
                    <svg className="css-8baudc">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trending-down"
                      >
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6" />
                        <polyline points="17 18 23 18 23 12" />
                      </svg>
                    </svg>
                  </p>
                  <p className="strategies-wrap-box-content-p">ZSL</p>
                  <p className="strategies-wrap-box-content-p">ProShares</p>
                </div>
              </div>
              <div direction="Long" className="strategies-wrap-box">
                <div className="strategies-wrap-box-content">
                  <p className="strategies-wrap-box-content-title">
                    Crude Oil
                    <svg className="strategies-wrap-box-content-title-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trending-up"
                      >
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
                        <polyline points="17 6 23 6 23 12" />
                      </svg>
                    </svg>
                  </p>
                  <p className="strategies-wrap-box-content-p">USO</p>
                  <p className="strategies-wrap-box-content-p">U.S. Commodity Funds</p>
                </div>
              </div>
              <div direction="Short" className="strategies-wrap-box">
                <div className="strategies-wrap-box-content">
                  <p className="strategies-wrap-box-content-title">
                    Crude Oil
                    <svg className="css-8baudc">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trending-down"
                      >
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6" />
                        <polyline points="17 18 23 18 23 12" />
                      </svg>
                    </svg>
                  </p>
                  <p className="strategies-wrap-box-content-p">SCO</p>
                  <p className="strategies-wrap-box-content-p">ProShares</p>
                </div>
              </div>
              <div direction="Long" className="strategies-wrap-box">
                <div className="strategies-wrap-box-content">
                  <p className="strategies-wrap-box-content-title">
                    Dollar
                    <svg className="strategies-wrap-box-content-title-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trending-up"
                      >
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
                        <polyline points="17 6 23 6 23 12" />
                      </svg>
                    </svg>
                  </p>
                  <p className="strategies-wrap-box-content-p">UUP</p>
                  <p className="strategies-wrap-box-content-p">Invesco</p>
                </div>
              </div>
              <div direction="Short" className="strategies-wrap-box">
                <div className="strategies-wrap-box-content">
                  <p className="strategies-wrap-box-content-title">
                    Dollar
                    <svg className="css-8baudc">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trending-down"
                      >
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6" />
                        <polyline points="17 18 23 18 23 12" />
                      </svg>
                    </svg>
                  </p>
                  <p className="strategies-wrap-box-content-p">UDN</p>
                  <p className="strategies-wrap-box-content-p">Invesco</p>
                </div>
              </div>
              <div direction="Long" className="strategies-wrap-box">
                <div className="strategies-wrap-box-content">
                  <p className="strategies-wrap-box-content-title">
                    T Notes
                    <svg className="strategies-wrap-box-content-title-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trending-up"
                      >
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
                        <polyline points="17 6 23 6 23 12" />
                      </svg>
                    </svg>
                  </p>
                  <p className="strategies-wrap-box-content-p">IEF</p>
                  <p className="strategies-wrap-box-content-p">BlackRock iShares</p>
                </div>
              </div>
              <div direction="Short" className="strategies-wrap-box">
                <div className="strategies-wrap-box-content">
                  <p className="strategies-wrap-box-content-title">
                    T-Notes
                    <svg className="css-8baudc">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trending-down"
                      >
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6" />
                        <polyline points="17 18 23 18 23 12" />
                      </svg>
                    </svg>
                  </p>
                  <p className="strategies-wrap-box-content-p">TBX</p>
                  <p className="strategies-wrap-box-content-p">ProShares</p>
                </div>
              </div>
              <div direction="Long" className="strategies-wrap-box">
                <div className="strategies-wrap-box-content">
                  <p className="strategies-wrap-box-content-title">
                    Bitcoin
                    <svg className="strategies-wrap-box-content-title-svg">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trending-up"
                      >
                        <polyline points="23 6 13.5 15.5 8.5 10.5 1 18" />
                        <polyline points="17 6 23 6 23 12" />
                      </svg>
                    </svg>
                  </p>
                  <p className="strategies-wrap-box-content-p">BLOK</p>
                  <p className="strategies-wrap-box-content-p">Amplify ETFs</p>
                </div>
              </div>
              <div direction="Short" className="strategies-wrap-box">
                <div className="strategies-wrap-box-content">
                  <p className="strategies-wrap-box-content-title">
                    Bitcoin
                    <svg className="css-8baudc">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-trending-down"
                      >
                        <polyline points="23 18 13.5 8.5 8.5 13.5 1 6" />
                        <polyline points="17 18 23 18 23 12" />
                      </svg>
                    </svg>
                  </p>
                  <p className="strategies-wrap-box-content-p">BLOK</p>
                  <p className="strategies-wrap-box-content-p">Amplify ETFs</p>
                </div>
              </div>
            </div>*/}
          </div>
        </section>
        <section className="tech-stocks-timer-daily-wrap" id="plans">
          <div className="container">
            <div className="tech-stocks-timer-daily-wrap-title">
              <h2 className="tech-stocks-timer-daily-wrap-title-h2">Subscription Plan</h2>
              <p className="subscription-plan-p">Choose the payment plan that’s right for you</p>
              <div className="line"></div>
              <h2 className="tech1-stocks-timer-daily-wrap-title-h2">Limited Time Only</h2>
              <p className="subscription-plan-p">
                <div className="img-icon2">
                  <img src="/images/award-black.svg" className="award-black" />
                  <img src="/images/award-white.svg" className="award-white" />
                </div>
                Get a {process.env.TRIAL_DAYS}-Day Trial for only ${process.env.TRIAL_PRICE}
              </p>
              <a href="https://bit.ly/fc-etf" target="_blank" className="sample-report-btn">
                Download a Sample ETF Report
                <svg className="tech-stock-text-wrap-svg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-arrow-right"
                  >
                    <line x1={5} y1={12} x2={19} y2={12} />
                    <polyline points="12 5 19 12 12 19" />
                  </svg>
                </svg>
              </a>
            </div>
            <div className="subscription-plan-box-wrap">
              <div className="subscription-plan-box-wrap-left">
                <h3 className="subscription-plan-box-wrap-h3">Annual</h3>
                <p className="border-top-text-p">
                  Access to all daily, weekly market updates. ${plan && plan.annually ? plan.annually : ''} every 12
                  months.
                </p>
                <div className="subscription-plan-box-wrap-price">
                  <p className="subscription-plan-box-wrap-price-large">
                    ${plan && plan.annually ? Math.ceil(plan.annually / 12) : ''}
                  </p>
                  <p className="subscription-plan-box-wrap-per-month">per month</p>
                </div>
                <button
                  id="annually"
                  className="subscription-plan-box-select-plan"
                  onClick={() =>
                    confirmPlan({
                      price_id: plan && plan.annually_stripe_id ? plan.annually_stripe_id : '',
                      price: plan && plan.annually ? plan.annually : '',
                      type: 'Annually',
                      product_id: `${process.env.ETF_FB_ID}`,
                    })
                  }
                >
                  Select Plan
                </button>
              </div>
              <div className="subscription-plan-box-wrap-right">
                <h3 className="subscription-plan-box-wrap-h3">Quarterly</h3>
                <p className="border-top-text-p">
                  Access to all daily, weekly market updates. ${plan && plan.quarterly ? plan.quarterly : ''} every 3
                  months.
                </p>
                <div className="subscription-plan-box-wrap-price">
                  <p className="subscription-plan-box-wrap-price-large">
                    ${plan && plan.quarterly ? Math.ceil(plan.quarterly / 3) : ''}
                  </p>
                  <p className="subscription-plan-box-wrap-per-month">per month</p>
                </div>
                <button
                  id="quarterly"
                  className="subscription-plan-box-select-plan-outline"
                  onClick={() =>
                    confirmPlan({
                      price_id: plan && plan.quarterly_stripe_id ? plan.quarterly_stripe_id : '',
                      price: plan && plan.quarterly ? plan.quarterly : '',
                      type: 'Quarterly',
                      product_id: `${process.env.ETF_FB_ID}`,
                    })
                  }
                >
                  Select Plan
                </button>
              </div>
            </div>
            <p className="plan-info-text">
              <sup>†</sup>Monthly prices shown by taking the plan payment divided by the plan term in months.
            </p>
          </div>
        </section>
        <OurAdvantage />
        <TrackRecord />
        <NewsLetter />
        {showModal && (
          <Checkout
            isOpen={showModal}
            close={() => setModal(false)}
            plan_name={plan.name}
            price={plan.price}
            plan_type={plan.type}
            product_id={plan.product_id}
            price_id={plan.price_id}
            product_img={'etf-icon'}
            stripe_prod_id={plan && plan.stripe_product_id}
            coupon_code={coupon}
            shortName={'ETF'}
          />
        )}
      </div>
    </>
  );
};

export default ETF;
